import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const ApprovalTemplatelist = async (data: any) => {
  let body = JSON.stringify(data);

  let response = await fetch(
    `${API_URL}/list-certificate-approval-template`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );
  return response.json();
};

const AddApprovalTemplate = async (
  documentid: any,
  organizationid: any,
  departmentid: any,
  certificate_approval_template_name: any
) => {
  let body = JSON.stringify({
    document_id: documentid,
    department_id: departmentid,
    organization_id: organizationid,
    certificate_approval_template_name,
  });
  let response = await fetch(
    `${API_URL}/certificate-approval-template`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteApprovalTemplate = async (params: any) => {
  let response = await fetch(
    `${API_URL}/certificate-approval-template/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateApprovalTemplate = async (data: any, edit_doc: any) => {
  // console.log(edit_dep);
  console.log(edit_doc);

  let body = JSON.stringify(data);

  let response = await fetch(
    `${API_URL}/certificate-approval-template/${edit_doc.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

//   let response = await fetch(
//     `${API_URL}/upload`,
//     CommonMethod.ImagePostMethod(data)
//   );
//   return await response.json();
// };

const GetApprovalTemplate = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/certificate-approval-template/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      console.log(response.data.data.roles);

      return response.data.data.roles;
    });
};

const ApprovalTemplateService = {
  ApprovalTemplatelist,
  AddApprovalTemplate,
  DeleteApprovalTemplate,
  UpdateApprovalTemplate,
  GetApprovalTemplate,
};
export default ApprovalTemplateService;
