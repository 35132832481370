import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const Modulelists = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-modules`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};

const AddModule = async (moduledname: any, checked: boolean) => {
  let body = JSON.stringify({
    module_name: moduledname,
    status: checked,
  });
  let response = await fetch(
    `${API_URL}/modules`,
    CommonMethod.PostMethod(body)
  );

  return response.json();
};
const UpdateModules = async (edited: any, params: any, checked: boolean) => {
  // console.log(edited);

  let body = JSON.stringify({
    module_name: edited,
    status: checked,
  });
  let response = await fetch(
    `${API_URL}/modules/${params.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};
const DeleteModules = async (params: any) => {
  let response = await fetch(
    `${API_URL}/modules/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};
const ModuleServices = {
  Modulelists,
  AddModule,
  UpdateModules,
  DeleteModules,
};
export default ModuleServices;
