import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { backdropClasses, FormControlLabel } from "@mui/material";

import { toast } from "react-toastify";
import { COLORS } from "../../custom";
import {
  CloseButton,
  Button,
  TextInput,
  Switch,
  Modal,
  Group,
} from "@mantine/core";
import DepartmentServices from "../../services/DepartmentService";
import { showNotification } from "@mantine/notifications";

interface CardPropsType {
  settemplatemodal: any;
  TemplateString: any;
}
const ViewTemplateModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const { settemplatemodal, TemplateString } = props;
  const [popup, setPopup]: any = useState(true);
  const html = TemplateString;
  useEffect(() => {
    settemplatemodal(popup);
  }, [popup]);
  return (
    <>
      <Modal
        size={1200}
        opened={popup}
        onClose={() => {
          setPopup(false);
        }}
        centered
      >
        {/* <Document>
          <Page> */}

        {/* </Page>
        </Document> */}

        <div id="template">
          <div dangerouslySetInnerHTML={{ __html: TemplateString }} />
        </div>
      </Modal>
    </>
  );
};

export default ViewTemplateModal;
