import React, { useState, useEffect } from "react";
import ConfirmDialog from "../../components/Controls/ConfirmDialog";
import { Button } from "@material-ui/core";
import ServerSideGrid from "../../components/Tables/ServerSideGrid";
import "./ReqPending.css";
import { COLORS } from "../../custom";
import Consent_Services from "../../services/ConsentServices";
import { showNotification } from "@mantine/notifications";

export const RequestPendingList = () => {
  const [pendingdata, setPendingdata]: any = useState();

  //table---
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal]: any = useState();
  const [loading, setLoading] = useState(false);
  const [reqlength, setReqLength] = useState<number>(0);

  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  //table---

  const columnDefsmodule = [
    {
      headerName: "ID",
      field: "id",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
    },

    {
      headerName: "Response",
      field: "response",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
    },
    {
      headerName: "Joining date",
      field: "created_at",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
      cellRendererFramework: (params: any) => (
        <span>{params.data.created_at.split("T")[0]}</span>
      ),
    },
    {
      headerName: "Actions",
      field: "id",
      cellRendererFramework: (params: any) => (
        <div>
          <Button
            style={{
              width: "100px",
              margin: "5px",
              height: "30px",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              handleUpdate_req(params.data);
              // setEditModulepop(true);
              // setModulePop(true);
            }}
          >
            approved
          </Button>
          <Button
            style={{
              width: "100px",
              margin: "5px",
              height: "30px",
              textAlign: "center",
            }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure?",
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  handleDelete_req(params.data);
                },
              });
            }}
          >
            rejected
          </Button>
        </div>
      ),
    },
    // {
    //   headerName: "Status",
    //   field: "status",
    //   cellStyle: { fontSize: "15px", marginLeft: "20px" },
    // },
  ];

  const handleDelete_req = async (params: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      await Consent_Services.Reject_request(params.uuid).then((res) => {
        // console.log(res);
        getpendinglist();
        setLoading(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };
  const handleUpdate_req = async (params: any) => {
    setLoading(true);
    try {
      await Consent_Services.Update_pendingreq(params.uuid).then((res) => {
        console.log(res);
        getpendinglist();
        setLoading(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };
  const getpendinglist = async () => {
    console.log(page, pageSize);
    setLoading(true);
    try {
      await Consent_Services.getpendinglists().then((res) => {
        setPendingdata(res.data);
        setReqLength(res.data.length);
        // console.log(res.consentRequests.length);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  //   const getseverallists = async () => {
  //     // console.log(page, pageSize);
  //     try {
  //       await Consent_Services.getSeveralrequests().then((res) => {
  //         // setTotal(res.total);
  //         // setPendingdata(res.consentRequests);
  //         // setReqLength(res.consentRequests.length);
  //         // console.log(res.consentRequests.length);

  //         console.log(res.consentRequests);
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  useEffect(() => {
    getpendinglist();
    // getseverallists();
  }, []);

  //   useEffect(() => {
  //     console.log(page, pageSize);
  //     getuserlist();
  //   }, [page, pageSize]);

  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <div
        className="users-main-page"
        style={{ backgroundColor: COLORS.backgroundContent }}
      >
        <div className="users-title">
          <p>Consent Pending List</p>
          <div className="users-title"></div>
        </div>
        <ServerSideGrid
          className="User-list-table"
          rowData={pendingdata}
          headerHeight={80}
          columnDefs={columnDefsmodule}
          defaultColDef={{
            sortable: true,
            resizable: false,
            filter: true,
            flex: 1,
            minWidth: 100,
          }}
          rowHeight={50}
          handleCellClick={() => {}}
          onRowClicked={() => {}}
          loading={loading}
          disableClickSelectionRenderers={false}
          noDataTxt={
            pendingdata && pendingdata.length > 0 ? "loading" : "no data found"
          }
          TableHeight={80}
          rowSelection="multiple"
          rowMultiSelectWithClick={true}
          // onSelectionChanged={onSelectionChanged}
          pageSize={pageSize}
          totalDataCount={reqlength}
          serverRowSize={pageSize}
          currentPage={page + 1}
          serverSidePagination={true}
          serverPageCount={Math.ceil(reqlength / pageSize)}
          setServerRowSize={(p: number) => {
            setPageSize(p);
          }}
          setServerSidePage={(p: number) => {
            setPage(p - 1);
          }}
        />
      </div>
      {/* )} */}
    </>
  );
};
