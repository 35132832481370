const GetMethod = () => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `bearer ${JSON.parse(token)}`,
    },
  };
  return config;
};

const PostMethod = (bodyVal: any) => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `bearer ${JSON.parse(token)}`,
    },
    body: bodyVal,
  };
  return config;
};

const PutMethod = (bodyVal: any) => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: `bearer ${JSON.parse(token)}`,
    },
    body: bodyVal,
  };
  return config;
};

const DeleteMethod = () => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `bearer ${JSON.parse(token)}`,
    },
  };
  return config;
};
const ImagePostMethod = (bodyVal: any) => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "POST",
    headers: {
      Authorization:`bearer ${JSON.parse(token)}`
    },
    body: bodyVal,
  };
  return config;
};
const CommonMethod = {
  GetMethod,
  PostMethod,
  ImagePostMethod,
  PutMethod,
  DeleteMethod,
};

export default CommonMethod;
