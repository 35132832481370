import { useState } from "react";
import { createStyles, Navbar } from "@mantine/core";
import {
  IconBuildingBank,
  IconCertificate,
  IconUserPlus,
  IconUsers,
} from "@tabler/icons-react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon: any = getRef("icon");
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "light"
          ? theme.colors.dark[9]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor: "#1c7ed6",

        color:
          theme.colorScheme === "dark" ? theme.white : theme.colors.gray[0],

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],

      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: "#25204f",
        fontSize: theme.fontSizes.sm,
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
        borderRadius: theme.radius.sm,
        color: theme.fn.variant({
          variant: "filled",
          color: theme.primaryColor,
        }).color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "filled",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  };
});

interface SettingsPropsType {
  setpath: any;
}
const Settingsbar: React.FC<SettingsPropsType> = (props): JSX.Element => {
  const { setpath } = props;
  const userdetails = useSelector((s: any) => s.user.value);
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Organization");
  const navigate = useNavigate();
  const settingsData = [];

  if (
    userdetails?.user_roles &&
    userdetails?.user_roles[`ORGANIZATION_MASTER`] &&
    userdetails?.user_roles[`ORGANIZATION_MASTER`][0].can_read
  ) {
    settingsData.push({
      title: "Organization ",
      path: "Organization",
      iconName: <IconBuildingBank />,
      iconSize: 25,
    });
  }
  if (
    userdetails?.user_roles &&
    userdetails?.user_roles[`ROLES_MASTER`] &&
    userdetails?.user_roles[`ROLES_MASTER`][0].can_read
  ) {
    settingsData.push({
      title: "Roles ",
      path: "Role",
      iconName: <IconUserPlus />,
      iconSize: 25,
    });
  }
  if (
    userdetails?.user_roles &&
    userdetails?.user_roles[`CERTIFICATE_MASTER`] &&
    userdetails?.user_roles[`CERTIFICATE_MASTER`][0].can_read
  ) {
    settingsData.push({
      title: "Certificate ",
      path: "Certificate",
      iconName: <IconCertificate />,
      iconSize: 25,
    });
  }
  if (
    userdetails?.user_roles &&
    userdetails?.user_roles[`USERS_MASTER`] &&
    userdetails?.user_roles[`USERS_MASTER`][0].can_read
  ) {
    settingsData.push({
      title: "Users",
      path: "Users",
      iconName: <IconUsers />,
      iconSize: 25,
    });
  }

  const links = settingsData.map((item, index) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.path === active,
      })}
      key={index}
      onClick={() => {
        console.log("item.path", item.path);

        setPathandActive(item.path);
      }}
    >
      <span>{item.iconName}</span>
      <span style={{ paddingLeft: "15px", cursor: "pointer" }}>
        {item.title}
      </span>
    </a>
  ));
  const setPathandActive = (getPath: any) => {
    setActive(getPath);
    setpath(getPath);
  };

  return (
    <div>
      <Navbar.Section>{links}</Navbar.Section>
    </div>
  );
};

export default Settingsbar;
