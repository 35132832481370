import { AppShell, Group, Header } from "@mantine/core";
import HeaderMenuColored from "./header";

interface Props {
  children: React.ReactNode;
}

export const Layout = (props: Props) => {
  return (
    <AppShell>
      {
        <Header height={90} className="custom-header">
          <HeaderMenuColored links={[]} />
        </Header>
      }
      <div>{props.children}</div>
    </AppShell>
  );
};
