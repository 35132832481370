const Data = [
  {
    User_ID: 6157,
    UserName: "Alex Nepola",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: " Nepola",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: "Biswas",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: "Alex ",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: "Harry",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: "Dany",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: "Dany",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
  {
    User_ID: 6157,
    UserName: "Dany",
    Date: 24 - 12 - 21,
    Application: "2 requests",
    Selectoption: ["issue", "approve"],
  },
];
export default Data;
