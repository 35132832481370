import {
  Avatar,
  Box,
  Button,
  Card,
  createStyles,
  Flex,
  Grid,
  Group,
  Image,
  List,
  Loader,
  LoadingOverlay,
  Menu,
  Modal,
  PasswordInput,
  SimpleGrid,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import {
  Dropzone,
  DropzoneProps,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { deleteToken } from "../../redux/features/TokenSlice";
import { logins, logouts } from "../../redux/features/userSlice";
import {
  getUserProfile,
  updateUserProfile,
  userPasswordChange,
  userProfileImageUpload,
} from "../../services/UserProfileService";
import { useFormik } from "formik";
import * as Yup from "yup";
// import './styles.css'

// const calendar = require("../../Assets/ProfileIcons/calendarSvg.svg")
interface User {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  image: string;
  department_name: string;
  designation_name: string;
}

const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    color: theme.white,
    "&[data-hovered]": {
      backgroundColor:
        theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      color: theme.white,
    },
  },
}));

function Profile(props: Partial<DropzoneProps>) {
  const { classes } = useStyles();
  const [datas, setDatas] = useState<any>([]);
  const [opened, setOpened] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState<User>({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    image: "",
    department_name: "",
    designation_name: "",
  });
  const [updateData, setUpdateData] = useState<any>({});
  const [password, setPassword] = useState({ password: "", newPassword: "" });
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageView, setImageView] = useState(false);
  const openRef = useRef<() => void>(null);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [visible, { toggle }] = useDisclosure(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userdetails = useSelector((s: any) => s.user.value);

  // LOCAL STORAGE HANDLE
  let data = localStorage.getItem("user");
  let uuid: any;
  if (data) {
    uuid = JSON.parse(data);
    uuid = uuid?.uuid;
  }

  // GET API
  const userProfileDetails = async () => {
    setLoading(true);
    await getUserProfile(userdetails?.uuid, userdetails?.present_role).then(
      (res) => {
        console.log("User Profile Details", res.data.users[0]);
        // localStorage.setItem("user", JSON.stringify(res.data.users[0]));
        // dispatch(logins(res.data.users[0]));
        if (res.data.users[0]) {
          let data = res.data.users[0];
          let prevData = { ...userData };
          prevData.first_name = data.first_name;
          prevData.last_name = data.last_name;
          prevData.email = data.email;
          prevData.phone = data.phone;
          prevData.image = data?.user_details?.profile_img;
          prevData.department_name =
            data?.user_details?.department?.department_name;
          prevData.designation_name =
            data?.user_details?.designations?.designation_name;
          setUserData(prevData);
          delete data?.password;
          let permission = data?.user_roles[0]?.roles?.role_permissions;
          let allPermission;
          if (permission) {
            allPermission = permission.reduce(function (r: any, a: any) {
              r[a?.modules?.module_name] = r[a?.modules?.module_name] || [];
              r[a?.modules?.module_name].push({
                module: a?.modules?.module_name,
                can_read: a?.can_read,
                can_write: a?.can_write,
              });
              return r;
            }, Object.create(null));
            data.user_roles = allPermission;
            localStorage.setItem("user", JSON.stringify(data));
            dispatch(logins(data));
          }
        }
        // setDatas(res.data.users[0])
        // setDatas(res.data.users[0])
      }
    );
    setLoading(false);
  };

  //USER DETAIL UPDATE API

  const updateProfileDetails = async (data: any) => {
    setLoading(true);
    if (checked) {
      await updateUserProfile(uuid, data)
        .then((res) => {
          console.log("res Update", res);
          logout();
          // localStorage.setItem("user", JSON.stringify(res.data.users));
          // dispatch(logins(res.data.user));
        })
        .catch((err) => {
          console.log("err", err);
        });
      setLoading(false);
    } else {
      await updateUserProfile(uuid, data)
        .then((res) => {
          console.log("res Update", res);
          setUpdateData({});
          // localStorage.setItem("user", JSON.stringify(res.data.users));
          // dispatch(logins(res.data.user));
        })
        .catch((err) => {
          console.log("err", err);
        });
      userProfileDetails();
      setLoading(false);
    }
  };

  //PASSWORD CHANGE API

  const passwordChange = async (value: any) => {
    setLoading(true);

    await userPasswordChange(password)
      .then((res: any) => {})
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  // PROFILE IMAGE UPLOAD

  const imageUpload = async () => {
    setImageLoading(true);
    let formData: any = new FormData();

    formData.append("file", files[0]);
    // console.log('formData', formData.get('form'));

    await userProfileImageUpload(formData)
      .then((res: any) => {
        console.log("image URL", res?.data?.imageURL[0]?.Location);
        let prevData = {
          user_details: {
            update: {
              profile_img: res?.data?.imageURL[0]?.Location,
            },
          },
        };
        updateUserProfile(uuid, prevData)
          .then((res: any) => {
            console.log("Image Res", res);
            // localStorage.setItem("user", JSON.stringify(res.data.users));
            // dispatch(logins(res.data.user));
          })
          .catch((err: any) => {
            console.log("err", err);
          });
      })
      .catch((err: any) => {
        console.log("err", err);
      });

    setTimeout(async () => {
      setOpened(false);
      setFiles([]);
      await userProfileDetails();
      setImageLoading(false);
    }, 1000);
    // setUserData({ first_name: '', last_name: '', email: '', phone: '', image: '', department_name: '', designation_name: '' })
  };

  // IMAGE PREVIEW

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  });

  //LOGOUT

  const logout = () => {
    // AuthService.logout();
    dispatch(logouts({}));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(deleteToken(""));
    navigate("/");
  };

  useEffect(() => {
    userProfileDetails();
  }, []);

  let height = window.innerHeight;

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current Password is required"),
      newPassword: Yup.string()
        .min(6, "Must be 6 characters or more")
        .matches(
          /^(?=.*[!@#\$%\^&\*])/,
          "  Must Contain One Special Case Character"
        )
        .matches(/^(?=.*[0-9].{1,})/, "  Must Contain Two Number Character")
        .required("New Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values: any) => {
      passwordChange(values);
    },
  });

  return (
    <>
      {!loading ? (
        <div
          style={{
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "1%",
            paddingBottom: "1%",
          }}
          // style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1%', marginBottom: '1%' }}
          // style={{ width: '70%', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', }}
        >
          <Grid>
            <Grid.Col span={2}>
              {/* <div > */}
              <Modal
                opened={opened}
                onClose={() => {
                  setFiles([]);
                  setOpened(false);
                }}
                title="Profile Image"
                radius="md"
                transition="scale"
                transitionDuration={300}
                transitionTimingFunction="ease"
                centered
              >
                <LoadingOverlay
                  visible={imageLoading}
                  overlayBlur={1}
                  loaderProps={{
                    size: "md",
                    variant: "oval",
                    color: "#25204f",
                  }}
                  overlayOpacity={0.2}
                />
                <Dropzone
                  accept={IMAGE_MIME_TYPE}
                  onDrop={setFiles}
                  sx={{ width: "100%", border: "0px" }}
                >
                  {/* <Text align="center">Drop images here/</Text> */}
                  <Group position="center">
                    {files.length === 0 && (
                      <Button style={{ backgroundColor: "#25204f" }}>
                        Select Image
                      </Button>
                    )}
                  </Group>
                  <SimpleGrid
                  // cols={4}
                  // breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                  // mt={previews.length > 0 ? 'xl' : 0}
                  >
                    {previews}
                  </SimpleGrid>
                </Dropzone>
                {files.length !== 0 && (
                  <Group position="center">
                    <Button
                      onClick={() => {
                        imageUpload();
                      }}
                    >
                      Upload
                    </Button>
                  </Group>
                )}
              </Modal>

              <Modal
                opened={imageView}
                onClose={() => {
                  setImageView(false);
                }}
                title={<h4>Profile Image</h4>}
                radius="md"
                transition="scale"
                transitionDuration={300}
                transitionTimingFunction="ease"
                centered
                // withCloseButton={false}
              >
                <Image src={userData?.image} />
                <Group position="right" mt="md">
                  <Button
                    style={{ backgroundColor: "#25204f" }}
                    onClick={() => {
                      setImageView(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "#25204f" }}
                    onClick={() => {
                      setImageView(false);
                      setOpened(true);
                    }}
                  >
                    Edit
                  </Button>
                </Group>
              </Modal>

              <Card shadow="sm" radius="lg" mt="md" p="xl">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Menu
                    withArrow
                    classNames={classes}
                    trigger="hover"
                    openDelay={100}
                    closeDelay={400}
                    radius="md"
                  >
                    <Menu.Target>
                      <Avatar
                        style={{ cursor: "pointer" }}
                        radius="lg"
                        size="lg"
                        src={userData?.image}
                        alt="it's me"
                        onClick={() => {
                          setImageView(true);
                        }}
                      />
                    </Menu.Target>
                    <Menu.Dropdown
                      sx={{
                        padding: "1%",
                      }}
                    >
                      <Menu.Item
                        style={{ backgroundColor: "#604E9E" }}
                        onClick={() => {
                          setOpened(true);
                        }}
                      >
                        Edit
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>

                  <Text mt="xl" mb="sm" weight={700}>
                    {userData?.first_name} {userData?.last_name}
                  </Text>
                  <Text mb="md" weight={500}>
                    {userData?.department_name} / {userData?.designation_name}
                  </Text>
                </div>
                {/* </Group> */}
              </Card>
              {/* </div> */}
            </Grid.Col>
            <Grid.Col
              span={10}
              sx={{
                height: window.innerHeight - 150,
                // height: '80vh',
                overflowY: "scroll",
                // padding: "20px",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <div>
                <Card shadow="md" my="md" radius="lg">
                  <Text weight={500}>Basic Info</Text>
                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      placeholder="Your name"
                      label="First Name"
                      radius="md"
                      value={userData?.first_name}
                      onChange={(event) => {
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.first_name = event.currentTarget.value;
                        changeData.first_name = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    <TextInput
                      placeholder="Your name"
                      label="Last Name"
                      radius="md"
                      value={userData?.last_name}
                      onChange={(event) => {
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.last_name = event.currentTarget.value;
                        changeData.last_name = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                  </Group>
                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      label="Email"
                      placeholder="example@gmail.com"
                      radius="md"
                      type="email"
                      value={userData?.email}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.email = event.currentTarget.value;
                        changeData.email = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    <TextInput
                      label="Phone Number"
                      placeholder="+91 "
                      radius="md"
                      type="number"
                      value={userData?.phone}
                      onChange={(event) => {
                        console.log("dataaaa", event.target.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.phone = event.currentTarget.value;
                        changeData.phone = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                  </Group>
                  {/* <Group spacing="md" mt="md" grow>
                        <Select
                            label="I'm"
                            placeholder="Pick one"
                            searchable
                            clearable
                            nothingFound="No options"
                            data={['Male', 'Female', 'Not to Prefer']}
                            dropdownPosition="bottom"
                            radius="md"
                            transition="pop-top-left"
                            transitionDuration={80}
                            transitionTimingFunction="ease"
                        />
                        <DatePicker
                            placeholder="Choose Date"
                            label="Birth Date"
                            inputFormat="MM/DD/YYYY"
                            labelFormat="MM/YYYY"
                            defaultValue={new Date()}
                            radius="md"
                        />
                    </Group>
                    <Group spacing="md" mt="md" grow>
                        <TextInput label="Email" placeholder="example@gmail.com" radius="md" type="email"
                            value={userData?.email}
                            onChange={(event) => {
                                console.log('dataaaa', event.currentTarget.value);
                                let prevData = { ...userData }
                                prevData.email = event.currentTarget.value
                                setUserData(prevData)
                            }}
                        />
                        <TextInput label="Confirmation Email" placeholder="example@gmail.com" radius="md" type="email"
                            // value={userData?.confirmationEmail}
                            onChange={(event) => {
                                console.log('dataaaa', event.currentTarget.value);
                                let prevData = { ...userData }
                                // prevData.confirmationEmail = event.currentTarget.value
                                // setUserData(prevData)
                            }}
                        />
                    </Group>
                    <Group spacing="md" mt="md" grow>
                        <TextInput label="Your Location" placeholder="India" radius="md" />
                        <TextInput label="Phone Number" placeholder="+91 " radius="md" type="number"
                            value={userData?.phone}
                            onChange={(event) => {
                                console.log('dataaaa', event.target.value);
                                let prevData = { ...userData }
                                prevData.phone = event.currentTarget.value
                                setUserData(prevData)
                            }}
                        />
                    </Group>
                    <Group spacing="md" my="md" grow>
                        <Select
                            label="Language"
                            placeholder="Pick one"
                            searchable
                            clearable
                            nothingFound="No options"
                            data={['English', 'Tamil', 'French', 'Spanish']}
                            dropdownPosition="top"
                            radius="md"
                        />
                        <MultiSelect
                            data={['React', 'Angular', 'Svelte', 'Vue']}
                            label="Skills"
                            placeholder="Choose"
                            searchable
                            clearable
                            dropdownPosition="top"
                            radius="md"
                            transition="pop-top-left"
                            transitionDuration={80}
                            transitionTimingFunction="ease"
                        />
                    </Group> */}
                  <Flex justify="flex-end">
                    <Button
                      mt="sm"
                      style={{ backgroundColor: "#25204f", width: "10%" }}
                      // style={{ width: "10%" }}
                      onClick={() => {
                        updateProfileDetails(updateData);
                      }}
                    >
                      Update
                    </Button>
                  </Flex>
                </Card>
              </div>
              <div>
                <Card shadow="md" my="md" radius="lg">
                  <Text weight={500}>Change Password</Text>

                  <form onSubmit={formik.handleSubmit}>
                    <div style={{ padding: "10px 0px" }}>
                      <PasswordInput
                        placeholder="Current Password"
                        label="Current Password"
                        radius="md"
                        defaultValue="secret"
                        visible={visible}
                        onVisibilityChange={toggle}
                        {...formik.getFieldProps("currentPassword")}
                      />
                      {/* {formik.touched.currentPassword &&
                      formik.errors.currentPassword ? (
                        <div style={{ fontSize: "13px", color: "red" }}>
                          {formik.errors.currentPassword}
                        </div>
                      ) : null} */}
                    </div>

                    <div>
                      <PasswordInput
                        label="New Password"
                        placeholder="New Password"
                        visible={visible}
                        onVisibilityChange={toggle}
                        radius="md"
                        {...formik.getFieldProps("newPassword")}
                      />
                      {/* {formik.touched.newPassword &&
                      formik.errors.newPassword ? (
                        <div style={{ fontSize: "13px", color: "red" }}>
                          {formik.errors.newPassword}
                        </div>
                      ) : null} */}
                    </div>

                    <div style={{ padding: "10px 0px" }}>
                      <PasswordInput
                        placeholder="Confirm Password"
                        label="Confirm Password"
                        // defaultValue="secret"
                        visible={visible}
                        onVisibilityChange={toggle}
                        radius="md"
                        {...formik.getFieldProps("confirmPassword")}
                      />
                      {/* {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div style={{ fontSize: "13px", color: "red" }}>
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null} */}
                    </div>

                    <Text mt={5} fw={500}>
                      Password Requirements
                    </Text>
                    <List ml="sm" style={{ color: "#9CA5B5" }}>
                      <List.Item>One special characters</List.Item>
                      <List.Item>Min 6 characters</List.Item>
                      <List.Item>One number (2 are recommended)</List.Item>
                      <List.Item>
                        <Text> Change it often</Text>
                      </List.Item>
                    </List>
                    <Flex justify="flex-end">
                      <Button
                        style={{ backgroundColor: "#604E9E" }}
                        // onClick={() => {
                        //   passwordChange();
                        // }}
                        type="submit"
                      >
                        Update Password
                      </Button>
                    </Flex>
                  </form>

                  {/* <Text fw={500}>Password Requirements</Text>
                  <List ml="sm" style={{ color: "#9CA5B5" }}>
                    <List.Item>One special characters</List.Item>
                    <List.Item>Min 6 characters</List.Item>
                    <List.Item>One number (2 are recommended)</List.Item>
                    <List.Item>
                      <Text> Change it often</Text>
                    </List.Item>
                  </List>
                  <Flex justify="flex-end">
                    <Button
                      style={{ backgroundColor: "#25204f" }}
                      onClick={() => {
                        passwordChange();
                      }}
                    >
                      Update Password
                    </Button>
                  </Flex> */}
                </Card>
              </div>
              <div>
                <Card shadow="md" my="md" radius="lg">
                  <Text weight={500}>Delete Account</Text>
                  <Text style={{ color: "#9CA5B5" }}>
                    Once you delete your account, there is no going back. Please
                    be certain.
                  </Text>
                  <Flex justify="space-between" my="md">
                    <div>
                      <Switch
                        color="orange"
                        label="Confirm"
                        labelPosition="right"
                        description="I want to Delete or De-Activate my account."
                        // sx={{ width: '40px' }}
                        checked={checked}
                        onChange={(event) =>
                          setChecked(event.currentTarget.checked)
                        }
                      />
                    </div>
                    <div>
                      <Button
                        variant="outline"
                        mr="sm"
                        radius="md"
                        color="orange"
                        disabled={!checked}
                        onClick={() => {
                          let data = { status: false };
                          updateProfileDetails(data);
                        }}
                      >
                        De-Activate
                      </Button>
                      <Button
                        color="red.8"
                        radius="md"
                        disabled={!checked}
                        onClick={() => {
                          let data = { status: false };
                          updateProfileDetails(data);
                        }}
                      >
                        Delete Account
                      </Button>
                    </div>
                  </Flex>
                </Card>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      ) : (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "15%" }}
        >
          <Loader color="#25204f" />
        </Box>
      )}
    </>
  );
}

export default Profile;
