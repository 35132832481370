import React, { useState, useEffect } from "react";
// import "./CertificateSetting.css";
import {
  Card,
  Text,
  Button,
  Group,
  Table,
  Avatar,
  Checkbox,
  Box,
  Select,
  Pagination,
  Loader,
  Autocomplete,
} from "@mantine/core";

import { Theme } from "../../../custom";
import { showNotification } from "@mantine/notifications";
import DocumentTypesServices from "../../../services/DocoumentTypeService";
import OrgansizationServices from "../../../services/OrgansizServices";
import DocoumentFiledsModal from "../../AllPopUPs/DocumentFieldsModal";
import DocumentFieldsServices from "../../../services/DocumentFieldsService";
import ConfirmDialog from "../../Controls/ConfirmDialog";
import DocumentService from "../../../services/DocumentService";
import DocoumentModal from "../../AllPopUPs/DocumentModal";
import NoData from "../../LottieFiles/NoData";

export const Documents = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [docTotal, setDocTotal]: any = useState();
  const [docoumentModal, setDocoumentModal]: any = useState(false);
  const [organsizelists, setOrgansizelist]: any = useState("");
  const [documentlist, setDocumentlist]: any = useState([]);
  const [documentType, setDocumentType]: any = useState([]);
  const [editModal, setEditModal]: any = useState("");
  const [documentid, setDocumentId]: any = useState();
  const [templateName, setTemplateName]: any = useState();
  const [edit_doc, setEdit_doc]: any = useState("");

  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setcurrentPage = (value: any) => {
    setPage(value);
    setDocumentlist([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setDocumentlist([]);
  };

  const getdocumentlist = async () => {
    setLoading(true);
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    let data1 = {
      limit: pageSize,
      where: {
        template_name: {
          contains: templateName,
          mode: "insensitive",
        },
      },
    };
    try {
      let res = await DocumentService.Documentlists(
        templateName ? data1 : data
      );
      if (res.status === true) {
        setDocumentlist(res.data.document);
        setDocTotal(res.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeletefield = async (param: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      let res = await DocumentService.DeleteDocument(param);
      if (res.status === true) {
        getdocumentlist();
        setLoading(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdate = async (params: any) => {
    setEditModal(true);
    setEdit_doc(params);
  };

  const getDocumentTypes = async () => {
    try {
      let res = await DocumentFieldsServices.DocoumentTypeslist();
      if (res.status === true) {
        setDocumentType(res.data.documentType);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getDocumentTypes();
  }, []);
  useEffect(() => {
    getdocumentlist();
  }, [page, pageSize, templateName]);
  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {docoumentModal && (
        <DocoumentModal
          setDocumentFieldsModal={setDocoumentModal}
          get_documentField_list={getdocumentlist}
          setEditModal={setEditModal}
          edit_document={edit_doc}
          editModal={editModal}
          documentType={documentType}
        />
      )}
      <div>
        <Card mt="sm" radius="lg" shadow="lg">
          <Card.Section>
            <Group position="apart">
              {/* <Group m="md"> */}
              <Box p="md">
                <Autocomplete
                  label="Filter by Name"
                  placeholder="Select TemplateName"
                  data={
                    Array.isArray(documentlist)
                      ? documentlist.map(
                          (provider) => `${provider.template_name}`
                        )
                      : []
                  }
                  onChange={(value) => setTemplateName(value)}
                  maxDropdownHeight={400}
                  nothingFound="Nobody here"
                />
              </Box>
              <Box p="md">
                <Button
                  onClick={() => {
                    setDocoumentModal(true);
                    setEditModal(false);
                  }}
                  style={{ background: "#25204f" }}
                  variant="filled"
                  size="sm"
                >
                  +Add
                </Button>
              </Box>
            </Group>
            {loading === true ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "50px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            ) : documentlist && documentlist.length > 0 ? (
              <div>
                <Table verticalSpacing="lg" highlightOnHover>
                  <thead style={{ border: "none" }}>
                    <tr>
                      <th>
                        <Checkbox size="sm" />
                      </th>
                      <th>
                        <div>Template Name</div>
                      </th>
                      <th>
                        <div>Template Type</div>
                      </th>
                      <th>
                        <div>status</div>
                      </th>
                      <th>
                        <div>Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {documentlist?.map((document: any, index: number) => (
                      <tr
                        key={document?.uuid}
                        className={
                          document?.status === false ? "in-active-list" : ""
                        }
                      >
                        <td>
                          <Checkbox size="sm" />
                        </td>
                        <td>{document?.template_name}</td>
                        <td>{document?.template_type}</td>
                        <td>
                          {document?.status === true ? "Active" : "In-Active"}{" "}
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              handleUpdate(document);
                              setDocoumentModal(true);
                              setEditModal(true);
                            }}
                            variant="default"
                            radius="md"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Want to Delete?",

                                onConfirm: () => {
                                  handleDeletefield(document);
                                },
                              });
                            }}
                            variant="default"
                            radius="md"
                            ml="sm"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Group position="apart" mt="md" p="lg">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box mr="md">Rows per page</Box>
                    <Select
                      onChange={(e) => setrowsperpage(e)}
                      style={{ width: "4rem" }}
                      value={String(pageSize)}
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                    />
                  </Box>
                  <Pagination
                    styles={(theme) => ({
                      item: {
                        "&[data-active]": {
                          backgroundColor: "#25204f",
                        },
                      },
                    })}
                    total={Math.ceil(docTotal / pageSize)}
                    boundaries={5}
                    page={page}
                    initialPage={1}
                    onChange={(e) => setcurrentPage(e)}
                  />
                </Group>
              </div>
            ) : (
              <NoData />
            )}
          </Card.Section>
        </Card>
      </div>
    </>
  );
};
