import React, { useState } from "react";
import data from "./user.json";
import ServerSideGrid from "./ServerSideGrid";

const Table = () => {
  const [rowData] = useState(data);
  const [columnDefs] = useState([
    { field: "id" },
    { field: "name" },
    { field: "username" },
    { field: "email" },
    { field: "address" },
    { field: "phone" },
    { field: "website" },
    { field: "company" },
  ]);
  // const pageSize = 2;

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading] = useState(false);
  return (
    <div>
      <ServerSideGrid
        rowData={rowData}
        headerHeight={80}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: false,
          filter: true,
          flex: 1,
          minWidth: 100,
        }}
        rowHeight={50}
        handleCellClick={() => {}}
        onRowClicked={() => {}}
        loading={loading}
        disableClickSelectionRenderers={false}
        noDataTxt="no data found"
        TableHeight={80}
        rowSelection="multiple"
        onSelectionChanged={() => {}}
        pageSize={pageSize}
        totalDataCount={data.length}
        serverRowSize={pageSize}
        currentPage={page}
        serverSidePagination={true}
        serverPageCount={Math.ceil(data.length / pageSize)}
        setServerRowSize={(rowSize: number) => {
          setPageSize(rowSize);
        }}
        setServerSidePage={(e: any, p: number) => {
          setPage(p);
        }}
      />
    </div>
  );
};

export default Table;
