import {
  Box,
  Button,
  Checkbox,
  Group,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import ModuleServices from "../../services/ModuleServices";
import Permissionservices from "../../services/PermissionServices";
import RoleServices from "../../services/RoleSevices";

interface CardPropsType {
  get_permission_list: any;
  isEditModal: any;
  setEditModal: any;
  editper_data: any;
}

const EditPermissionModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setEditModal, get_permission_list, isEditModal, editper_data } =
    props;
  console.log(editper_data, "editper_data");
  const [popup, setPopup]: any = useState(true);
  const [rolelist, setRoleslist]: any = useState();
  const [loading, setLoading]: any = useState(false);
  const [perissionName, SetPermissionName]: any = useState();
  const [roleId, SetRoleId]: any = useState();
  const [modulesData, setModulesData] = useState<any>([]);
  const [modulesCheck, setModulesCheck] = useState(false);
  const [modulesCheckRead, setModulesCheckRead] = useState(false);
  const [modulesCheckWrite, setModulesCheckWrite] = useState(false);
  const [datas, setDatas] = useState<any>({
    permissionName: "",
    role: "",
    status: true,
    module: modulesData,
  });

  const [editDatas, setEditDatas] = React.useState<any>({
    permissionName: editper_data?.role_permissions[0].permission_name,
    roleName: String(editper_data?.role_name),
    status: editper_data?.status,
    module: editper_data.role_permissions,
  });

  const [resData, setResDatas] = React.useState<any>({
    permission_name: editper_data?.role_permissions[0].permission_name,
    id: editper_data?.id,
    status: editper_data?.status,
    module: editper_data.role_permissions.map((d: any) => ({
      module_id: d?.modules?.id,
      can_read: d.can_read,
      can_write: d.can_write,
      module_name: d.modules.module_name,
      rowchecked: d.can_read && d.can_write === true ? true : false,
      uuid: d.uuid,
    })),
  });
  console.log(resData, "resData");

  useEffect(() => {
    setEditModal(popup);
    getRolelists();
    getModules();
  }, [popup]);

  const selectAllModules = (event: any) => {
    const checkedValue = event.target.checked;
    const arr = resData.module;
    let checkAllModules: any = [];
    arr.forEach((acc: any) =>
      checkAllModules.push({
        ...acc,
        can_read: checkedValue,
        can_write: checkedValue,
      })
    );
    setModulesCheck(checkedValue);
    setModulesCheckRead(false);
    setModulesCheckWrite(false);
    setResDatas({ ...editDatas, module: checkAllModules });
  };

  const selectAllRead = (event: any) => {
    const checkedValue = event.target.checked;
    const arr = resData.module;
    let checkAllModules: any = [];
    arr.forEach((acc: any) =>
      checkAllModules.push({
        ...acc,
        can_read: checkedValue,
      })
    );
    setModulesCheckRead(checkedValue);
    setResDatas({ ...resData, module: checkAllModules });
  };
  const selectAllWrite = (event: any) => {
    const checkedValue = event.target.checked;
    const arr = resData.module;
    let checkAllModules: any = [];
    arr.forEach((acc: any) =>
      checkAllModules.push({
        ...acc,
        can_write: checkedValue,
        can_read: checkedValue,
      })
    );
    setModulesCheckRead(checkedValue);
    setModulesCheckWrite(checkedValue);
    setResDatas({ ...resData, module: checkAllModules });
  };

  const selectModulesByRow = (
    id: any,
    moduleName: string,
    event: any,
    index: any
  ) => {
    const checkedValue = event.target.checked;
    let newData = { ...resData.module };
    newData = resData.module.map((d: any) => {
      const newModulesDataByRow = { ...d };
      if (newModulesDataByRow.module_id === id) {
        newModulesDataByRow.can_read = checkedValue;
        newModulesDataByRow.can_write = checkedValue;
        newModulesDataByRow.rowchecked = checkedValue;
      }
      return newModulesDataByRow;
    });
    setResDatas({ ...resData, module: newData });
  };
  console.log(resData);
  const selectReadOnly = (event: any, index: any, id: any) => {
    const checkedValue = event.target.checked;
    const arr = resData.module;
    arr.splice(index, 1, {
      ...arr[index],
      can_read: checkedValue,
    });
    setResDatas({ ...datas, module: arr });
  };
  const selectWriteOnly = (event: any, index: any) => {
    const checkedValue = event.target.checked;
    const arr = resData.module;
    arr.splice(index, 1, {
      ...arr[index],
      can_write: checkedValue,
      can_read: checkedValue,
    });
    setResDatas({ ...datas, module: arr });
  };
  const getRolelists = async () => {
    let data = {
      select: {
        id: true,
        role_name: true,
      },
    };

    try {
      await RoleServices.Rolelists(data).then((res) => {
        if (res.status === true) {
          setRoleslist(res.data.roles);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    let obj = resData;
    console.log("responseDataaaaa", obj);

    let arr = [];
    for (let i = 0; i < obj.module.length; i++) {
      delete obj.module[i].rowchecked;
      delete obj.module[i].module_name;
      obj.module[i]["permission_name"] =
        editper_data?.role_permissions[0].permission_name;
      obj.module[i]["role_id"] = editper_data?.id;
      arr.push(obj.module[i]);
    }
    console.log("arrrrrrr", arr);

    // obj.module = arr;
    try {
      await Permissionservices.Updatepermission(arr).then((res) => {
        setLoading(false);
        if (res.code === 400) {
          setPopup(false);
          showNotification({
            color: "violet",
            message: "invalid data",
          });
        } else if (res.status === true) {
          setPopup(false);
          get_permission_list();
          showNotification({
            color: "violet",
            message: res.message,
          });
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getModules = async () => {
    setLoading(true);
    try {
      let data = {
        select: {
          id: true,
          module_name: true,
        },
      };

      await ModuleServices.Modulelists(data).then((res) => {
        if (res.status === true) {
          let arr = res?.data?.modules;
          let arr1 = [];
          let prevData: any = [...resData.module];
          for (let i = 0; i < editper_data?.role_permissions?.length; i++) {
            arr1.push(editper_data?.role_permissions[i]?.modules.id);
          }

          for (let i = 0; i < arr.length; i++) {
            if (!arr1.includes(arr[i].id)) {
              console.log(arr[i], "datas");

              let obj = {
                module_id: arr[i].id,
                can_read: false,
                can_write: false,
                module_name: arr[i].module_name,
                rowchecked: false,
              };
              prevData.push(obj);

              // let data = [...resData.module, obj];
            }
          }
          setResDatas({ ...resData, module: prevData });
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  console.log(resData, "getModules");
  return (
    <>
      <div
        className={popup ? "permission-pop-main active" : "permission-pop-main"}
      >
        {isEditModal === true && (
          <Modal
            size={600}
            opened={popup}
            onClose={() => {
              setPopup(false);
            }}
            title="Add permission"
            centered
          >
            <div>
              <Group>
                <TextInput
                  style={{ width: "260px" }}
                  size="sm"
                  value={editDatas.permissionName}
                  onChange={(value) =>
                    setEditDatas({ ...editDatas, permissionName: value })
                  }
                  label="Permission name"
                  disabled
                />
                <Select
                  label="Select Role"
                  style={{ width: "260px" }}
                  placeholder="Pick one"
                  disabled
                  // value={editDatas.roleName}
                  defaultValue={editDatas?.roleName}
                  data={
                    Array.isArray(rolelist)
                      ? rolelist.map((e: any, i: any) => {
                          return {
                            value: e.id,
                            label: e.role_name,
                          };
                        })
                      : []
                  }
                  onChange={(value) => {
                    setResDatas({ ...resData, id: value });
                  }}
                />
              </Group>
              <Group position="apart" mt="sm">
                <div>
                  <h3>Modules</h3>
                  <Checkbox
                    label="Select All"
                    defaultChecked={modulesCheck}
                    checked={modulesCheck}
                    onChange={(e) => selectAllModules(e)}
                    mt="sm"
                  />
                </div>
                <div>
                  <h3>Access</h3>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      mr="lg"
                      mt="sm"
                      label="ReadAll"
                      defaultChecked={modulesCheckRead}
                      checked={modulesCheckRead}
                      onChange={(event: any) => selectAllRead(event)}
                      disabled={modulesCheck}
                    />
                    <Checkbox
                      mt="sm"
                      onChange={(event: any) => selectAllWrite(event)}
                      defaultChecked={modulesCheckWrite}
                      checked={modulesCheckWrite}
                      disabled={modulesCheck}
                      label="WriteAll"
                    />
                  </div>
                </div>
              </Group>
              <Box
                sx={{
                  height: "400px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                {resData.module?.map((item: any, index: any) => (
                  <Group position="apart" mt="sm">
                    <div>
                      <Checkbox
                        label={item.module_name}
                        defaultChecked={item.rowchecked}
                        checked={item.rowchecked}
                        onChange={(event: any) =>
                          selectModulesByRow(
                            item.module_id,
                            item.module_name,
                            event,
                            index
                          )
                        }
                        disabled={modulesCheck}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        style={{ marginRight: "2.2rem" }}
                        // defaultChecked={item?.can_read === true ? true : false }
                        checked={item?.can_read}
                        onChange={(event: any) =>
                          selectReadOnly(event, index, item?.module_id)
                        }
                        label="Read"
                      />
                      <Checkbox
                        mr="md"
                        // defaultChecked={item?.can_write}
                        checked={item?.can_write === true ? true : false}
                        onChange={(event: any) => selectWriteOnly(event, index)}
                        label="Write"
                      />
                    </div>
                  </Group>
                ))}
              </Box>
              <Group position="right" mt="md">
                <Button
                  size="sm"
                  style={{ background: "#25204f" }}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  save
                </Button>
              </Group>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default EditPermissionModal;
