import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Button from "./Button";
// import Button from "@mui/material/Button";

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    padding: theme.spacing(4),
    position: "absolute",
    top: theme.spacing(25),
  },
  dialogTitle: {
    textAlign: "center",
    fontSize: "12px",
  },
  dialogContent: {
    textAlign: "center",
    fontSize: "10px",
  },
  dialogAction: {
    justifyContent: "center",
    paddingTop: "15px",
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: "red",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      cursor: "default",
    },
  },
}));

export default function ConfirmDialog(props: any) {
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}></DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle1">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          text="No"
          color="error"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
        <Button text="Yes" color="success" onClick={confirmDialog.onConfirm} />
      </DialogActions>
    </Dialog>
  );
}
