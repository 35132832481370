import { useEffect, useState } from "react";
// import "./CertificateSetting.css";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
} from "@mantine/core";

import { showNotification } from "@mantine/notifications";
import DocumentFieldsServices from "../../../services/DocumentFieldsService";
import DeleteModal from "../../AllPopUPs/DeleteModal";
import DocoumentFiledsModal from "../../AllPopUPs/DocumentFieldsModal";
import NoData from "../../LottieFiles/NoData";
export const DocumentFields = () => {
  const rows = [
    { id: "1", Levels: "level1", number: "12" },
    { id: "1", Levels: "level2", number: "12" },
    { id: "1", Levels: "level3", number: "12" },
    { id: "1", Levels: "level4", number: "12" },
    { id: "1", Levels: "level5", number: "12" },
  ];

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [docTotal, setDocTotal]: any = useState();
  const [DocoumentFieldsModal, setDocoumentFieldsModal]: any = useState(false);
  const [organsizelists, setOrgansizelist]: any = useState("");
  const [documentFieldlist, setDocumentFiledlist]: any = useState([]);
  const [documentType, setDocumentType]: any = useState([]);
  const [editModal, setEditModal]: any = useState("");
  const [documentid, setDocumentId]: any = useState();
  const [edit_field, setEdit_field]: any = useState("");
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setcurrentPage = (value: any) => {
    setPage(value);
    setDocumentFiledlist([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setDocumentFiledlist([]);
  };

  const getdocumentfieldlist = async () => {
    setLoading(true);
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
      where: {
        document_type_id: documentid,
      },
    };
    try {
      let res = await DocumentFieldsServices.DocoumentFieldslist(data);
      if (res.status === true) {
        setDocumentFiledlist(res.data.document_fields);
        setDocTotal(res.data.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const getorgansizelists = async () => {
  //   try {
  //     await OrgansizationServices.Organsizationlists().then((res) => {
  //       setOrgansizelist(res.organization);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleDeletefield = async (param: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      let res = await DocumentFieldsServices.DeleteDocoumentFiled(param);
      getdocumentfieldlist();
      setLoading(false);
      showNotification({
        color: "green",
        message: res.message,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdate = async (params: any) => {
    setEditModal(true);
    setEdit_field(params);
  };
  const getDocumentTypes = async () => {
    try {
      let res = await DocumentFieldsServices.DocoumentTypeslist();
      setDocumentType(res.data.documentType);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const test = (e: any) => {
    console.log(e, "documentid");
  };

  useEffect(() => {
    getDocumentTypes();
  }, []);
  useEffect(() => {
    getdocumentfieldlist();
  }, [page, pageSize, documentid]);
  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {DocoumentFieldsModal && (
        <DocoumentFiledsModal
          setDocumentFieldsModal={setDocoumentFieldsModal}
          get_documentField_list={getdocumentfieldlist}
          setEditModal={setEditModal}
          edit_document_fileds={edit_field}
          editModal={editModal}
          documentTypes={documentType}
        />
      )}
      <div>
        <Card mt="sm" radius="lg" shadow="lg">
          <Card.Section>
            <Group position="apart">
              <Box m="md">
                <Select
                  label="Fields by DocumentTypes"
                  placeholder="Select DocumentTypes"
                  data={
                    Array.isArray(documentType)
                      ? documentType.map((e: any, i: any) => {
                          return {
                            value: e.id,
                            label: e.name,
                          };
                        })
                      : []
                  }
                  onChange={(value) => {
                    setDocumentId(value);
                  }}
                  searchable
                  maxDropdownHeight={400}
                  nothingFound="Nobody here"
                />
              </Box>
              <Box p="md">
                <Button
                  onClick={() => {
                    setDocoumentFieldsModal(true);
                    setEditModal(false);
                  }}
                  style={{ background: "#25204f" }}
                  size="sm"
                >
                  +Add
                </Button>
              </Box>
            </Group>
            {loading === true ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "50px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            ) : documentFieldlist && documentFieldlist.length > 0 ? (
              <div>
                <Table verticalSpacing="lg" highlightOnHover>
                  <thead style={{ border: "none" }}>
                    <tr>
                      <th>
                        <Checkbox size="sm" />
                      </th>
                      <th>
                        <div>Name</div>
                      </th>
                      <th>
                        <div>Field Type</div>
                      </th>
                      <th>
                        <div>Field Label</div>
                      </th>
                      <th>
                        <div>status</div>
                      </th>
                      <th>
                        <div>Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {documentFieldlist?.map(
                      (documentField: any, index: number) => (
                        <tr
                          key={documentField?.uuid}
                          className={
                            documentField?.status === false
                              ? "in-active-list"
                              : ""
                          }
                        >
                          <td>
                            <Checkbox size="sm" />
                          </td>
                          <td>{documentField?.field_name}</td>
                          <td>{documentField?.field_type}</td>
                          <td>{documentField?.field_label}</td>
                          <td>
                            {documentField?.status === true
                              ? "Active"
                              : "In-Active"}{" "}
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                handleUpdate(documentField);
                                setDocoumentFieldsModal(true);
                                setEditModal(true);
                              }}
                              variant="default"
                              radius="md"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Want to Delete?",

                                  onConfirm: () => {
                                    handleDeletefield(documentField);
                                  },
                                });
                              }}
                              variant="default"
                              radius="md"
                              ml="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                <Group position="apart" mt="md" p="lg">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box mr="md">Rows per page</Box>
                    <Select
                      onChange={(e) => setrowsperpage(e)}
                      style={{ width: "4rem" }}
                      value={String(pageSize)}
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                    />
                  </Box>
                  <Pagination
                    styles={(theme) => ({
                      item: {
                        "&[data-active]": {
                          backgroundColor: "#25204f",
                        },
                      },
                    })}
                    total={Math.ceil(docTotal / pageSize)}
                    boundaries={5}
                    page={page}
                    initialPage={1}
                    onChange={(e) => setcurrentPage(e)}
                  />
                </Group>
              </div>
            ) : (
              <NoData />
            )}
          </Card.Section>
        </Card>
      </div>
    </>
  );
};
