import { CloseButton, Modal, Button, Group, Text } from "@mantine/core";
import React, { useState } from "react";
import "./ConfirmPop.css";

interface CardPropsType {
  setConfirmBox: any;
  setConfirm?: any;
  setBox?: any;
  message?: any;
  description?:string
}
const ConfirmPop: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setConfirmBox, setBox, setConfirm, message, description } = props;
  const [popup, setPopup]: any = useState(true);

  return (
    <>
      <Modal
        size="md"
        opened={popup}
        onClose={() => setPopup(false)}
        withCloseButton={false}
        centered
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <h3>{message}</h3>
        </div>
        {description && (
        <Group  mt="md">
          <Text color="gray">
          {description}
          </Text>
        </Group>
        )}
        <Group mt="xl" position="center">
            <Button
            color="red"
            variant="outline"
              onClick={() => {
                setConfirmBox(false);
                // setBox(true);
              }}
            >
              Close
            </Button>
            <Button
            color="blue"
              onClick={() => {
                setConfirm(true);
                setConfirmBox(false);
              }}
            >
              Confirm
            </Button>
        </Group>
      </Modal>
    </>
  );
};

export default React.memo(ConfirmPop);
