import { Button, Grid, Image, Input, PasswordInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../redux/features/TokenSlice";
import { logins } from "../../redux/features/userSlice";
import AuthService from "./AuthService";
import "./login.css";
const Loginform = () => {
  const dispatch = useDispatch();
  const [input, setInput]: any = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<String>("");
  const [loading, setLoading]: any = useState(false);
  const navigate = useNavigate();
  const [visible, { toggle }] = useDisclosure(false);
  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    // console.log(input);
  };
  const formSubmitter = async (e: any) => {
    e.preventDefault();
    setSuccessMessage("");
    try {
      setLoading(true);
      await AuthService.login(input).then((res) => {
        dispatch(logins(res.data.user));
        dispatch(setToken(res.data.token));
        setSuccessMessage(res.data);
        setLoading(false);
        navigate("/");
      });
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setErrorMessage(error.response.data.data.error);
      showNotification({
        color: "violet",
        message: "Invalid Login Credentials",
      });
    }
  };

  return (
    <>
      <Grid
        style={{
          width: "100% ",
          height: "100vh",
          margin: "px",
          justifyContent: "center",
        }}
      >
        <Grid.Col
          md={12}
          style={{
            backgroundColor: "#fff7fb",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          lg={12}
        >
          <Grid>
            <Grid.Col
              md={12}
              lg={6}
              style={{
                backgroundColor: "#ffffff",
                width: "60vh",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                padding: "20px",
              }}
            >
              <div className="form_content">
                <div
                  className="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h6 style={{ fontSize: "20px", paddingTop: "70px" }}>
                      CERTIFY
                    </h6>
                  </div>
                  {/* <Image
                    src={require("../../Assets/genpact-logo.png")}
                    width={200}
                    height={150}
                    alt="Certify"
                    radius="md"
                    w="auto"
                    fit="contain"
                  /> */}
                </div>

                <div className="fromBox">
                  <form onSubmit={formSubmitter}>
                    <div className="inputBox">
                      <Input.Wrapper label="Username" required>
                        <Input
                          type={"text"}
                          name="email"
                          placeholder="hello@example.com"
                          onChange={handleChange}
                        />
                      </Input.Wrapper>
                    </div>
                    <div className="inputBox">
                      <Input.Wrapper label="Password" required>
                        <PasswordInput
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                        />
                      </Input.Wrapper>
                    </div>

                    <div>
                      {errorMessage &&
                        errorMessage.length > 0 &&
                        errorMessage.map((errMsg: any) => (
                          <p style={{ color: "red", margin: "10px 0px" }}>
                            {errMsg}
                          </p>
                        ))}
                      {successMessage.length > 0 && (
                        <p style={{ color: "green" }}>{successMessage}</p>
                      )}
                    </div>
                    <div className="login_btn">
                      <Button
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        style={{
                          background: "#25204f",
                          fontSize: "16px",
                          width: "150px",
                        }}
                      >
                        {loading ? "Loading..." : "Login"}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Grid.Col>
            <Grid.Col
              md={12}
              lg={6}
              style={{
                backgroundColor: "#a6a0ce",
                width: "60vh",

                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <div className="img_cir">
                <img
                  style={{ width: "350px" }}
                  className="img1"
                  src={require("../../Assets/Loginlogo.png")}
                  alt="Certify"
                />
              </div>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
};
export default Loginform;
