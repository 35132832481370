import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const getNotification = async () => {
  try {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const response = await axios.get(`${API_URL}/activity`, config);

    console.log(response);

    return response;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

const NotifyService = {
  getNotification,
};
export default NotifyService;
