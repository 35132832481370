import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const DocoumentlistType = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-document-type`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};
const AddDocoumentType = async (value: any, status: any) => {
  let body = JSON.stringify({
    name: value,
    status: status,
  });
  let response = await fetch(
    `${API_URL}/document-type`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteDocoument = async (params: any) => {
  let response = await fetch(
    `${API_URL}/document-type/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateDocoument = async (edited: any, edit_dep: any, checked: any) => {
  // console.log(edit_dep);

  let body = JSON.stringify({
    name: edited,
    status: checked,
  });

  let response = await fetch(
    `${API_URL}/document-type/${edit_dep.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

const GetDocoument = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/document-type/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      return response.data.data.roles;
    });
};

const DocumentTypesServices = {
  DocoumentlistType,
  AddDocoumentType,
  DeleteDocoument,
  UpdateDocoument,
  GetDocoument,
};
export default DocumentTypesServices;
