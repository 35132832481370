import { CloseButton, Input, Loader } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { TextStyle } from "../../custom";
import API_Services from "../../services/CertificateUsersService";
import ConfirmPop from "../confirmPopup/ConfirmPop";
import RejectPop from "../RejectPopup/RejectPop";
import "./IssuePop.css";

interface CardPropsType {
  setIssuePop?: any;
  detail?: any;
  setConfirm?: any;
  input?: any;
  setInput?: any;
  getRequestList?: any;
}
const IssuePopup: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setIssuePop, detail, setConfirm, input, setInput, getRequestList } =
    props;
  const [popup, setPopup]: any = useState(true);
  const [issueBox, setBox]: any = useState(true);
  const [rejectPop, setRejectPop]: any = useState(false);
  const [confirmBox, setConfirmBox]: any = useState(false);
  const [requestFields, setRequestField]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  setIssuePop(popup);

  useEffect(() => {
    getIssueFields();
    console.log(detail);
  }, []);

  useEffect(() => {
    if (detail) {
      setInput(detail.document_info[0]);
    }
  }, [detail]);
  console.log(input);
  console.log(requestFields);



  const getIssueFields = async () => {
    setLoading(true);
    await API_Services.getDocumentFileds(detail.document_type_id).then(
      (res) => {
        setRequestField(res.data.document_fields);
        setLoading(false);
      }
    );
  };

  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <>
      {issueBox && (
        <div className={popup ? "issue-pop-main active" : "issue-pop-main"}>
          <div className="issue-pop" style={{ background: "white" }}>
            <CloseButton
              className="close1"
              title="Close popover"
              size="xl"
              onClick={() => setPopup(false)}
              iconSize={20}
            />
            <h3>Issue Certificate</h3>
            <div className="issue-Table">
              <table style={{ width: "300px" }}>
                <tr>
                  <th>Employee Name</th>
                  <th>Document</th>
                </tr>
                <tr>
                  <td>{input && input.employeeName}</td>
                  <td>{detail.template_name}</td>
                </tr>
              </table>
            </div>
            {loading && <Loader color="#25204f" />}
            {/* Request fields rendering */}
            {requestFields.length > 0 ? (
              <div
                className="issue-inputfield"
                style={{ fontFamily: TextStyle.fontFamily }}
              >
                {requestFields.map((e: any, id: any) => {
                  if (e.field_type === "date") {
                    return (
                      <DatePicker
                        placeholder={
                          input[e.field_name] ? input[e.field_name] : "Pick one"
                        }
                        label={e.field_label}
                        inputFormat="DD/MM/YYYY"
                        labelFormat="DD/MM/YYYY"
                        radius="sm"
                        size="sm"
                        className="inputfields"
                        name={e.field_name}
                        onChange={(val) => {
                          // @ts-ignore
                          let event = new Date(val).toLocaleString().split(",");
                          setInput({ ...input, [e.field_name]: event[0] });
                        }}
                      />
                    );

                    // <Input.Wrapper id={id} label={e.field_label} className="inputfields">
                    //   <Input type={e.field_type} value={oldDate[0]} onChange={handleChange} name={e.field_name} id={id} radius="sm" size="sm" />
                    // </Input.Wrapper>
                  } else {
                    return (
                      <Input.Wrapper
                        id={id}
                        label={e.field_label}
                        className="inputfields"
                      >
                        <Input
                          type={e.field_type}
                          value={input[e.field_name]}
                          onChange={handleChange}
                          name={e.field_name}
                          id={id}
                          radius="sm"
                          size="sm"
                        />
                      </Input.Wrapper>
                    );
                  }
                })}
              </div>
            ) : (
              <div style={{ height: "50px" }}></div>
            )}

            <div>
              <button
                className="issue-cancel-btn"
                onClick={() => {
                  setBox(false);
                  setRejectPop(true);
                }}
              >
                Reject
              </button>
              <button
                className="issue-request-btn  "
                onClick={() => {
                  setBox(false);
                  setConfirmBox(true);
                }}
              >
                Issue
              </button>
            </div>
          </div>
        </div>
      )}
      {rejectPop && (
        <RejectPop detail={detail} getRequestList={getRequestList} />
      )}
      {confirmBox && (
        <ConfirmPop
          setConfirmBox={setConfirmBox}
          setConfirm={setConfirm}
          setBox={setBox}
          message="Issue Certificate ?"
        />
      )}
    </>
  );
};

export default React.memo(IssuePopup);
