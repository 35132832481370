import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const requestLifeCycle = async (props: any) => {
  let body = JSON.stringify({
    request_id: props,
    include: {
      users_certificate_request_approved_byTousers: true,
    },
  });
  let res = await fetch(
    `${API_URL}/lifecycle-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await res.json();
};

// list certificate requests
const listCertificateRequest = async (props: any, limit?: any, skip?: any) => {
  const body = JSON.stringify({
    where: props,
    include: {
      certificate_approval_template: {
        include: {
          department: true,
        },
      },
      document: true,
      certificate: {
        include: {
          digitalized_certificate: true,
        },
      },
      users_certificate_request_requested_byTousers: true,
      users_certificate_request_issued_byTousers: true,
      users_certificate_request_rejected_byTousers: true,
      users_certificate_request_approved_byTousers: true,
    },
    skip: skip,
    limit: limit,
  });

  let res = await fetch(
    `${API_URL}/list-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await res.json();
};

const listCertificate = async (props: any) => {
  let body = JSON.stringify({
    where: {
      user_id: props,
    },
  });

  let res = await fetch(
    `${API_URL}/list-certificate`,
    CommonMethod.PostMethod(body)
  );
  return await res.json();
};

const getApprovalTemplateStages = async (
  templateId: number,
  requestId: number,
  user_detail_id: any
) => {
  let body = JSON.stringify({
    where: {
      certificate_approval_template: templateId,
    },
    include: {
      certificate_approval_stage: true,
      user_details: {
        include: {
          users: {
            include: {
              user_roles: {
                include: {
                  roles: true,
                },
              },
            },
          },
        },
      },
    },

    certificate_approval_stage: {
      where: {
        request_id: requestId,
      },

      // include: {
      //   user_details: {
      //     include: {
      //       users: {
      //         include: {
      //           user_roles: {
      //             include: {
      //               roles: true,
      //             },
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
    },
  });

  let res = await fetch(
    `${API_URL}/list-certificate-approval-template-stage`,
    CommonMethod.PostMethod(body)
  );
  return await res.json();
};

const MyCertificateServices = {
  requestLifeCycle,
  listCertificateRequest,
  listCertificate,
  getApprovalTemplateStages,
};
export default MyCertificateServices;
