import {
  Box,
  Button,
  CloseButton,
  Input,
  LoadingOverlay,
  MultiSelect,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";

import { COLORS } from "../../custom";
import API_Services from "../../services/CertificateUsersService";
import "./EditUser.css";
import * as _ from "lodash";

interface CardPropsType {
  setOpenEdituser: any;
  editData: any;
  getuserlist: any;
}
const Edituser: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setOpenEdituser, editData, getuserlist } = props;
  const [designationsList, setdesignationsList]: any = useState([]);
  const [userRoleList, setUserRoleList]: any = useState([]);
  const [departmentList, setdepartmentList]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
 
  const [userRoles, setUserRoles] = useState(
    editData.user_roles.map((item: any) => {
      return item.roles.id;
    })
  );
  const [useridInput, setuseridInput]: any = useState([]);

  useEffect(() => {
    getDropdownValue();
  }, []);
console.log(editData);

  const form = useForm({
    initialValues: {
      username: editData.username,
      first_name: editData.first_name,
      last_name: editData.last_name,
      email: editData.email,
      phone: editData.phone,
      department_id: editData.user_details.department_id,
      designation_id: editData.user_details.designation_id,
      date_of_joining: editData?.date_of_joining?.split("T")[0],
      user_roles: userRoles,
    },

    // functions will be used to validate values at corresponding key
    // validate: {
    // username: (value) => (value.length < 2 ? 'Username is required' : null),
    // first_name: (value) => (value.length < 2 ? 'First name is required' : null),
    // last_name: (value) => (value.length < 2 ? 'Last name is required' : null),
    // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    // phone: (value) => (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value) ? null : 'Invalid Phone Number'),
    // belshare_id: (value) => (value.length < 1 ? 'belshare_id is required' : null),
    // designation_id: (value) => (value.length < 1 ? 'Select at least one designation' : null),
    // department_id: (value) => (value.length < 1 ? 'Select at least one department' : null),
    // },
  });

  const handleSubmit = async (e: any) => {
    setLoading(true);
    try {
      let updatedValue = {
        username: e.username,
        first_name: e.first_name,
        last_name: e.last_name,
        email: e.email,
        phone: e.phone,
        user_details: {
          update: {
            department_id: e.department_id,
            designation_id: e.designation_id,
          },
        },
        user_roles: {
          create: useridInput,
        },
      };

      let updateRes = await API_Services.UpdateUser(
        editData.uuid,
        updatedValue
      );

      if (updateRes.status) {
        showNotification({
          color: "voilet",
          message: "User Updated successfully",
        });
        console.log(updateRes);
        getuserlist();
        setOpenEdituser(false);
      } else {
        showNotification({
          color: "red",
          message: updateRes.message,
        });
      }
    } catch (error: any) {
      showNotification({
        color: "red",
        message: "Somthing went wrong",
      });
    }
    setLoading(false);
  };

  const handleChange = async (event: any) => {
    setUserRoles(event);
    console.log("userRoles", userRoles);

    console.log("event", event);

    console.log(
      "userRoles.length < event.length",
      userRoles.length < event.length
    );

    if (userRoles.length < event.length) {
      // console.log("event",event);

      let filteredKeywords = event.filter(
        (word: any) => !userRoles.includes(word)
      );
      console.log("filteredKeywords", filteredKeywords);

      let findid = userRoleList.filter(
        (role: any) => role.role_name === filteredKeywords[0]
      );

      setuseridInput([...useridInput, { role_id: filteredKeywords[0] }]);
    } else {
      setLoading(true);
      let filteredKeywords = editData.user_roles.filter((word: any) => {
        console.log("if", !event.includes(word.roles.role_name));

        return !event.includes(word.roles.role_name);
      });
      console.log("filteredKeywords else", filteredKeywords);

      await API_Services.DeleteUserRole({
        uuid: filteredKeywords[0].uuid,
      }).then((res: any) => {
        if (res) setLoading(false);
      });
    }
  };

  const getDropdownValue = async () => {
    try {
      await API_Services.fetchDesignationList().then((res) =>
        setdesignationsList(res.data.designations)
      );
      await API_Services.fetchUserRoleList().then((res) =>
        // console.log('user roleee',res.data.roles)
        setUserRoleList(res.data.roles)
      );
      await API_Services.fetchDepartmentList().then((res) =>
        setdepartmentList(res.data.department)
      );
    } catch (error) {
      console.log(error);
    }
  };

  let roles: any = [];

  // Mutiselect functionality
  function data() {
    let daaa;
    // let removeDups=userRoles.filter((item:any, index:any) => userRoles.indexOf(item) === index)

    // console.log(removeDups);

    return Array.isArray(editData.user_roles)
      ? editData.user_roles.map((e: any) => {
          return {
            value: e.roles.id,
            label: e.roles.role_name,
          };
        })
      : [];
    // return (daaa = Array.isArray(userRoleList)
    //   ? userRoleList
    //       .sort((a, b) => {
    //         if (a.role_name.toLowerCase() < b.role_name.toLowerCase())
    //           return -1;
    //         if (a.role_name.toLowerCase() > b.role_name.toLowerCase()) return 1;
    //         return 0;
    //       })
    //       .map((e: any) => {
    //         roles.push({ value: e.id, label: e.role_name });
    //         return e.role_name;
    //       })
    //   : []);
  }

  return (
    <div
      className="Edit-user-main"
      style={{ backgroundColor: COLORS.backgroundContent }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Open Sans",
            fontWeight: 600,
            fontSize: "23px",
            color: "rgb(96, 78, 158)",
            paddingLeft: "25px",
          }}
        >
          Update user
        </p>

        <CloseButton
          title="Close popover"
          onClick={() => setOpenEdituser(false)}
          size="xl"
          iconSize={20}
        />
      </Box>
      <form
        className="edit-user-form"
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
      >
        <TextInput
          label="User name"
          radius="sm"
          size="sm"
          className="input"
          name="username"
          placeholder="User name"
          {...form.getInputProps("username")}
          variant="filled"
        />
        <TextInput
          label="Email"
          radius="sm"
          size="sm"
          className="input"
          name="email"
          placeholder="xyz@broll.com"
          {...form.getInputProps("email")}
          variant="filled"
        />

        <TextInput
          label="First name"
          radius="sm"
          size="sm"
          className="input"
          name="first_name"
          placeholder="First Name"
          {...form.getInputProps("first_name")}
          variant="filled"
        />

        <TextInput
          label="Last name"
          radius="sm"
          size="sm"
          className="input"
          name="last_name"
          placeholder="Last name"
          {...form.getInputProps("last_name")}
          variant="filled"
        />

        <TextInput
          type="number"
          label="Phone no"
          radius="sm"
          size="sm"
          className="input"
          name="phone"
          placeholder="Phone No"
          {...form.getInputProps("phone")}
          variant="filled"
        />

        <Input.Wrapper label="User role" className="input">
          <MultiSelect
            variant="filled"
            radius="sm"
            size="sm"
            value={userRoles}
            onChange={handleChange}
            data={
              Array.isArray(userRoleList)
                ? userRoleList.map((e: any) => {
                    return {
                      value: e.id,
                      label: e.role_name,
                    };
                  })
                : []
            }
             // {...form.getInputProps('roles_id')}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Designation type" className="input">
          <Select
            variant="filled"
            radius="sm"
            size="sm"
            data={
              Array.isArray(designationsList)
                ? designationsList
                    .sort((a, b) => {
                      if (
                        a.designation_name.toLowerCase() <
                        b.designation_name.toLowerCase()
                      )
                        return -1;
                      if (
                        a.designation_name.toLowerCase() >
                        b.designation_name.toLowerCase()
                      )
                        return 1;
                      return 0;
                    })
                    .map((e: any, i: any) => {
                      return {
                        value: e.id,
                        label:
                          e.designation_name.charAt(0).toUpperCase() +
                          e.designation_name.slice(1),
                      };
                    })
                : []
            }
            {...form.getInputProps("designation_id")}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Department Type" className="input">
          <Select
            variant="filled"
            radius="sm"
            size="sm"
            placeholder={editData.department_name}
            data={
              Array.isArray(departmentList)
                ? departmentList
                    .sort((a, b) => {
                      if (
                        a.department_name.toLowerCase() <
                        b.department_name.toLowerCase()
                      )
                        return -1;
                      if (
                        a.department_name.toLowerCase() >
                        b.department_name.toLowerCase()
                      )
                        return 1;
                      return 0;
                    })
                    .map((e: any) => {
                      return {
                        value: e.id,
                        label:
                          e.department_name.charAt(0).toUpperCase() +
                          e.department_name.slice(1),
                      };
                    })
                : []
            }
            {...form.getInputProps("department_id")}
          />
        </Input.Wrapper>

        <div className="Edit-user-btn">
          <Button
            type="submit"
            color="red"
            className="edit-btn"
            loading={loading}
            size="md"
          >
            Update
          </Button>
        </div>
      </form>
      <LoadingOverlay
        loaderProps={{ color: "#25204f" }}
        visible={loading}
        overlayBlur={1.5}
      />
    </div>
  );
};

export default Edituser;
