import React from "react";
import CertificateForm from "./components/CertificateForm";
import { Container, Text, Title, useMantineTheme } from "@mantine/core";

function CreateCertificate() {
  const theme = useMantineTheme();

  return (
    <Container>
      <div style={{ flex: 1 }}>
        <Title order={1}>Create Offer Letter</Title>
        <Text color="gray" w={"60%"} mt={5}>
          Safely generate and authenticate certificates using cutting-edge
          blockchain technology. Ensure security, transparency, and trust in
          every certificate creation and verification process.
        </Text>
        <br />
        <CertificateForm />
      </div>
    </Container>
  );
}

export default CreateCertificate;
