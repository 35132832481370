import React from "react";
import "./Financial.css";

export const FinancialPage = () => {
  return (
    <div>
      <h1>FinancialPage</h1>
    </div>
  );
};
