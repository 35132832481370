import React, { useEffect, useMemo, useState } from "react";
import { TextStyle } from "../../custom";
import { useRef } from "react";
import { Text, Group, Button, useMantineTheme } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons-react";
import { Autocomplete, Input, Loader, Modal, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../common/config";
import API_Services from "../../services/CertificateUsersService";
import ConfirmPop from "../confirmPopup/ConfirmPop";
import SuccessPop from "../successPopup/SuccessPop";
import "./RequestPop.css";
import { userProfileImageUpload } from "../../services/UserProfileService";

interface CardPropsType {
  getRequestList?: any;
  setRequestPop?: any;
}
const RequestPop: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setRequestPop, getRequestList } = props;

  const [input, setInput]: any = useState();
  const [uploadFileName, setUploadFileName] = useState("");
  const [documentTypeList, setDocumentTypeList]: any = useState([]);
  const [approvaltemp, setapprovalTemp]: any = useState([]);
  const [popup, setPopup]: any = useState(true);
  const [requestBox, setBox] = useState(true);
  const [confirmBox, setConfirmBox]: any = useState(false);
  const [confirm, setConfirm]: any = useState(false);
  const [doc_Id, setDoc_Id]: any = useState();
  const [certificteRequest, setCertificateRequest]: any = useState();
  const [requestStatus, setRequestStatus]: any = useState();
  const [message, setMessage]: any = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [requestFields, setRequestField]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const [userList, setUserList]: any = useState([]);
  const [userListChange, setUserListChange]: any = useState<string>();
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);
  const userdetails = useSelector((s: any) => s.user.value);
  setRequestPop(popup);
  useEffect(() => {
    if (confirm === true) {
      sendRequest();
    }
  }, [confirm]);

  // useEffect(() => {
  //   listApprovaltemp();
  // }, []);

  useEffect(() => {
    if (requestStatus === "created") {
      getRequestList();
    }
  }, [requestStatus]);

  const handleChange = async (e: any) => {
    try {
      setUploadLoading(true)
      if (e instanceof File) {
        if (e.type !== MIME_TYPES.pdf) {
          alert("Invalid file type. Please upload a PDF file");
          return;
        }
        const formData = new FormData();
        formData.append("file", e);
        const res = await userProfileImageUpload(formData);
        const imageURL = res?.data?.imageURL[0]?.Location;
        setInput({
          ...input,
          letter: imageURL,
        });
        setUploadLoading(false)
      } else {
        setInput({
          ...input,
          [e.target.name]: e.target.value,
        });
        setUploadLoading(false)

      }
    } catch (error) {
      setUploadLoading(false)

      console.error("Error handling change:", error);
    }
  };

  let sendRequest = async () => {
    let token: any = localStorage.getItem("token");
    for (let i = 0; i < input.length; i++) {
      if (_.isEmpty(input[i].object.keys)) {
      }
    }

    const config = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `bearer ${JSON.parse(token)}`,
      },
      body: JSON.stringify({
        document_id: doc_Id.id,
        requested_by: certificteRequest,
        document_info: [input],
      }),
    };
    try {
      await fetch(`${API_BASE_URL}/certificate-request`, config)
        .then((res) => res.json())
        .then((data) => {
          if (data.code == 200) {
            showNotification({
              color: "green",
              message: data.message,
            });
            getRequestList();
            setConfirm(false);
            setRequestPop(false);
          } else if (data.code == 400) {
            setMessage(data.data.errors[0]);
            setRequestStatus("rejected");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  let getDocumentlist = async () => {
    setLoading(true);
    try {
      await API_Services.fetchDocumentList().then((res: any) => {
        setDocumentTypeList(res.data.documentType);

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };



  let getDocumentFields = async (e: any) => {
    setLoading(true);
    try {
      let data = {
        where: {
          document_type_id: e,
          status: true,
        },
        include: {
          document_type: true,
        },
      };
      API_Services.getDocumentFileds(data).then((res: any) => {
        setRequestField(res.data.document_fields);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserListByFilter = async (value: any) => {
    let data;
    if (value) {
      data = {
        limit: 10,
        where: {
          OR: [
            {
              first_name: {
                contains: value,
                mode: "insensitive",
              },
            },
            {
              last_name: {
                contains: value,
                mode: "insensitive",
              },
            },
          ],
        },
      };
    } else {
      data = { skip: 0, limit: 10 };
    }
    API_Services.getUserListByFilter(data)
      .then((res) => {
        if (res?.status) {
          setUserList(res?.data?.users);
        } else {
          console.log("err", "UserList By Filter Error...");
        }
      })
      .catch((err) => {
        console.log("eer", err);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (doc_Id) {
      setConfirmBox(true);
      setBox(false);
    }
  };

  useEffect(() => {
    getUserListByFilter("");
  }, [userListChange]);

  useMemo(async () => {
    getDocumentlist();
  }, []);


  return (
    <>
      {requestBox && (
        <>
          <Modal
            centered
            opened={popup}
            onClose={() => setPopup(false)}
            title={<strong>Create Certificate</strong>}
            size="auto"
          >
            <Select
              onChange={async (e) => {
                setDoc_Id(e);
                getDocumentFields(e);
              }}
              className="doc-btn"
              radius="md"
              size="md"
              placeholder="Document Type"
              data={
                Array.isArray(documentTypeList)
                  ? documentTypeList
                      .sort((a, b) => {
                        if (a.name.toLowerCase() < b.name.toLowerCase())
                          return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase())
                          return 1;
                        return 0;
                      })
                      .map((e: any) => {
                        return { value: e.id, label: e.name };
                      })
                  : []
              }
            />
            {userdetails?.user_roles &&
            userdetails?.user_roles[`REQUEST_CERTIFICATE_AGAINST`] &&
            userdetails?.user_roles[`REQUEST_CERTIFICATE_AGAINST`][0]
              .can_read ? (
              <Autocomplete
                // label="List Users"
                // variant="filled"
                placeholder="List Users"
                name="user"
                limit={10}
                className="doc-btn"
                // data={['Arun', 'Dev']}
                size="md"
                radius="md"
                maxDropdownHeight={400}
                data={
                  Array.isArray(userList)
                    ? userList.map((provider) => {
                        return {
                          label: `${provider.first_name}`,
                          value: `${provider.id}`,
                        };
                      })
                    : []
                }
                onChange={(value) => {
                  // let prevData = { ...input };
                  // prevData.user = value;
                  // setInput(prevData);
                  setCertificateRequest(value);
                  setUserListChange(value);
                  getUserListByFilter(value);
                }}
                nothingFound="Nobody here"
              />
            ) : null}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            )}
            <form onSubmit={handleSubmit}>
              {/* {requestFields.length > 0 ? ( */}
              <div
                className="request-inputfield"
                style={{ fontFamily: TextStyle.fontFamily }}
              >
                {requestFields.map((e: any, id: any) => {
                  if (e.field_type === "date") {
                    return (
                      <DatePicker
                        placeholder="Pick date"
                        label={e.field_label}
                        inputFormat="DD/MM/YYYY"
                        labelFormat="DD/MM/YYYY"
                        radius="md"
                        size="md"
                        className="inputfields"
                        name={e.field_name}
                        onChange={(val) => {
                          // @ts-ignore
                          let event = new Date(val).toLocaleString().split(",");
                          setInput({ ...input, [e.field_name]: event[0] });
                        }}
                      />
                    );
                  } else if (e.field_type === "file") {
                    return (
                      <div>
                        <Dropzone
                          openRef={openRef}
                          onDrop={(acceptedFiles) => {
                            handleChange(acceptedFiles[0]);
                            setUploadFileName(acceptedFiles[0].name);
                          }}
                          // className={classes.dropzone}
                          radius="md"
                          accept={[MIME_TYPES.pdf]}
                          maxSize={30 * 1024 ** 2}
                          loading={uploadLoading}
                        >
                          <div style={{ pointerEvents: "none" }}>
                            <Group
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Dropzone.Accept>
                                <IconDownload
                                  // style={{ width: rem(50), height: rem(50) }}
                                  color={theme.colors.blue[6]}
                                  stroke={1.5}
                                />
                              </Dropzone.Accept>
                              <Dropzone.Reject>
                                <IconX
                                  // style={{ width: rem(50), height: rem(50) }}
                                  color={theme.colors.red[6]}
                                  stroke={1.5}
                                />
                              </Dropzone.Reject>
                              <Dropzone.Idle>
                                <IconCloudUpload
                                  // style={{ width: rem(50), height: rem(50) }}
                                  stroke={1.5}
                                />
                              </Dropzone.Idle>
                            </Group>

                            <Text ta="center" fw={700} fz="lg" mt="xl">
                              <Dropzone.Accept>Drop files here</Dropzone.Accept>
                              <Dropzone.Reject>
                                Pdf file less than 30mb
                              </Dropzone.Reject>
                              <Dropzone.Idle>Upload Certificate</Dropzone.Idle>
                            </Text>
                            <Text ta="center" fz="sm" mt="xs" c="dimmed">
                              We can accept only <i>.pdf</i> files that are less
                              than 30mb in size.
                            </Text>
                          </div>
                        </Dropzone>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "10px",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            // className={classes.control}
                            size="md"
                            radius="xl"
                            onClick={() => openRef.current?.()}
                          >
                            Select files
                          </Button>
                          <p>{uploadFileName}</p>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <Input.Wrapper
                        id={id}
                        label={e.field_label}
                        className="inputfields"
                        withAsterisk={e?.is_required}
                      >
                        <Input
                          type={e.field_type}
                          onChange={handleChange}
                          name={e.field_name}
                          required={e?.is_required}
                          id={id}
                          radius="md"
                          size="md"
                        />
                      </Input.Wrapper>
                    );
                  }
                })}
              </div>
              {/* ) : (
                <></>
                // <div>No Data Found</div>
              )} */}

              <div style={{ marginLeft: "15px" }}>
                <button className="cancel-btn" onClick={() => setPopup(false)}>
                  Cancel
                </button>
                <button
                  className="request-btn"
                  type="submit"
                  // onClick={() => {
                  //   if (doc_Id) {
                  //     setConfirmBox(true);
                  //     setBox(false);
                  //   }
                  // }}
                  style={{ background: "#25204f" }}
                >
                  Request
                </button>
              </div>
            </form>
          </Modal>
        </>
      )}
      {confirmBox && (
        <ConfirmPop
          setConfirmBox={setConfirmBox}
          setBox={setBox}
          setConfirm={setConfirm}
          message={"Send Request ?"}
        />
      )}

      {requestStatus === "created" && (
        <SuccessPop message={message} setRequestPop={setRequestPop} />
      )}
    </>
  );
};

export default React.memo(RequestPop);
