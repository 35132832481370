import { Box, Tabs, Text } from "@mantine/core";
import { useState } from "react";
import { Theme } from "../../custom";
import Department from "./Department";
import Designation from "./Designation";
const OrganizationSetting = () => {
  const [activeTab, setActiveTab]: any = useState("departments");
  return (
    <>
      <Box ml='sm'>
        <div>
          <Text
            className="header-text"
            sx={{
              fontFamily: "poppins",
              color:"#25204f",
              fontSize: "22px",
            }}
          >
            Organization Settings
          </Text>
        </div>
        <div>
          <Tabs
            defaultValue="departments"
            radius="md"
            onTabChange={(value) => setActiveTab(value)}
          >
            <Tabs.List>
              <Tabs.Tab
                value="departments"
                style={{ fontSize: "16px", width: "10rem", }}
              >
                Department
              </Tabs.Tab>
              <Tabs.Tab
                style={{ fontSize: "16px", width: "10rem" }}
                value="designation"
              >
                Designation
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="departments" pt="xs">
              {activeTab === "departments" && <Department />}
            </Tabs.Panel>

            <Tabs.Panel value="designation" pt="xs">
              {activeTab === "designation" && <Designation />}
            </Tabs.Panel>
          </Tabs>
        </div>
      </Box>
    </>
  );
};
export default OrganizationSetting;
