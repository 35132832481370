import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const Permissionlists = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-roles`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};

const Addpermission = async (data: any) => {
  let body = JSON.stringify(data);
  let response = await fetch(
    `${API_URL}/role-permission`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};
const Deletepermission = async (params: any) => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .delete(`${API_URL}/role-permission/${params.uuid}`, config)
    .then((response) => {
      console.log(response.data);

      return response.data;
    });
};

const Updatepermission = async (data: any) => {
  console.log("dataaaaa", data);

  let body = JSON.stringify(data);
  let response = await fetch(
    `${API_URL}/role-permission`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

const Permissionservices = {
  Addpermission,
  Permissionlists,
  Deletepermission,
  Updatepermission,
};
export default Permissionservices;
