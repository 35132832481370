// import axios from "axios";
import { API_BASE_URL } from "../common/config";
const API_URL = API_BASE_URL;

const Organsizationlists = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
    body: JSON.stringify({
      filter: {
        status: true,
      },
      "order by": "id asc",
    }),
  };
  return await fetch(`${API_URL}/list-organization`, config)
    .then((res) => res.json())
    .then((res) => {
      console.log(res.data);
      return res.data;
    });
};
// const Add_Organsization = async ({
//   organization_name,
//   logo,
//   display_name,
//   billing_address,
//   state,
//   country,
//   domain_name,
//   email_address,
//   date_of_established,
//   business_type_id,
//   proprietor_name,
// }: any) => {
//   let token: any = localStorage.getItem("token");
//   const config = {
//     method: "POST",
//     headers: {
//       "Content-type": "application/json",
//       Authorization: JSON.parse(token),
//     },
//     body: JSON.stringify({
//       organization_name: organization_name,
//       logo: logo,
//       display_name: display_name,
//       domain_name: domain_name,
//       email_address: email_address,
//       date_of_established: date_of_established,
//       business_type_id: business_type_id,
//       proprietor_name: proprietor_name,
//       billing_address: billing_address,
//       state: state,
//       country: country,
//     }),
//   };
//   return await fetch(`${API_URL}/organization`, config)
//     .then((res) => res.json())
//     .then((res) => {
//       return res;
//     });
// };

// const Delete_Organsization = async (params: any) => {
//   console.log(params.uuid);
//   let token: any = localStorage.getItem("token");
//   const config = {
//     headers: {
//       "Content-type": "application/json",
//       Authorization: JSON.parse(token),
//     },
//     body: JSON.stringify({
//       name: params.domain_name,
//     }),
//   };
//   return await axios
//     .delete(`${API_URL}/organization/${params.uuid}`, config)
//     .then((response) => {
//       console.log(response.data);

//       return response.data;
//     });
// };

// const Update_Organsization = async (id: any, input: any) => {
//   let token: any = localStorage.getItem("token");
//   const config = {
//     method: "PUT",
//     headers: {
//       "Content-type": "application/json",
//       Authorization: JSON.parse(token),
//     },
//     body: JSON.stringify(input),
//   };
//   return await fetch(`${API_URL}/organization/${id}`, config)
//     .then((res) => res.json())
//     .then((res) => {
//       return res;
//     });
// };

// const Get_Organsization = async () => {
//   let token: any = localStorage.getItem("token");
//   const config = {
//     headers: {
//       "Content-type": "application/json",
//       Authorization: JSON.parse(token),
//     },
//   };
//   return await axios
//     .get(
//       `${API_URL}/organization/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
//       config
//     )
//     .then((response) => {
//       console.log(response.data.data.roles);

//       return response.data.data.roles;
//     });
// };

const OrgansizationServices = {
  Organsizationlists,
  // Add_Organsization,
  // Delete_Organsization,
  // Update_Organsization,
  // Get_Organsization,
};
export default OrgansizationServices;
