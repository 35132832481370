import React from "react";
import Input from "../../components/InputBox/Input";
import Table from "../../components/Tables/Table";
import TotalCard from "../../components/TotalCard/TotalCard";

import { COLORS } from "../../custom";

import "./Authorizer.css";

const Authorizer = () => {
  return (
    <div className="authorizer-main">
      <Input />
      <h2 className="title">Authorizer</h2>
      <div
        className="detail-box"
        style={{ background: COLORS.backgroundContent }}
      >
        <div className="total-card">
          <TotalCard
            title="Total Purchase"
            cardDigit={158478.24}
            icon1_Color="#066004"
            icon1_Name="Admins"
          />
          <TotalCard
            title="Total Revenue Generated"
            cardDigit={158478.24}
            icon1_Color="#D11317"
            icon1_Name="Admins"
          />
        </div>
        <Table />
      </div>
    </div>
  );
};

export default Authorizer;
