import {
  Avatar,
  Box,
  Button,
  Card,
  createStyles,
  Grid,
  Group,
  Image,
  Loader,
  LoadingOverlay,
  Menu,
  Modal,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import {
  Dropzone,
  DropzoneProps,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getOrganizationProfile,
  updateOrganizationalProfile,
} from "../../services/OrganizationProfileService";
import { userProfileImageUpload } from "../../services/UserProfileService";

// const calendar = require("../../Assets/ProfileIcons/calendarSvg.svg")
interface User {
  image: string;
  organization_name: string;
  display_name: string;
  domain_name: string;
  email_address: string;
  date_of_established: string;
  business_type: string;
  proprietor_name: string;
  billing_address: string;
  state: string;
  country: string;
  uuid: string;
  logo: string;
}

const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    color: theme.white,
    "&[data-hovered]": {
      backgroundColor:
        theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      color: theme.white,
    },
  },
}));

export default function OrganizationProfile(props: Partial<DropzoneProps>) {
  const { classes } = useStyles();
  const [datas, setDatas] = useState<any>([]);
  const [opened, setOpened] = useState(false);
  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState<User>({
    organization_name: "",
    display_name: "",
    domain_name: "",
    email_address: "",
    date_of_established: "",
    business_type: "",
    proprietor_name: "",
    billing_address: "",
    state: "",
    country: "",
    image: "",
    uuid: "",
    logo: "",
  });
  const [updateData, setUpdateData] = useState<any>({});
  const [password, setPassword] = useState({ password: "", newPassword: "" });
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageView, setImageView] = useState(false);

  const openRef = useRef<() => void>(null);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [visible, { toggle }] = useDisclosure(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userdetails = useSelector((s: any) => s.user.value);

  // LOCAL STORAGE HANDLE
  let data = localStorage.getItem("user");
  let organizationIdData: any;
  if (data) {
    organizationIdData = JSON.parse(data);
    organizationIdData = organizationIdData?.user_details?.organization_id;
  }

  console.log("organizationIdData", organizationIdData);

  // GET API
  const userProfileDetails = async () => {
    setLoading(true);
    await getOrganizationProfile(organizationIdData).then((res) => {
      console.log("Organization Profile Details", res?.data?.organization[0]);
      // localStorage.setItem("user", JSON.stringify(res.data.users[0]));
      // dispatch(logins(res.data.users[0]));
      if (res?.data?.organization[0]) {
        let data = res?.data?.organization[0];
        let prevData = { ...userData };
        prevData.organization_name = data?.organization_name;
        prevData.display_name = data?.display_name;
        prevData.domain_name = data?.domain_name;
        prevData.email_address = data?.email_address;
        prevData.date_of_established = data?.date_of_established;
        prevData.business_type = data?.business_type?.name;
        console.log("btt", data?.business_type?.name);

        prevData.proprietor_name = data?.proprietor_name;
        prevData.billing_address = data?.billing_address;
        prevData.state = data?.state;
        prevData.country = data?.country;
        prevData.uuid = data?.uuid;
        prevData.logo = data?.logo;
        setUserData(prevData);
        // localStorage.setItem("user", JSON.stringify(res.data.users[0]));
        // dispatch(logins(res.data.users[0]))
      }
      // setDatas(res.data.users[0])
      // setDatas(res.data.users[0])
    });
    setLoading(false);
  };
  console.log("update", updateData);

  //USER DETAIL UPDATE API

  const updateProfileDetails = async (data: any) => {
    setLoading(true);

    await updateOrganizationalProfile(userData?.uuid, data)
      .then((res) => {
        console.log("res Update", res);
        setUpdateData({});
        // localStorage.setItem("user", JSON.stringify(res.data.users));
        // dispatch(logins(res.data.user));
      })
      .catch((err) => {
        console.log("err", err);
      });
    userProfileDetails();
    setLoading(false);
  };

  // PROFILE IMAGE UPLOAD

  const imageUpload = async () => {
    setImageLoading(true);
    let formData: any = new FormData();

    formData.append("file", files[0]);

    await userProfileImageUpload(formData)
      .then((res) => {
        console.log("image URL", res?.data?.imageURL[0]?.Location);
        let prevData = {
          logo: res?.data?.imageURL[0]?.Location,
        };
        updateOrganizationalProfile(userData?.uuid, prevData)
          .then((res) => {
            console.log("res Update", res);
            setUpdateData({});
            // localStorage.setItem("user", JSON.stringify(res.data.users));
            // dispatch(logins(res.data.user));
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((err) => {
        console.log("err", err);
      });

    setTimeout(async () => {
      setOpened(false);
      setFiles([]);
      await userProfileDetails();
      setImageLoading(false);
    }, 1000);
    // setUserData({ first_name: '', last_name: '', email: '', phone: '', image: '', department_name: '', designation_name: '' })
  };

  // IMAGE PREVIEW

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  });

  useEffect(() => {
    userProfileDetails();
  }, []);

  return (
    <>
      {!loading ? (
        <div
          style={{ padding: "3%" }}
          // style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1%', marginBottom: '1%' }}
          // style={{ width: '70%', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', }}
        >
          <Grid>
            <Grid.Col span={2}>
              {/* <div > */}
              <Modal
                opened={opened}
                onClose={() => {
                  setFiles([]);
                  setOpened(false);
                }}
                title="Profile Image"
                radius="md"
                transition="scale"
                transitionDuration={300}
                transitionTimingFunction="ease"
                centered
              >
                <LoadingOverlay
                  visible={imageLoading}
                  overlayBlur={1}
                  loaderProps={{
                    size: "md",
                    variant: "oval",
                    color: "#25204f",
                  }}
                  overlayOpacity={0.2}
                />

                <Dropzone
                  accept={IMAGE_MIME_TYPE}
                  onDrop={setFiles}
                  sx={{ width: "100%", border: "0px" }}
                >
                  {/* <Text align="center">Drop images here/</Text> */}
                  <Group position="center">
                    {files.length === 0 && <Button>Select Image</Button>}
                  </Group>
                  <SimpleGrid
                  // cols={4}
                  // breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                  // mt={previews.length > 0 ? 'xl' : 0}
                  >
                    {previews}
                  </SimpleGrid>
                </Dropzone>
                {files.length !== 0 && (
                  <Button
                    onClick={() => {
                      imageUpload();
                    }}
                  >
                    Upload
                  </Button>
                )}
              </Modal>
              <Modal
                opened={imageView}
                onClose={() => {
                  setImageView(false);
                }}
                // className="mantine - vb22ct"
                radius="md"
                transition="scale"
                transitionDuration={300}
                transitionTimingFunction="ease"
                centered
                withCloseButton={false}
              >
                <Image src={userData?.logo} />
              </Modal>

              {/* <div > */}
              <Modal
                opened={opened}
                onClose={() => {
                  setFiles([]);
                  setOpened(false);
                }}
                title="Select Profile Image"
                radius="md"
                transition="scale"
                transitionDuration={300}
                transitionTimingFunction="ease"
                centered
              >
                <LoadingOverlay
                  visible={imageLoading}
                  overlayBlur={1}
                  loaderProps={{
                    size: "md",
                    variant: "oval",
                    color: "#25204f",
                  }}
                  overlayOpacity={0.2}
                />

                <Dropzone
                  accept={IMAGE_MIME_TYPE}
                  onDrop={setFiles}
                  sx={{ width: "100%", border: "0px" }}
                >
                  {/* <Text align="center">Drop images here/</Text> */}
                  <Group position="center">
                    {files.length === 0 && <Button>Select Image</Button>}
                  </Group>
                  <SimpleGrid
                  // cols={4}
                  // breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                  // mt={previews.length > 0 ? 'xl' : 0}
                  >
                    {previews}
                  </SimpleGrid>
                </Dropzone>
                {files.length !== 0 && (
                  <Group position="center">
                    <Button
                      onClick={() => {
                        imageUpload();
                      }}
                    >
                      Upload
                    </Button>
                  </Group>
                )}
              </Modal>
              <Modal
                opened={imageView}
                onClose={() => {
                  setImageView(false);
                }}
                // className="mantine - vb22ct"
                title={<h4>Profile Image</h4>}
                radius="md"
                transition="scale"
                transitionDuration={300}
                transitionTimingFunction="ease"
                centered
                // withCloseButton={false}
              >
                <Image src={userData?.logo} />
                <Group position="right" mt="md">
                  <Button
                    style={{ backgroundColor: "#25204f" }}
                    onClick={() => {
                      setImageView(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "#25204f" }}
                    onClick={() => {
                      setImageView(false);
                      setOpened(true);
                    }}
                  >
                    Edit
                  </Button>
                </Group>
              </Modal>

              <Card radius="lg" mt="md" p="xl">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Avatar
                    style={{ cursor: "pointer" }}
                    mb="xl"
                    size={100}
                    src={userData?.logo}
                    radius="xl"
                    alt="it's me"
                    onClick={() => {
                      console.log("Logged");
                      setImageView(true);
                    }}
                  />
                  {/* </Menu.Target>
                                        <Menu.Dropdown
                                            sx={{
                                                padding: '1%'
                                            }}
                                        >
                                            <Menu.Item onClick={() => { setOpened(true) }}>Edit</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu> */}

                  <Text mt="xl" mb="sm" weight={700}>
                    {userData?.organization_name}
                  </Text>
                  {/* <Text mb="md" weight={500}>{userData?.department_name} / {userData?.designation_name}</Text> */}
                </div>
                {/* </Group> */}
              </Card>
              {/* </div> */}
            </Grid.Col>
            <Grid.Col span={10}>
              <div>
                <Card my="md" radius="lg">
                  <Text weight={500}>Organization Info</Text>
                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      placeholder="Display Name"
                      readOnly
                      label="Display Name"
                      radius="md"
                      value={userData?.display_name}
                      onChange={(event) => {
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.display_name = event.currentTarget.value;
                        changeData.display_name = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    <TextInput
                      placeholder="Domain Name"
                      label="Domain Name"
                      readOnly
                      radius="md"
                      value={userData?.domain_name}
                      onChange={(event) => {
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.domain_name = event.currentTarget.value;
                        changeData.domain_name = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                  </Group>
                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      label="Email"
                      placeholder="example@gmail.com"
                      radius="md"
                      readOnly
                      type="email"
                      value={userData?.email_address}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.email_address = event.currentTarget.value;
                        changeData.email_address = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    {/* <TextInput label="Established Date" placeholder="Established Date" radius="md"
                                            value={userData?.date_of_established}
                                            onChange={(event) => {
                                                console.log('dataaaa', event.currentTarget.value);
                                                let prevData = { ...userData }
                                                let changeData = { ...updateData }
                                                prevData.date_of_established = event.currentTarget.value
                                                changeData.date_of_established = event.currentTarget.value
                                                setUserData(prevData)
                                                setUpdateData(changeData)
                                            }}
                                            readOnly
                                        /> */}

                    <DatePicker
                      placeholder="Established Date"
                      label="Established Date"
                      inputFormat="DD/MM/YYYY"
                      labelFormat="MM/YYYY"
                      defaultValue={new Date(userData?.date_of_established)}
                      radius="md"
                      disabled
                    />
                  </Group>

                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      label="Bussines Type"
                      placeholder="Business Type"
                      readOnly
                      radius="md"
                      value={userData?.business_type}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.business_type = event.currentTarget.value;
                        changeData.business_type = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    <TextInput
                      label="Proprietor Name"
                      placeholder="Proprietor Name"
                      readOnly
                      radius="md"
                      value={userData?.proprietor_name}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.proprietor_name = event.currentTarget.value;
                        changeData.proprietor_name = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                  </Group>
                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      label="Address"
                      placeholder="Address"
                      readOnly
                      radius="md"
                      value={userData?.billing_address}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.billing_address = event.currentTarget.value;
                        changeData.billing_address = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    <TextInput
                      label="State"
                      placeholder="State"
                      readOnly
                      radius="md"
                      value={userData?.state}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.state = event.currentTarget.value;
                        changeData.state = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                  </Group>
                  <Group spacing="md" mt="md" grow>
                    <TextInput
                      label="Country"
                      placeholder="Country"
                      readOnly
                      radius="md"
                      value={userData?.country}
                      onChange={(event) => {
                        console.log("dataaaa", event.currentTarget.value);
                        let prevData = { ...userData };
                        let changeData = { ...updateData };
                        prevData.country = event.currentTarget.value;
                        changeData.country = event.currentTarget.value;
                        setUserData(prevData);
                        setUpdateData(changeData);
                      }}
                    />
                    <div></div>
                    {/* <Button mt="lg" onClick={() => {
                                            updateProfileDetails(updateData)
                                        }}>Update</Button> */}
                  </Group>
                  {/* <Group spacing="md" mt="md" grow>
                        <Select
                            label="I'm"
                            placeholder="Pick one"
                            searchable
                            clearable
                            nothingFound="No options"
                            data={['Male', 'Female', 'Not to Prefer']}
                            dropdownPosition="bottom"
                            radius="md"
                            transition="pop-top-left"
                            transitionDuration={80}
                            transitionTimingFunction="ease"
                        />
                        <DatePicker
                            placeholder="Choose Date"
                            label="Birth Date"
                            inputFormat="MM/DD/YYYY"
                            labelFormat="MM/YYYY"
                            defaultValue={new Date()}
                            radius="md"
                        />
                    </Group>
                    <Group spacing="md" mt="md" grow>
                        <TextInput label="Email" placeholder="example@gmail.com" radius="md" type="email"
                            value={userData?.email}
                            onChange={(event) => {
                                console.log('dataaaa', event.currentTarget.value);
                                let prevData = { ...userData }
                                prevData.email = event.currentTarget.value
                                setUserData(prevData)
                            }}
                        />
                        <TextInput label="Confirmation Email" placeholder="example@gmail.com" radius="md" type="email"
                            // value={userData?.confirmationEmail}
                            onChange={(event) => {
                                console.log('dataaaa', event.currentTarget.value);
                                let prevData = { ...userData }
                                // prevData.confirmationEmail = event.currentTarget.value
                                // setUserData(prevData)
                            }}
                        />
                    </Group>
                    <Group spacing="md" mt="md" grow>
                        <TextInput label="Your Location" placeholder="India" radius="md" />
                        <TextInput label="Phone Number" placeholder="+91 " radius="md" type="number"
                            value={userData?.phone}
                            onChange={(event) => {
                                console.log('dataaaa', event.target.value);
                                let prevData = { ...userData }
                                prevData.phone = event.currentTarget.value
                                setUserData(prevData)
                            }}
                        />
                    </Group>
                    <Group spacing="md" my="md" grow>
                        <Select
                            label="Language"
                            placeholder="Pick one"
                            searchable
                            clearable
                            nothingFound="No options"
                            data={['English', 'Tamil', 'French', 'Spanish']}
                            dropdownPosition="top"
                            radius="md"
                        />
                        <MultiSelect
                            data={['React', 'Angular', 'Svelte', 'Vue']}
                            label="Skills"
                            placeholder="Choose"
                            searchable
                            clearable
                            dropdownPosition="top"
                            radius="md"
                            transition="pop-top-left"
                            transitionDuration={80}
                            transitionTimingFunction="ease"
                        />
                    </Group> */}
                  {/* <Flex justify="flex-end">
                                        <Button mt="sm" style={{ width: '10%' }} onClick={() => {
                                            updateProfileDetails(updateData)
                                        }}>Update</Button>
                                    </Flex> */}
                </Card>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      ) : (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "15%" }}
        >
          <Loader />
        </Box>
      )}
    </>
  );
}
