export const COLORS = {
  color: "#484848",
  backgroundContent: " #FFFFFF",
  backgroundcard:"#25204f",
  count: "#423f3f",
  red: "#fff",
  backgroundcolor: "#f3f1f1",
  buttonColor: "#D11317",
  userlistbroder: "#00A0F7",
  title: "#D11317",
  btnText: "#FFFFFF",
  ButtonColor: "#1f195e",
};

export const Theme = {
  primary: "#25204f",
  secondary: "#43464A",
  tertiary: "#43464A",
  background: "#F1F2F7",
};
export const TextStyle = {
  fontFamily: "Open Sans",
};
