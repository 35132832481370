import React, { useState, useEffect } from "react";
import {
  Card,
  Text,
  Button,
  Group,
  Table,
  Avatar,
  Checkbox,
  Box,
  Select,
  Pagination,
  Loader,
  Autocomplete,
} from "@mantine/core";
import "./CertificateType.css";
import { showNotification } from "@mantine/notifications";
import DocumentTypesServices from "../../../services/DocoumentTypeService";
import OrgansizationServices from "../../../services/OrgansizServices";
import DocoumentTypeModal from "../../AllPopUPs/DocoumentTypeModal";
import DeleteModal from "../../AllPopUPs/DeleteModal";
import NoData from "../../LottieFiles/NoData";

export const CertificateType = () => {
  const rows = [
    { id: "1", Levels: "level1", number: "12" },
    { id: "1", Levels: "level2", number: "12" },
    { id: "1", Levels: "level3", number: "12" },
    { id: "1", Levels: "level4", number: "12" },
    { id: "1", Levels: "level5", number: "12" },
  ];

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [docTotal, setDocTotal]: any = useState();

  const [DocoumentModal, setDocoumentModal]: any = useState(false);
  const [organsizelists, setOrgansizelist]: any = useState("");
  const [documentlist, setDocumentlist]: any = useState([]);
  const [editModal, setEditModal]: any = useState("");
  const [edit_doc, setEdit_doc]: any = useState("");
  const [name, setName]: any = useState("");
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  console.log(documentlist);

  const setcurrentPage = (value: any) => {
    setPage(value);
    setDocumentlist([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setDocumentlist([]);
  };

  const getdocumentlist = async () => {
    setLoading(true);
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    let data1 = {
      limit: pageSize,
      where: {
        name: {
          contains: name,
          mode: "insensitive",
        },
      },
    };
    try {
      let res = await DocumentTypesServices.DocoumentlistType(
        name ? data1 : data
      );
      setDocumentlist(res.data.documentType);
      setDocTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getorgansizelists = async () => {
    try {
      await OrgansizationServices.Organsizationlists().then((res) => {
        if (res.status === true) {
          setOrgansizelist(res.organization);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletedep = async (param: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      let res = await DocumentTypesServices.DeleteDocoument(param);
      if (res.staus === true) {
        getdocumentlist();
        setLoading(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdate = async (params: any) => {
    setEditModal(true);
    setEdit_doc(params);
  };
  useEffect(() => {
    getorgansizelists();
  }, []);
  useEffect(() => {
    getdocumentlist();
  }, [page, pageSize, name]);
  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {DocoumentModal && (
        <DocoumentTypeModal
          setDocumentModal={setDocoumentModal}
          get_document_list={getdocumentlist}
          organsizelist={organsizelists}
          setEditModal={setEditModal}
          edit_document={edit_doc}
          editModal={editModal}
        />
      )}
      <div>
        <Card mt="sm" radius="lg" shadow="lg">
          <Card.Section>
            <Group position="apart">
              <Box p="md">
                <Autocomplete
                  label="Filter by Name"
                  placeholder="Select DocumentTypes"
                  data={
                    Array.isArray(documentlist)
                      ? documentlist.map((provider) => `${provider.name}`)
                      : []
                  }
                  onChange={(value) => setName(value)}
                  maxDropdownHeight={400}
                  nothingFound="Nobody here"
                />
              </Box>
              <Box p="md">
                <Button
                  size="sm"
                  onClick={() => {
                    setDocoumentModal(true);
                    setEditModal(false);
                  }}
                  style={{ background: "#25204f" }}
                  variant="filled"
                >
                  +Add
                </Button>
              </Box>
            </Group>
            {loading === true ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "50px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            ) : documentlist && documentlist.length > 0 ? (
              <div>
                <Table verticalSpacing="lg" highlightOnHover>
                  <thead style={{ border: "none" }}>
                    <tr>
                      <th>
                        <Checkbox size="sm" />
                      </th>
                      <th>
                        <div>Name</div>
                      </th>
                      <th>
                        <div>Status</div>
                      </th>
                      <th>
                        <div>Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {documentlist.map((document: any, index: number) => (
                      <tr
                        key={document?.uuid}
                        className={
                          document?.status === false ? "in-active-list" : ""
                        }
                      >
                        <td>
                          <Checkbox size="sm" />
                        </td>
                        <td>{document?.name}</td>
                        <td>
                          {document?.status === true ? "Active" : "In-Active"}{" "}
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              handleUpdate(document);
                              setDocoumentModal(true);
                              setEditModal(true);
                            }}
                            variant="default"
                            radius="md"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Want to Delete?",
                                onConfirm: () => {
                                  handleDeletedep(document);
                                },
                              });
                            }}
                            variant="default"
                            radius="md"
                            ml="sm"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Group position="apart" mt="md" p="lg">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box mr="md">Rows per page</Box>
                    <Select
                      onChange={(e) => setrowsperpage(e)}
                      style={{ width: "4rem" }}
                      value={String(pageSize)}
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                    />
                  </Box>
                  <Pagination
                    styles={(theme) => ({
                      item: {
                        "&[data-active]": {
                          backgroundColor: "#25204f",
                        },
                      },
                    })}
                    total={Math.ceil(docTotal / pageSize)}
                    boundaries={5}
                    page={page}
                    initialPage={1}
                    onChange={(e) => setcurrentPage(e)}
                  />
                </Group>
              </div>
            ) : (
              <NoData />
            )}
          </Card.Section>
        </Card>
      </div>
    </>
  );
};
