import Lottie from "lottie-react";
import gettingCertificate from "../../Assets/LottieFiles/gettingCertificate.json";

export default function GettingCertificate() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: gettingCertificate,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className="controlled"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Lottie
        //  options={defaultOptions}
        style={{ height: "500px", width: "500px" }}
        animationData={gettingCertificate}
        loop={true}
        autoplay={true}
      />
    </div>
  );
}
