import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const Notificationlists = async () => {
  let startDate = new Date(new Date().setHours(0, 0, 0, 0));
  let endDate = new Date(new Date().setHours(23, 59, 59, 999));
  let query = JSON.stringify({
    //   where:{
    //         created_at:{
    //             gte:startDate,
    //             lte:endDate
    //         }
    //     },
    include: {
      notification_templates: true,
      certificate_request_notifications_certificate_requestTocertificate_request:
        {
          include: {
            users_certificate_request_requested_byTousers: {
              include: {
                user_roles: {
                  include: {
                    roles: true,
                  },
                },
              },
            },
          },
        },
    },
  });

  let res = await fetch(
    `${API_URL}/list-notification`,
    CommonMethod.PostMethod(query)
  );

  return res.json();
};

// const AddNotification = async (moduledname: any, checked: boolean) => {
//   let body = JSON.stringify({
//     module_name: moduledname,
//     status: checked,
//   });
//   let response = await fetch(
//     `${API_URL}/modules`,
//     CommonMethod.PostMethod(body)
//   );

//   return response.json();
// };
// const UpdateNotification = async (edited: any, params: any, checked: boolean) => {
//   // console.log(edited);

//   let body = JSON.stringify({
//     module_name: edited,
//     status: checked,
//   });
//   let response = await fetch(
//     `${API_URL}/modules/${params.uuid}`,
//     CommonMethod.PutMethod(body)
//   );

//   return response.json();
// };
// const DeleteUpdateNotification = async (params: any) => {
//   let response = await fetch(
//     `${API_URL}/modules/${params.uuid}`,
//     CommonMethod.DeleteMethod()
//   );

//   return response.json();
// };
const Notificationservices = {
  Notificationlists,
  //   AddNotification,
  //   UpdateNotification,
  //   DeleteUpdateNotification,
};
export default Notificationservices;
