import { Card, Grid } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CertificateSetting } from "../../components/certificatesettings/CertificateSetting";
import OrganizationSetting from "../OrganizationSettings/Organization";
import UserRollSetting from "../UserRollSetting/UserRollSetting";
import "./Setting.css";

// import Sidebar from "../../layout/navbar";
import { useSelector } from "react-redux";
import { Userpage } from "../Users/user";
import Settingsbar from "./settingsNav";
// import ConfirmDialog from "../../components/Controls/ConfirmDialog";
const Settingcards = () => {
  const userdetails = useSelector((s: any) => s.user.value);
  const navigate = useNavigate();
  const [path, setPath] = useState("Organization");

  return (
    <>
      <Grid mt="lg">
        <Grid.Col span={2}>
          <Card radius="lg" style={{ height: "25rem" }}>
            <Settingsbar setpath={setPath} />
          </Card>
        </Grid.Col>
        <Grid.Col
          span={10}
          sx={{
            height: window.innerHeight - 150,
            overflowY: "scroll",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {path === "Organization" && <OrganizationSetting />}
          {path === "Role" && <UserRollSetting />}
          {path === "Certificate" && <CertificateSetting />}
          {path === "Users" && <Userpage />}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Settingcards;
