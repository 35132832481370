import { Box, Tabs, Text } from "@mantine/core";
import { useState } from "react";
import { Theme } from "../../custom";
import RollSettingsModules from "./modules";

import RollSettingsPermissions from "./permission";
import RollSettingsRolls from "./roles";

const UserRollSetting = () => {
  const [active, setActive]: any = useState("modules");
  return (
    <>
      <Box m='sm'>
        <div>
          <Text
            className="header-text"
            sx={{
              fontFamily: "poppins",
              color: Theme.primary,
              fontSize: "22px",
            }}
          >
            Role Settings
          </Text>
        </div>
        <div>
          <Tabs
            defaultValue="modules"
            radius="md"
            onTabChange={(value) => setActive(value)}
          >
            <Tabs.List>
              <Tabs.Tab
                style={{ fontSize: "16px", width: "10rem" }}
                value="modules"
              >
                Modules
              </Tabs.Tab>
              <Tabs.Tab
                style={{ fontSize: "16px", width: "10rem" }}
                value="rolls"
              >
                Roles
              </Tabs.Tab>
              <Tabs.Tab
                style={{ fontSize: "16px", width: "10rem" }}
                value="permissions"
              >
                Permissions
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="modules" pt="xs">
              {active === "modules" && <RollSettingsModules />}
            </Tabs.Panel>
            <Tabs.Panel value="permissions" pt="xs">
              {active === "permissions" && <RollSettingsPermissions />}
            </Tabs.Panel>
            <Tabs.Panel value="rolls" pt="xs">
              {active === "rolls" && <RollSettingsRolls />}
            </Tabs.Panel>
          </Tabs>
        </div>
      </Box>
    </>
  );
};

export default UserRollSetting;
