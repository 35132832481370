import { CloseButton } from "@mantine/core";
import React, { useEffect, useState } from "react";
import "./ViewPop.css";

interface CardPropsType {
  viewDetails: any;
  setViewPop: any;
}
const ViewPopup: React.FC<CardPropsType> = (props): JSX.Element => {
  const { viewDetails, setViewPop } = props;
  const [popup, setPopup]: any = useState(true);

  useEffect(() => {
    setViewPop(popup);
  }, [popup]);

  const NewLine = (props: any) => {
    let text = props.text;
    let t = text.split("/n");
    return <p>{t}</p>;
  };

  return (
    <div className={"view-pop-main active"}>
      <div className="view-pop" style={{ background: "white", color: "black", width: '90%', height: '80vh', margin: '20px' }}>
        <CloseButton
          className="close1"
          title="Close popover"
          size="xl"
          onClick={() => setPopup(false)}
          iconSize={20}
        />
        <h3>{viewDetails.data.certificate.certificate_name}</h3>
        <div
        style={
          {
            overflowY: 'scroll',
            height: '70vh'
          }
        }
          dangerouslySetInnerHTML={{
            __html: viewDetails.data.certificate.document,
          }}
        />
      </div>
    </div>
  );
};

export default ViewPopup;
