import {
  Box,
  Button,
  CloseButton,
  Input,
  MultiSelect,
  PasswordInput,
  Select,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../custom";
import API_Services from "../../services/CertificateUsersService";
import "./AddUser.css";

interface CardPropsType {
  setOpenAdduser: any;
  getuserlist: any;
}
const Adduser: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setOpenAdduser, getuserlist } = props;
  const [designationsList, setdesignationsList]: any = useState([]);
  const [userRoleList, setUserRoleList]: any = useState([]);
  const [departmentList, setdepartmentList]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    getDropdownValue();
  }, []);

  const form = useForm({
    initialValues: {
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      designation_id: null,
      department_id: null,
      user_roles: [],
      date_of_joining: "",
      present_role: null,
      roles_id: "",
    },

    // functions will be used to validate values at corresponding key
    // validate: {
    //   username: (value) => (value.length < 2 ? "Username is required" : null),
    //   first_name: (value) =>
    //     value.length < 2 ? "First name is required" : null,
    //   last_name: (value) => (value.length < 2 ? "Last name is required" : null),
    //   email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    //   phone: (value) =>
    //     /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)
    //       ? null
    //       : "Invalid Phone Number",

    //   designation_id: (value) =>
    //     value.length < 1 ? "Select at least one designation" : null,
    //   department_id: (value) =>
    //     value.length < 1 ? "Select at least one department" : null,
    //   password: (value) =>
    //     /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value)
    //       ? null
    //       : "Minimum eight characters, at least one letter and one number",

    //   date_of_joining: (value) => (!value ? "Date is required" : null),
    //   roles_id: (value) => (value.length < 1 ? "Pick at least one role" : null),
    // },
  });

  const handleSubmit = async (e: any) => {
    let data = localStorage.getItem("user");
    let user;
    if (data) {
      user = JSON.parse(data);
    }
    console.log("submittttt");

    let rolID = e?.roles_id;
    let input: any = {
      username: e.username,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      phone: e.phone,
      password: e.password,
      present_role: rolID[0],
      user_details: {
        create: {
          designation_id: e.designation_id,
          department_id: e.department_id,
          date_of_joining: e.date_of_joining,
          organization_id: user?.user_details?.organization_id,
        },
      },
    };
    if (rolID) {
      input["user_roles"] = {
        create: rolID?.map((item: any, i: any) => {
          return {
            role_id: item,
          };
        }),
      };
    }
    setLoading(true);

    try {
      let userRes = await API_Services.registerUser(input)
        .then(async (respons: any) => {
          showNotification({
            color: "green",
            message: "User Register successfully",
          });
          getuserlist();
          setOpenAdduser(false);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          showNotification({
            color: "red",
            message: "Somthing went wrong",
          });
        });
    } catch (error: any) {
      setLoading(false);
      showNotification({
        color: "red",
        message: error?.message,
      });
    }
  };
  const getDropdownValue = async () => {
    try {
      await API_Services.fetchDesignationList().then((res) =>
        setdesignationsList(res.data.designations)
      );
      await API_Services.fetchUserRoleList().then((res) =>
        setUserRoleList(res.data.roles)
      );
      await API_Services.fetchDepartmentList().then((res) =>
        setdepartmentList(res.data.department)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="Edit-user-main"
      style={{ backgroundColor: COLORS.backgroundContent }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Open Sans",
            fontWeight: 600,
            fontSize: "22px",
            color: "#25204f",
            paddingLeft: "10px",
          }}
        >
          Add user
        </p>

        <CloseButton
          title="Close popover"
          onClick={() => setOpenAdduser(false)}
          size="xl"
          iconSize={20}
        />
      </Box>
      <form
        className="edit-user-form"
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
      >
        <TextInput
          label="User name"
          radius="sm"
          size="sm"
          className="input"
          name="name"
          placeholder="Name"
          {...form.getInputProps("name")}
          variant="filled"
        />

        <TextInput
          label="Email"
          radius="sm"
          size="sm"
          className="input"
          name="email"
          placeholder="Username@email.com"
          {...form.getInputProps("email")}
          variant="filled"
        />

        <TextInput
          label="First name"
          radius="sm"
          size="sm"
          className="input"
          name="first_name"
          placeholder="First name"
          {...form.getInputProps("first_name")}
          variant="filled"
        />

        <TextInput
          label="Last name"
          radius="sm"
          size="sm"
          className="input"
          name="first_name"
          placeholder="Last name"
          {...form.getInputProps("last_name")}
          variant="filled"
        />

        <TextInput
          type="number"
          label="Phone no"
          radius="sm"
          size="sm"
          className="input"
          name="phone"
          placeholder="phone"
          {...form.getInputProps("phone")}
          variant="filled"
        />

        <Input.Wrapper label="User role" className="input">
          <MultiSelect
            variant="filled"
            radius="sm"
            size="sm"
            placeholder="Select role"
            onChange={(e: any) => console.log(e, "event")}
            data={
              Array.isArray(userRoleList)
                ? userRoleList
                    .sort((a, b) => {
                      if (a.role_name.toLowerCase() < b.role_name.toLowerCase())
                        return -1;
                      if (a.role_name.toLowerCase() > b.role_name.toLowerCase())
                        return 1;
                      return 0;
                    })
                    .map((e: any) => {
                      return {
                        value: e.id,
                        label:
                          e.role_name.charAt(0).toUpperCase() +
                          e.role_name.slice(1),
                      };
                    })
                : []
            }
            {...form.getInputProps("roles_id")}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Designation type" className="input">
          <Select
            variant="filled"
            radius="sm"
            size="sm"
            placeholder="Select designation"
            data={
              Array.isArray(designationsList)
                ? designationsList
                    .sort((a, b) => {
                      if (
                        a.designation_name.toLowerCase() <
                        b.designation_name.toLowerCase()
                      )
                        return -1;
                      if (
                        a.designation_name.toLowerCase() >
                        b.designation_name.toLowerCase()
                      )
                        return 1;
                      return 0;
                    })
                    .map((e: any, i: any) => {
                      return {
                        value: e.id,
                        label:
                          e.designation_name.charAt(0).toUpperCase() +
                          e.designation_name.slice(1),
                      };
                    })
                : []
            }
            {...form.getInputProps("designation_id")}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Department Type" className="input">
          <Select
            variant="filled"
            radius="sm"
            size="sm"
            placeholder="Select department "
            data={
              Array.isArray(departmentList)
                ? departmentList
                    .sort((a, b) => {
                      if (
                        a.department_name.toLowerCase() <
                        b.department_name.toLowerCase()
                      )
                        return -1;
                      if (
                        a.department_name.toLowerCase() >
                        b.department_name.toLowerCase()
                      )
                        return 1;
                      return 0;
                    })
                    .map((e: any) => {
                      return {
                        value: e.id,
                        label:
                          e.department_name.charAt(0).toUpperCase() +
                          e.department_name.slice(1),
                      };
                    })
                : []
            }
            {...form.getInputProps("department_id")}
          />
        </Input.Wrapper>

        <DatePicker
          label="Date of joining"
          variant="filled"
          placeholder="Pick date"
          inputFormat="DD/MM/YYYY"
          labelFormat="DD/MM/YYYY"
          radius="sm"
          size="sm"
          className="input"
          {...form.getInputProps("date_of_joining")}
        />

        <PasswordInput
          variant="filled"
          className="input"
          radius="sm"
          size="sm"
          placeholder="Password"
          label="Password"
          name="password"
          {...form.getInputProps("password")}
        />
        <div className="Add-user-btn" >
          <Button type="submit" color="red"  size="md" loading={loading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Adduser;
