import { CloseButton } from "@mantine/core";

import React, { useEffect, useState } from "react";
import { ScrollArea, Textarea } from "@mantine/core";
// import { TextStyle } from "../../custom";

import "./requestpop.css";
import Consent_Services from "../../services/ConsentServices";
import { showNotification } from "@mantine/notifications";

interface CardPropsType {
  setConsentPop: any;
  requestdata?: any;
  usersdata: any;
  getuserlist: any;
}
const ConsentReqPopup: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setConsentPop, requestdata, usersdata, getuserlist } = props;
  console.log("requestdata :", requestdata);
  const [popup, setPopup]: any = useState(true);
  const [consentBox, setBox]: any = useState(true);

  const [reason, setReason] = useState("");

  useEffect(() => {
    // getIssueFields();
    setConsentPop(popup);
  }, [popup]);

  const handleSubmit = async () => {
    try {
      let payload = {
        employee_id: usersdata,
        response: reason,
      };
      await Consent_Services.addConsentRequest(payload).then((res) => {
        console.log(res);
        setConsentPop(false);
        getuserlist();
        showNotification({
          color: "green",
          message: "consent request success",
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {consentBox && (
        <div className={popup ? "consent-pop-main active" : "consent-pop-main"}>
          <div className="consent-pop" style={{ background: "white" }}>
            <CloseButton
              className="close1"
              title="Close popover"
              size="xl"
              onClick={() => setPopup(false)}
              iconSize={20}
            />
            <h3>Consent Request</h3>
            <div>
              <table>
                <ScrollArea style={{ height: 200, width: 700 }}>
                  <tr>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Request Form</th>
                  </tr>
                  {requestdata.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.employee_id}</td>
                      <td>{item.email}</td>
                      <td>req_from</td>
                    </tr>
                  ))}
                </ScrollArea>
              </table>
            </div>
            <div className="textarea">
              <Textarea
                placeholder="Your comment"
                onChange={(e) => setReason(e.target.value)}
                withAsterisk
              />
            </div>
            <div>
              <button
                className="consent-cancel-btn"
                onClick={() => handleSubmit()}
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsentReqPopup;
