import { Box, Tabs, Text } from "@mantine/core";
import { useState } from "react";
import "./CertificateSetting.css";
import { CertificateType } from "./certifiicatetype/CertificateType";

import { Theme } from "../../custom";
import { ApprovalLevels } from "./ApprovalLevels/ApprovalLevels";
import { ApprovalTemplate } from "./ApprovalTemplate/ApprovalTemplate";
import { DocumentFields } from "./documentfileds/DocumentFields";
import { Documents } from "./DocumentTemplate/Document";

export const CertificateSetting = () => {
  console.log("certificatePage");

  const [active, setActive]: any = useState("types");
  return (
    <>
      <Box m="sm">
        <div>
          <Text
            className="header-text"
            sx={{
              fontFamily: "poppins",
              color: Theme.primary,
              fontSize: "22px",
            }}
          >
            Certificate Settings
          </Text>
        </div>
        <div>
          <Tabs defaultValue="types"  onTabChange={(value) => setActive(value)}>
            <Tabs.List>
              <Tabs.Tab style={{ fontSize: "16px" }} value="types">
                Document Types
              </Tabs.Tab>
         
              <Tabs.Tab style={{ fontSize: "16px", paddingLeft:"45px" }} value="fields">
                Document Fields
              </Tabs.Tab>
              <Tabs.Tab style={{ fontSize: "16px", paddingLeft:"45px"}} value="template">
                Document Template
              </Tabs.Tab>

              <Tabs.Tab style={{ fontSize: "16px" , paddingLeft:"45px" }} value="ApprovalTemplate">
                Approval Templates
              </Tabs.Tab>

              <Tabs.Tab
                style={{ fontSize: "16px" , paddingLeft:"45px" }}
                value="ApprovalTemplateLevels"
              >
                Approval Templates Levels
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="types" pt="xs">
              {active === "types" && <CertificateType />}
            </Tabs.Panel>
            <Tabs.Panel value="fields" pt="xs">
              {active === "fields" && <DocumentFields />}
            </Tabs.Panel>
            <Tabs.Panel value="template" pt="xs">
              {active === "template" && <Documents />}
            </Tabs.Panel>
            <Tabs.Panel value="ApprovalTemplate" pt="xs">
              {active === "ApprovalTemplate" && <ApprovalTemplate />}
            </Tabs.Panel>
            <Tabs.Panel value="ApprovalTemplateLevels" pt="xs">
              {active === "ApprovalTemplateLevels" && <ApprovalLevels />}
            </Tabs.Panel>
          </Tabs>
        </div>
      </Box>
    </>
  );
};
