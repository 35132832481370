import React, { useEffect, useState } from "react";


import { Button, Group, Modal, Select, Switch, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import DocumentFieldsServices from "../../services/DocumentFieldsService";
interface CardPropsType {
  setDocumentFieldsModal: any;
  get_documentField_list: any;
  setEditModal: any;
  edit_document_fileds: any;
  editModal: any;
  documentTypes: any;
}
const DocoumentFieldsModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const {
    setDocumentFieldsModal,
    get_documentField_list,
    edit_document_fileds,
    editModal,
    documentTypes,
  } = props;
  const [popup, setPopup] = useState(true);
  const [required, setRequired] = useState(false);

  const [value, setValue]: any = useState("");
  const [fieldType, setfieldType]: any = useState("");
  const [fieldLabel, setFieldLabel]: any = useState("");
  const [edited, setEdited]: any = useState("");
  const [loading, setLoading]: any = useState(false);
  //  const [documentType, setDocumentType]: any = useState([]);
  const [documentid, setDocumentId]: any = useState();
  const [status, setStatus]: any = useState(true);
  console.log(status, "s");

  useEffect(() => {
    if (editModal) {
      setRequired(edit_document_fileds.is_required);
      setStatus(edit_document_fileds.status);
      setValue(edit_document_fileds.field_name);
      setfieldType(edit_document_fileds.field_type);
      setFieldLabel(edit_document_fileds.field_label);
    }

    setDocumentFieldsModal(popup);
  }, [popup]);
  console.log(documentTypes);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (value) {
      try {
        let res = await DocumentFieldsServices.AddDocoumentField(
          documentid,
          value,
          fieldType,
          fieldLabel,
          required,
          status
        );
        if (res.status === true) {
          get_documentField_list();
          setDocumentFieldsModal();
          setValue("");
          setfieldType("");
          setFieldLabel("");
          setRequired(false);
          setStatus(true);
          setLoading(false);
          // setPopup(false);
          showNotification({
            color: "violet",
            message: res.message,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please Enter the Department name",
      });
    }
  };
  const Save = async () => {
    // alert("save");
    // e.preventDefault();
    setLoading(true);
    if (value) {
      try {
        let res = await DocumentFieldsServices.AddDocoumentField(
          documentid,
          value,
          fieldType,
          fieldLabel,
          required,
          status
        );
        if (res.status === true) {
          // get_documentField_list();
          // setDocumentFieldsModal();
          setValue("");
          setfieldType("");
          setFieldLabel("");
          setRequired(false);
          setStatus(true);
          setLoading(false);
          //  (false);

          showNotification({
            color: "green",
            message: res.message,
          });
        }
      } catch (error) {
        setLoading(false);
        // console.log(error);
        showNotification({
          color: "red",
          message: "error",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "red",
        message: "Please Enter the Department name",
      });
    }
  };

  const handleUpdate = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await DocumentFieldsServices.UpdateDocoumentFiled(
        value,
        fieldLabel,
        fieldType,
        edit_document_fileds,
        required
      );
      if (res.status === true) {
        get_documentField_list();
        setLoading(false);
        setPopup(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  return (
    <>
      {editModal === false && (
        <Modal
          size={500}
          opened={popup}
          onClose={() => {
            setPopup(false);
            setRequired(false);
          }}
          title="Add Document Field"
          centered
        >
          <form onSubmit={handleSubmit}>
            <div>
              <Select
                label="select Document Type"
                placeholder="Document Type"
                required
                withAsterisk
                data={
                  Array.isArray(documentTypes)
                    ? documentTypes.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.name,
                        };
                      })
                    : []
                }
                onChange={(value) => {
                  setDocumentId(value);
                }}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />

              <TextInput
                value={value}
                required
                withAsterisk
                size="md"
                mt="sm"
                onChange={(event) => setValue(event.currentTarget.value)}
                label="Field name"
              />
              <TextInput
                value={fieldType}
                required
                withAsterisk
                size="md"
                mt="sm"
                onChange={(event) => setfieldType(event.currentTarget.value)}
                label="Field type"
              />
              <TextInput
                value={fieldLabel}
                required
                withAsterisk
                mt="sm"
                size="md"
                onChange={(event) => setFieldLabel(event.currentTarget.value)}
                label="Field label"
              />

              <Switch
                onLabel="true"
                label="Required"
                checked={required}
                size="md"
                mt="sm"
                onChange={(event) => setRequired(event.currentTarget.checked)}
                offLabel="false"
              />
              <Switch
                label={status ? "Active" : "In-active"}
                radius="lg"
                onLabel="true"
                offLabel="false"
                size="md"
                mt="sm"
                checked={status}
                onChange={(event) => setStatus(event.currentTarget.checked)}
              />
            </div>

            <Group position="right">
              <Button
                style={{ background: "#25204f" }}
                loading={loading}
                onClick={() => Save()}
              >
                Save
              </Button>
              <Button
                style={{ background: "#25204f" }}
                loading={loading}
                type="submit"
              >
                Save & Close
              </Button>
            </Group>
          </form>
        </Modal>
      )}
      {editModal === true && (
        <>
          <Modal
            opened={popup}
            onClose={() => {
              setPopup(false);
              setRequired(false);
            }}
            title="Edit Document Field"
            centered
          >
            <form onSubmit={handleUpdate}>
              <div>
                <TextInput
                  value={value}
                  required
                  withAsterisk
                  size="md"
                  onChange={(event) => setValue(event.currentTarget.value)}
                  label="Field name"
                />
                <TextInput
                  value={fieldType}
                  required
                  withAsterisk
                  size="md"
                  onChange={(event) => setfieldType(event.currentTarget.value)}
                  label="Field type
              "
                />
                <TextInput
                  value={fieldLabel}
                  required
                  withAsterisk
                  size="md"
                  onChange={(event) => setFieldLabel(event.currentTarget.value)}
                  label="Field label"
                />

                <Switch
                  onLabel="true"
                  label="Required"
                  checked={required}
                  size="md"
                  mt="sm"
                  onChange={(event) => setRequired(event.currentTarget.checked)}
                  offLabel="false"
                />
                <Switch
                  label={required ? "Active" : "In-active"}
                  radius="lg"
                  onLabel="true"
                  offLabel="false"
                  size="md"
                  mt="sm"
                  checked={status}
                  onChange={(event) => setStatus(event.currentTarget.checked)}
                />
              </div>
              <Group position="right" mt="lg">
                <Button
                  type="submit"
                  style={{ background: "#25204f" }}
                  loading={loading}
                  // onClick={() => handleUpdate()}
                >
                  Save
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default DocoumentFieldsModal;
