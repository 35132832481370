import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const Rolelists = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-roles`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};

// list-roles
const AddRole = async (value: any, status: any) => {
  let body = JSON.stringify({
    status: status,
    role_name: value,
  });
  let response = await fetch(`${API_URL}/roles`, CommonMethod.PostMethod(body));

  return response.json();
};

const Deleterole = async (params: any) => {
  console.log(params);
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .delete(`${API_URL}/roles/${params.uuid}`, config)
    .then((response) => {
      console.log(response.data);

      return response.data;
    });
};

const Updaterole = async (id: any, edited: any, checked: boolean) => {
  let body = JSON.stringify({
    role_name: edited,
    status: checked,
  });
  let response = await fetch(
    `${API_URL}/roles/${id}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

const GetRole = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(`${API_URL}/roles/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`, config)
    .then((response) => {
      // console.log(response.data.data.roles);

      return response.data.data.roles;
    });
};

const RoleServices = {
  Rolelists,
  AddRole,
  Deleterole,
  Updaterole,
  GetRole,
};
export default RoleServices;
