import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/CommonMethod";
const API_URL = API_BASE_URL;

export const getUserProfile = async (uuid: any, present_role: any) => {
  const query = JSON.stringify({
    where: {
      uuid,
    },
    include: {
      user_details: {
        include: {
          department: true,
          designations: true,
        },
      },
      user_roles: {
        where: {
          role_id: present_role,
        },
        include: {
          roles: {
            include: {
              role_permissions: {
                include: {
                  modules: true,
                },
              },
            },
          },
        },
      },
    },
  });

  let respons = await fetch(
    `${API_URL}/list-users`,
    CommonMethod.PostMethod(query)
  );
  return await respons.json();
};
export const updateUserProfile = async (id: any, data: any) => {
  console.log("idddd", id, data);

  let body = JSON.stringify(data);
  console.log("body", body);

  let response = await fetch(
    `${API_URL}/users/${id}`,
    CommonMethod.PutMethod(body)
  );
  return await response.json();
};
export const userPasswordChange = async (data: any) => {

   let body = JSON.stringify({password:data.currentPassword,newPassword:data.confirmPassword});
   console.log("password", body);

  let response = await fetch(
    `${API_URL}/change`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};
export const userProfileImageUpload = async (data: any) => {
  console.log("idddd", data);

  // let body = JSON.stringify(data);
  // console.log('body', body);

  let response = await fetch(
    `${API_URL}/upload`,
    CommonMethod.ImagePostMethod(data)
  );
  return await response.json();
};
