import { Button, Card, Group, Text } from "@mantine/core";
import { IconUser, IconUsers } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./RoleCards.css";

interface CardPropsType {
  title: string;
  icon1_Name: string;
  icon1_Color?: string;
  text?: string;
  role: String;
}
const RoleCards: React.FC<CardPropsType> = (props): JSX.Element => {
  const userdetails = useSelector((s: any) => s.user.value);
  console.log(userdetails);

  const { title, icon1_Name, icon1_Color, text, role } = props;
  const [CurretRole, setRole]: any = useState();
  useEffect(() => {
    setRole(userdetails?.present_role);
  }, []);
  console.log("current", CurretRole, role);

  return (
    <Card
      shadow="sm"
      p="xl"
      radius="md"
      withBorder
      className={role == CurretRole ? "roll-card-box active" : "roll-card-box"}
    >
      <Card.Section pt="md" style={{ textAlign: "center" }}>
        {role == CurretRole ? (
          <IconUser width={40} height={40} />
        ) : (
          <IconUsers width={40} height={40} />
        )}
      </Card.Section>

      <Group position="center" mt="md" mb="xs">
        <Text weight={500} style={{ textAlign: "center" }}>
          {title[0]?.toUpperCase()}
          {title?.toLowerCase()?.slice(1)}
        </Text>
      </Group>

      <Button
        // variant="light"
        color="#FFFFFF"
        fullWidth
        bg={icon1_Color}
        mt="md"
        radius="md"
      >
        {role == CurretRole ? "Current" : "Switch"}
      </Button>
    </Card>
    // <div
    //   className={role == CurretRole ? "roll-card-box active" : "roll-card-box"}
    // >
    //   <Icon color={icon1_Color} size={39} icon={icon1_Name} />
    //   <span>{text}</span>
    //   <p style={{ textTransform: "capitalize" }}>{title}</p>
    // </div>
  );
};

export default RoleCards;
