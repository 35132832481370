import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  Group,
  Loader,
  Modal,
  Radio,
  Select,
  TextInput,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import ModuleServices from "../../services/ModuleServices";
import Permissionservices from "../../services/PermissionServices";
import RoleServices from "../../services/RoleSevices";

interface CardPropsType {
  setPermissionModal: any;
  get_permission_list: any;
  setEditModal: any;
  editper_data: any;
  edit_permission: any;
}

const PermissionModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setPermissionModal, edit_permission, get_permission_list } = props;
  const [popup, setPopup]: any = useState(true);
  const [rolelist, setRoleslist]: any = useState();
  const [loading, setLoading]: any = useState(false);
  const [perissionName, SetPermissionName]: any = useState();
  const [roleId, SetRoleId]: any = useState();
  const [modulesData, setModulesData] = useState<any>([]);
  const [modulesCheck, setModulesCheck] = useState(false);
  const [modulesCheckRead, setModulesCheckRead] = useState(false);
  const [modulesCheckWrite, setModulesCheckWrite] = useState(false);
  const [datas, setDatas] = useState<any>({
    permissionName: "",
    role: "",
    status: true,
    module: modulesData,
  });

  useEffect(() => {
    getRolelists();
    getModules();
    setPermissionModal(popup);
  }, [popup]);
  let dynamicData = modulesData;
  const selectAllModules = (event: any) => {
    const checkedValue = event.target.checked;
    const arr = dynamicData;
    let checkAllModules: any = [];
    arr.forEach((acc: any) =>
      checkAllModules.push({
        ...acc,
        can_read: checkedValue,
        can_write: checkedValue,
        permission_name: perissionName,
        role_id: roleId,
      })
    );
    setModulesCheck(checkedValue);
    setModulesCheckRead(false);
    setModulesCheckWrite(false);

    setDatas({ ...datas, module: checkAllModules });
    setModulesData(checkAllModules);
  };
  const selectAllRead = (event: any) => {
    const checkedValue = event.target.checked;
    const arr = dynamicData;
    let checkAllModules: any = [];
    arr.forEach((acc: any) =>
      checkAllModules.push({
        ...acc,
        can_read: checkedValue,
        permission_name: perissionName,
        role_id: roleId,
      })
    );
    setModulesCheckRead(checkedValue);
    setDatas({ ...datas, module: checkAllModules });
    setModulesData(checkAllModules);
  };
  const selectAllWrite = (event: any) => {
    const checkedValue = event.target.checked;
    const arr = dynamicData;
    let checkAllModules: any = [];
    arr.forEach((acc: any) =>
      checkAllModules.push({
        ...acc,
        can_write: checkedValue,
        can_read: checkedValue,
        permission_name: perissionName,
        role_id: roleId,
      })
    );
    setModulesCheckRead(checkedValue);
    setModulesCheckWrite(checkedValue);

    setDatas({ ...datas, module: checkAllModules });
    setModulesData(checkAllModules);
  };

  const selectModulesByRow = (
    id: any,
    moduleName: string,
    event: any,
    index: any
  ) => {
    const checkedValue = event.target.checked;
    let newData = { ...dynamicData };
    newData = dynamicData.map((d: any) => {
      const newModulesDataByRow = { ...d };
      if (newModulesDataByRow.module_id === id) {
        newModulesDataByRow.can_read = checkedValue;
        newModulesDataByRow.can_write = checkedValue;
        newModulesDataByRow.rowChecked = checkedValue;
        newModulesDataByRow.permission_name = perissionName;
        newModulesDataByRow.role_id = roleId;
      }
      return newModulesDataByRow;
    });
    console.log(newData, "170");

    setDatas({ ...datas, module: newData });
    setModulesData(newData);
  };

  const selectReadOnly = (event: any, index: any, id: any) => {
    const checkedValue = event.target.checked;
    let newData = { ...modulesData };
    const arr = dynamicData;
    arr.splice(index, 1, {
      ...arr[index],
      can_read: checkedValue,
      permission_name: perissionName,
      role_id: roleId,
    });

    setModulesData(arr);
    setDatas({ ...datas, module: arr });
  };
  const selectWriteOnly = (event: any, index: any) => {
    const checkedValue = event.target.checked;
    const arr = dynamicData;
    arr.splice(index, 1, {
      ...arr[index],
      can_write: checkedValue,
      can_read: checkedValue,
      permission_name: perissionName,
      role_id: roleId,
    });

    setModulesData(arr);
    setDatas({ ...datas, module: arr });
  };
  const getRolelists = async () => {
    let data = {
      select: {
        id: true,
        role_name: true,
      },
    };
    try {
      await RoleServices.Rolelists(data).then((res) => {
        if (res.status === true) {
          setRoleslist(res.data.roles);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    let temp = [];
    console.log(modulesData);
    for (let i = 0; i < modulesData.length; i++) {
      delete modulesData[i].rowChecked;
      delete modulesData[i].module_name;
      temp.push(modulesData[i]);
    }
    console.log(temp);

    setLoading(true);
    if (perissionName && roleId) {
      try {
        await Permissionservices.Addpermission(temp).then((res) => {
          setPermissionModal(false);
          setLoading(false);
          if (res.code === 400) {
            showNotification({
              color: "violet",
              message: "invalid data",
            });
          } else if (res.status === true) {
            get_permission_list();
            showNotification({
              color: "violet",
              message: res.message,
            });
          }
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please fill all the Fields",
      });
    }
  };
  const getModules = async () => {
    setLoading(true)
    try {
      let data = {
        select: {
          id: true,
          module_name: true,
        },
      };
      await ModuleServices.Modulelists(data).then((res) => {
        if (res.status === true) {
          const newArray = res.data.modules.map((d: any) => ({
            module_id: d.id,
            module_name: d.module_name,
            permission_name: perissionName,
            role_id: roleId,
            can_read: false,
            can_write: false,
            rowChecked: false,
          }));
          setModulesData(newArray);
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };
 
  return (
    <>
      <div
        className={popup ? "permission-pop-main active" : "permission-pop-main"}
      >
        {edit_permission === false && (
          <Modal
            size={600}
            opened={popup}
            onClose={() => {
              setPopup(false);
            }}
            title="Add permission"
            centered
          >
            <div>
              <Group>
                <TextInput
                  style={{ width: "260px" }}
                  size="sm"
                  value={datas.permissionNam}
                  onChange={(e) => SetPermissionName(e.target.value)}
                  label="Permission name"
                />
                <Select
                  label="Select Role"
                  style={{ width: "260px" }}
                  placeholder="Pick one"
                  data={
                    Array.isArray(rolelist)
                      ? rolelist.map((e: any, i: any) => {
                          return {
                            value: e.id,
                            label: e.role_name,
                          };
                        })
                      : []
                  }
                  onChange={(value) => {
                    SetRoleId(value);
                  }}
                />
              </Group>
              <Group position="apart" mt="sm">
                <div>
                  <h3>Modules</h3>
                  <Checkbox
                    label="Select All"
                    checked={modulesCheck}
                    onChange={(e) => selectAllModules(e)}
                    mt="sm"
                  />
                </div>
                <div>
                  <h3>Access</h3>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      mr="lg"
                      mt="sm"
                      label="ReadAll"
                      defaultChecked={modulesCheckRead}
                      checked={modulesCheckRead}
                      onChange={(event: any) => selectAllRead(event)}
                      disabled={modulesCheck}
                    />
                    <Checkbox
                      mt="sm"
                      onChange={(event: any) => selectAllWrite(event)}
                      defaultChecked={modulesCheckWrite}
                      checked={modulesCheckWrite}
                      disabled={modulesCheck}
                      label="WriteAll"
                    />
                  </div>
                </div>
              </Group>
              <Box
                sx={{
                  height: "400px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                {modulesData?.map((item: any, index: any) => (
                  <Group position="apart" mt="sm">
                    <div>
                      <Checkbox
                        label={item.module_name}
                        defaultChecked={item?.rowChecked}
                        checked={item?.rowChecked}
                        onChange={(event: any) =>
                          selectModulesByRow(
                            item.module_id,
                            item.module_name,
                            event,
                            index
                          )
                        }
                        disabled={modulesCheck}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        style={{ marginRight: "2.2rem" }}
                        defaultChecked={item?.can_read}
                        checked={item?.can_read}
                        onChange={(event: any) =>
                          selectReadOnly(event, index, item?.module_id)
                        }
                        label="Read"
                      />
                      <Checkbox
                        mr="md"
                        defaultChecked={item?.can_write}
                        checked={item?.can_write}
                        onChange={(event: any) => selectWriteOnly(event, index)}
                        label="Write"
                      />
                    </div>
                  </Group>
                ))}
              </Box>
              <Group position="right" mt="md">
                <Button
                  size="sm"
                  style={{ background: "#25204f" }}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </Group>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
export default PermissionModal;
