import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const ApprovalTemplateLevelslist = async (data: any) => {
  // let body = JSON.stringify({
  //   where: {
  //     certificate_approval_template: data
  //   },

  //   "include": {
  //     "designations": {
  //       "select": {
  //         "designation_name": true,
  //         "id": true
  //       }
  //     },
  //     "certificate_approval_stage": true,
  //     "user_details": {
  //       "select": {
  //         "users": true
  //       }

  //     },
  //     "certificate_approval_template_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template": true
  //   },
  //   skip: skipLimitData?.skip,
  //   limit: skipLimitData?.limit,
  // })
  let body = JSON.stringify(data);

  let response = await fetch(
    `${API_URL}/list-certificate-approval-template-stage`,
    CommonMethod.PostMethod(body)
  );

  return response.json();
};
const AddApprovalTemplateLevels = async (data: any) => {
  let body = JSON.stringify(data);
  let response = await fetch(
    `${API_URL}/certificate-approval-template-stage`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteApprovalTemplateLevels = async (params: any) => {
  let response = await fetch(
    `${API_URL}/certificate-approval-template-stage/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateApprovalTemplateLevels = async (data: any, id: any) => {
  let body = JSON.stringify(data);

  let response = await fetch(
    `${API_URL}/certificate-approval-template-stage/${id}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};
const GetApprovalTemplateLevels = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/certificate-approval-template-stage/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      console.log(response.data.data.roles);
      return response.data.data.roles;
    });
};

const ApprovalLevelService = {
  ApprovalTemplateLevelslist,
  AddApprovalTemplateLevels,
  DeleteApprovalTemplateLevels,
  UpdateApprovalTemplateLevels,
  GetApprovalTemplateLevels,
};
export default ApprovalLevelService;
