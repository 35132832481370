import React, { useEffect, useMemo, useState } from "react";
import { TextStyle } from "../../custom";

import { Autocomplete, Input, Loader, Modal, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../common/config";
import API_Services from "../../services/CertificateUsersService";
import ConfirmPop from "../confirmPopup/ConfirmPop";
import SuccessPop from "../successPopup/SuccessPop";
// import "./RequestPop.css";

interface CardPropsType {
  setProcessModal?: any;
  detail?: any;
  setConfirm: any;
  input?: any;
  setInput?: any;
  certificateProcess: any;
  getRequestList?: any;
}
const ProcessModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const {
    setProcessModal,
    detail,
    setConfirm,
    certificateProcess,
    getRequestList,
  } = props;

  const [input, setInput]: any = useState(detail.document_info[0]);
  const [documentTypeList, setDocumentTypeList]: any = useState([]);
  console.log(detail, "==============>");

  const [approvaltemp, setapprovalTemp]: any = useState([]);
  const [popup, setPopup]: any = useState(true);
  const [requestBox, setBox] = useState(true);
  const [confirmBox, setConfirmBox]: any = useState(false);
  // const [confirm, setConfirm]: any = useState(false);
  const [doc_Id, setDoc_Id]: any = useState();
  const [requestStatus, setRequestStatus]: any = useState();
  const [message, setMessage]: any = useState();
  const [requestFields, setRequestField]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const [userList, setUserList]: any = useState([]);
  // const [inputs, setInputs]: any = useState();
  const [userListChange, setUserListChange]: any = useState<string>();

  console.log(input, "==============>");
  // setInput(detail.document_info[0]);
  const userdetails = useSelector((s: any) => s.user.value);
  setProcessModal(popup);
  useEffect(() => {
    getDocumentfields();
    // if (confirm === true) {
    //   sendRequest();
    // }
  }, []);

  // useEffect(() => {
  //   listApprovaltemp();
  // }, []);

  useEffect(() => {
    sendRequest();
  }, []);

  useEffect(() => {
    if (requestStatus === "created") {
      getRequestList();
    }
  }, [requestStatus]);
  console.log(detail, "detail");
  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  let sendRequest = async () => {
    let token: any = localStorage.getItem("token");
    for (let i = 0; i < input.length; i++) {
      if (_.isEmpty(input[i].object.keys)) {
      }
    }

    const config = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `bearer ${JSON.parse(token)}`,
      },
      body: JSON.stringify({
        document_id: doc_Id.id,
        // approval_template_id: 1,
        document_info: [input],
      }),
    };
    try {
      await fetch(`${API_BASE_URL}/certificate-request`, config)
        .then((res) => res.json())
        .then((data) => {
          if (data.code == 200) {
            // setMessage(data.message);
            // setRequestStatus("created");
            showNotification({
              color: "violet",
              message: data.message,
            });
            getRequestList();
            setConfirm(false);
            // setRequestPop(false);
          } else if (data.code == 400) {
            setMessage(data.data.errors[0]);
            setRequestStatus("rejected");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  
  let getDocumentlist = async () => {
    setLoading(true);
    try {
      await API_Services.fetchDocumentList().then((res: any) => {
        setDocumentTypeList(res.data.document);
        console.log(res);
        
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  // let listApprovaltemp = async () => {
  //   try {
  //     await ApprovalTemplateService.ApprovalTemplatelist().then(
  //       (res: any) => {
  //         setapprovalTemp(res.data.certificate_approval_template);
  //         setLoading(false);
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  let getDocumentfields = async () => {
    console.log(detail);

    setLoading(true);
    try {
      let data = {
        where: {
          document_type_id: detail.document.id,
          status: true,
        },
        include: {
          document_type: true,
        },
      };
      API_Services.getDocumentFileds(data).then((res: any) => {
        setRequestField(res.data.document_fields);
        console.log(res.data);

        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserListByFilter = async (value: any) => {
    let data;
    if (value) {
      data = {
        limit: 10,
        where: {
          OR: [
            {
              first_name: {
                contains: value,
                mode: "insensitive",
              },
            },
            {
              last_name: {
                contains: value,
                mode: "insensitive",
              },
            },
          ],
        },
      };
    } else {
      data = { skip: 0, limit: 10 };
    }
    API_Services.getUserListByFilter(data)
      .then((res) => {
        if (res?.status) {
          setUserList(res?.data?.users);
        } else {
          console.log("err", "UserList By Filter Error...");
        }
      })
      .catch((err) => {
        console.log("eer", err);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (doc_Id) {
      setConfirmBox(true);
      setBox(false);
    }
  };

  useEffect(() => {
    getUserListByFilter("");
  }, [userListChange]);

  useMemo(async () => {
    getDocumentlist();
  }, []);

  return (
    <>
      {requestBox && (
        <>
          <Modal
            centered
            opened={popup}
            onClose={() => setPopup(false)}
            title="Certificate Process"
            size="auto"
          >
            <Select
              disabled
              // variant="filled"
              className="doc-btn"
              radius="md"
              size="md"
              value={detail.document.id}
              placeholder="Document Type"
              data={
                Array.isArray(documentTypeList)
                  ? documentTypeList
                      .sort((a, b) => {
                        if (
                          a.template_name.toLowerCase() <
                          b.template_name.toLowerCase()
                        )
                          return -1;
                        if (
                          a.template_name.toLowerCase() >
                          b.template_name.toLowerCase()
                        )
                          return 1;
                        return 0;
                      })
                      .map((e: any) => {
                        return { value: e.id, label: e.template_name };
                      })
                  : []
              }
            />
            
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            )}
            <form onSubmit={handleSubmit}>
              {/* {requestFields.length > 0 ? ( */}
              <div
                className="request-inputfield"
                style={{ fontFamily: TextStyle.fontFamily }}
              >
                {requestFields.map((e: any, id: any) => {
                  // const [test,setTest] = useState({
                  //     [ detail.document_info[0][e.field_label]]:detail.document_info[0][e.field_name]
                  // })
                  if (e.field_type === "date") {
                    return (
                      <DatePicker
                        placeholder="Pick date"
                        label={e.field_label}
                        inputFormat="DD/MM/YYYY"
                        labelFormat="DD/MM/YYYY"
                        radius="md"
                        size="md"
                        className="inputfields"
                        name={e.field_name}
                        onChange={(val) => {
                          // // @ts-ignore
                          // let event = new Date(val).toLocaleString().split(",");
                          // setTest({ ...test, [e.field_name]: event[0] });
                          // setInput(test)
                          console.log(test);
                        }}
                      />
                    );
                  } else {
                    return (
                      <Input.Wrapper
                        id={id}
                        label={e.field_label}
                        className="inputfields"
                        withAsterisk={e?.is_required}
                      >
                        <Input
                          type={e.field_type}
                          onChange={handleChange}
                          value={detail.document_info[0][e.field_name]}
                          name={e.field_name}
                          required={e?.is_required}
                          id={id}
                          radius="md"
                          size="md"
                        />
                      </Input.Wrapper>
                    );
                  }
                })}
              </div>
              {/* ) : (
                <></>
                // <div>No Data Found</div>
              )} */}

              <div style={{ marginLeft: "15px" }}>
                <button className="cancel-btn" onClick={() => setPopup(false)}>
                  Cancel
                </button>
                <button
                  className="request-btn"
                  type="submit"
                  // onClick={() => {
                  //   if (doc_Id) {
                  //     setConfirmBox(true);
                  //     setBox(false);
                  //   }
                  // }}
                  style={{ background: "#25204f" }}
                >
                  Process
                </button>
              </div>
            </form>
          </Modal>
          
        </>
      )}
      {confirmBox && (
        <ConfirmPop
          setConfirmBox={setConfirmBox}
          setBox={setBox}
          setConfirm={setConfirm}
          message={"Send Request ?"}
        />
      )}
    </>
  );
};

export default React.memo(ProcessModal);
