import { CloseButton } from "@mantine/core";
import React, { useEffect, useState } from "react";
import "./ResonPop.css"


interface CardPropsType {
    detail: any;
    setReasonePop: any;
}
const ResonPop: React.FC<CardPropsType> = (props): JSX.Element => {
    const { detail, setReasonePop } = props
    const [popup, setPopup]: any = useState(true);
    useEffect(() => {
        setReasonePop(popup);
    }, [popup]);

    return <>
        <div className={"reason-pop-main active"}>
            <div className="reason-pop">
                <CloseButton
                    className="close1"
                    title="Close popover"
                    size="xl"
                    onClick={() => setPopup(false)}
                    iconSize={20}
                />
                <p className="title">Rejected Reason</p>
                <p className="body">{detail.response}</p>
            </div>
        </div>
    </>
};

export default ResonPop;
