import { useCallback, useEffect, useState } from "react";
import ApprovePopup from "../../components/ApprovePopup/ApprovePop";
import IssuePopup from "../../components/IssuePopup/IssuePop";
import RequestPop from "../../components/RequestPopup/RequestPop";
import API_Services from "../../services/CertificateUsersService";
import { SOCKET_URL } from "../../common/config";

import {
  Badge,
  Box,
  Button,
  Card,
  createStyles,
  Grid,
  Group,
  Loader,
  Menu,
  Pagination,
  Select,
  TextInput,
  Title,
  Container,
  Checkbox,
  Modal,
} from "@mantine/core";
import moment from "moment";
import { useSelector } from "react-redux";
import NoData from "../../components/LottieFiles/NoData";
import ResonPop from "../../components/ReasonPop/ResonPop";
import SuccessPop from "../../components/successPopup/SuccessPop";
import ViewPopup from "../../components/ViewPop/ViewPop";
import CertificateRequestService from "../../services/CertificateRequestsService";
import "./CertificateReq.css";
import ProcessModal from "../../components/AllPopUPs/ProcessModal";
import { Table } from "@mantine/core";
import { IconArrowDown, IconSearch } from "@tabler/icons-react";

import {
  startNavigationProgress,
  completeNavigationProgress,
} from "@mantine/nprogress";
import io from "socket.io-client";
const useStyles = createStyles((theme) => ({
  item: {
    backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    color: theme.white,
    "&[data-hovered]": {
      backgroundColor:
        theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      color: theme.white,
    },
  },
}));
const CertificateReq = () => {
  const { classes } = useStyles();
  const [modalProcess, setModalProcess] = useState("");
  const [modalClose, setModalClose] = useState(false);
  console.log(modalProcess, "8888888");
  const [certificateReqList, setCertificateReqList]: any = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [certificateReqListLength, setCertificateReqListLength]: any =
    useState();

  const [requestPop, setRequestPop]: any = useState(false);
  const [issuePop, setIssuePop]: any = useState(false);
  const [approvePop, setApporvePop]: any = useState(false);
  const [requestList, setRequestList]: any = useState([]);
  const [detail, setDetail]: any = useState();
  const [confirm, setConfirm]: any = useState();
  const [Status, setStatus]: any = useState();
  const [message, setMessage]: any = useState();
  const [input, setInput]: any = useState();
  const [viewPop, setViewPop]: any = useState();
  const [viewDetails, setViewDetails]: any = useState();
  const [reasonePop, setReasonePop]: any = useState(false);
  //table---
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal]: any = useState<number>();
  const [loading, setLoading]: any = useState(false);
  const [processModal, setProcessModal]: any = useState(false);
  const [countData, setCountData]: any = useState<object>();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [socket, setSocket] = useState<any>(null);

  const userdetails = useSelector((s: any) => s.user.value);

  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [imageURl, setImageUrl] = useState<undefined | string>(undefined);

  const getRequestList = useCallback(async () => {
    startNavigationProgress();
    setLoading(true);
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.role_name === "approver" || user.role_name === "issuer") {
      try {
        await API_Services.ListCertificateRequestByApprove().then((res) => {
          setRequestList(res.data.certificate_requests);

          setTotal(res.data.total);
          setLoading(false);
          completeNavigationProgress();
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        completeNavigationProgress();
      }
    } else if (user.role_name === "employee") {
      try {
        await API_Services.ListCertificateRequestForEmployee(user.id).then(
          (res) => {
            setRequestList(res.data.certificate_requests);

            setTotal(res.data.total);
            setLoading(false);
            completeNavigationProgress();
          }
        );
      } catch (error) {
        setLoading(false);
        completeNavigationProgress();
      }
    } else if (user.role_name === "admin") {
      let list: any = [];
      try {
        await API_Services.ListCertificateRequestByAdminTocomplete().then(
          (res) => {
            list = [...list, ...res.data.certificate_requests];
          }
        );
        await API_Services.ListCertificateRequestByAdminToProcess().then(
          (res) => {
            list = [...list, ...res.data.certificate_requests];
          }
        );
        setRequestList(list);
        setTotal(list.length);
        setLoading(false);
        completeNavigationProgress();
      } catch (error) {
        console.log(error);
        setLoading(false);
        completeNavigationProgress();
      }
    } else {
      try {
        await API_Services.ListCertificateRequest().then((res) => {
          setLoading(false);
          completeNavigationProgress();
          setRequestList(res.data.certificate_request);
        });
      } catch (error) {
        setLoading(false);
        completeNavigationProgress();
        console.log(error);
      }
    }
  }, []);

  const getCertificateApi = useCallback(async () => {
    startNavigationProgress();
    setLoading(true);
    if (
      userdetails?.user_roles &&
      userdetails?.user_roles[`GET_SPECIFIC_CERTIFICATE`] &&
      userdetails?.user_roles[`GET_SPECIFIC_CERTIFICATE`][0].can_read
    ) {
      console.log("Staff Logged");

      await CertificateRequestService.getSpecificCertificate()
        .then((res: any) => {
          if (res?.status) {
            console.log("getcerti", res?.data?.certificate_request);
            setCertificateReqList(res?.data?.certificate_request);
            setCertificateReqListLength(res?.data?.certificate_request?.length);
            setTotal(res?.data?.total);
          } else {
            console.log("Err", "Get Certificate Request Error");
          }
          completeNavigationProgress();
        })
        .catch((err: any) => {
          completeNavigationProgress();
          console.log("Err", err);
        });
    } else {
      await CertificateRequestService.getCertificate(
        pageSize * (page - 1),
        pageSize
      )
        .then((res: any) => {
          if (res?.status) {
            console.log("get certi", res?.data?.certificate_request);
            setCertificateReqList(res?.data?.certificate_request);
            setCertificateReqListLength(res?.data?.certificate_request?.length);
            setTotal(res?.data?.total);
          } else {
            console.log("Err", "Get Certificate Request Error");
          }
          completeNavigationProgress();
        })
        .catch((err: any) => {
          console.log("Err", err);
          completeNavigationProgress();
        });
    }
    setLoading(false);
  }, [pageSize, page]);

  const certificateCount = async () => {
    startNavigationProgress();
    await CertificateRequestService.cefrtificateCount()
      .then((res: any) => {
        let prevData: any = {};
        res?.data?.getCount.map((d: any) => {
          if (d?.response_status === "waiting") {
            prevData.yetToStart = d?._count?.response_status;
          }
          if (d?.response_status === "pending") {
            prevData.pending = d?._count?.response_status;
          }
          if (d?.response_status === "rejected") {
            prevData.rejected = d?._count?.response_status;
          }
          if (d?.response_status === "completed") {
            prevData.completed = d?._count?.response_status;
          }
          if (d?.response_status === "final") {
            prevData.final = d?._count?.response_status;
          }
        });
        setCountData(prevData);
        completeNavigationProgress();
      })
      .catch((err: any) => {
        console.log("Err", err);
        completeNavigationProgress();
      });
  };
  const certificateProcess = async (data: any, id: any) => {
    setLoading(true);
    startNavigationProgress();
    console.log("dataaaa", id);
    if (data === "waiting") {
      await CertificateRequestService.certificateProcess(id)
        .then((res: any) => {
          console.log("res cewrrrrr", res);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } else if (data === "final") {
      await CertificateRequestService.certificateComplete(id)
        .then((res: any) => {
          console.log("res isssue", res);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } else {
      await CertificateRequestService.certificateIssue(id)
        .then((res: any) => {
          console.log("res isssue", res);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    }
    getCertificateApi();
    setLoading(false);
    completeNavigationProgress();
  };

  const setcurrentPage = (value: any) => {
    console.log("value", value);

    setPage(value);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
  };

  useEffect(() => {
    getCertificateApi();
    certificateCount();
  }, [getCertificateApi]);
  interface Certificate {
    position: number;
    name: string;
    symbol: string;
    mass: number;
  }

  const rejectCert = async () => {
    setLoading(true);
    startNavigationProgress();
    try {
      await CertificateRequestService.rejectCertificate(
        selectedRows[0].certificate_request?.id
      ).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      completeNavigationProgress();
    }
    setLoading(false);
    completeNavigationProgress();
    getCertificateApi();
  };

  const handleProcessClick = () => {
    const certificateId = selectedRows.map((item: any, index: any) => {
      return { certificate_request_id: item?.certificate_request?.id };
    });
    let data = [];
    for (let i = 0; i < certificateId.length; i++) {
      let payload = {
        certificate_request_id: certificateId[i]["certificate_request_id"],
        fields: { email: "sriram.p@kprinfosolution.com" },
      };
      data.push(payload);
      // certificateId[i]['fields'] = {email:'sriram.paramasivam@kprinfo.com'}
    }
    socket.emit("process_certificate", {
      data: data,
    });

    socket.on("process_certificate", (data: any) => {
      setModalProcess(data?.data);
      setModalClose(!!data?.status);
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleCheckboxChange = (data: any) => {
    const selectedIndex = selectedRows.findIndex(
      (row: any) => row.id === data.id
    );

    if (selectedIndex === -1) {
      setSelectedRows([...selectedRows, data]);
    } else {
      const updatedSelection = [...selectedRows];
      updatedSelection.splice(selectedIndex, 1);
      setSelectedRows(updatedSelection);
    }
  };
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      setSelectedRows([...certificateReqList]);
    } else {
      setSelectedRows([]);
    }
  };

  const data: Certificate[] = [
    { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
    { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
    { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
    { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
    { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
  ];

  const columns = [
    { name: "position", title: "Element Position" },
    { name: "name", title: "Element Name" },
    { name: "symbol", title: "Symbol" },
    { name: "mass", title: "Atomic Mass" },
  ];

  useEffect(() => {
    let token: any = localStorage.getItem("token");
    const newSocket = io(SOCKET_URL, {
      extraHeaders: { Authorization: `bearer ${JSON.parse(token)}` },
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const elements = [
    { position: 6, mass: 12.011, symbol: "C", name: "Carbon" },
    { position: 7, mass: 14.007, symbol: "N", name: "Nitrogen" },
    { position: 39, mass: 88.906, symbol: "Y", name: "Yttrium" },
    { position: 56, mass: 137.33, symbol: "Ba", name: "Barium" },
    { position: 58, mass: 140.12, symbol: "Ce", name: "Cerium" },
  ];
  const rows = elements.map((element) => (
    <tr key={element.name}>
      <td>{element.position}</td>
      <td>{element.name}</td>
      <td>{element.symbol}</td>
      <td>{element.mass}</td>
    </tr>
  ));
  const atLeastOneChecked = selectedRows.length > 0;
  const filteredCertificateReqList = certificateReqList.filter((data: any) =>
    data.certificate_request.fields.offerId
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <div>
        {reasonePop === true && (
          <ResonPop detail={detail} setReasonePop={setReasonePop} />
        )}
        {viewPop === true && (
          <ViewPopup viewDetails={viewDetails} setViewPop={setViewPop} />
        )}
        {Status === "success" && (
          <SuccessPop message={message} setIssuePop={setIssuePop} />
        )}
        {requestPop === true && (
          <RequestPop
            setRequestPop={setRequestPop}
            getRequestList={getRequestList}
          />
        )}
        {issuePop === true && (
          <IssuePopup
            setIssuePop={setIssuePop}
            detail={detail}
            setConfirm={setConfirm}
            input={input}
            setInput={setInput}
            getRequestList={getRequestList}
          />
        )}
        {approvePop === true && (
          <ApprovePopup
            setApporvePop={setApporvePop}
            detail={detail}
            setConfirm={setConfirm}
            input={input}
            setInput={setInput}
            getRequestList={getRequestList}
          />
        )}
        {processModal && (
          <ProcessModal
            setProcessModal={setProcessModal}
            detail={detail}
            setConfirm={setConfirm}
            input={input}
            certificateProcess={certificateProcess}
            setInput={setInput}
            getRequestList={getRequestList}
          />
        )}

        <Modal
          fullScreen
          opened={showPdfViewer}
          onClose={() => setShowPdfViewer(false)}
        >
          <Group position="center">
            <object
              data={imageURl}
              type="application/pdf"
              className="pdfobject"
            />
          </Group>
        </Modal>

        <Container size="lg">
          <Grid>
            <Grid.Col span={12}>
              <>
                <Title order={1}>Offer Letter Requests</Title>

                <Grid
                  my={20}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextInput
                    icon={<IconSearch />}
                    placeholder="Search with"
                    size="md"
                    radius="md"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#25204f",
                      }}
                      onClick={handleProcessClick}
                      disabled={!atLeastOneChecked}
                    >
                      {`Process Request(s)`}
                    </Button>

                    <Button
                      ml={10}
                      color="red"
                      variant="outline"
                      onClick={rejectCert}
                      disabled={
                        selectedRows.length > 1 || selectedRows.length === 0
                      }
                    >
                      {`Reject`}
                    </Button>
                  </div>
                </Grid>
              </>

              {filteredCertificateReqList?.length === 0 ? (
                <NoData />
              ) : (
                <>
                  <Table
                    verticalSpacing="md"
                    highlightOnHover
                    horizontalSpacing="xl"
                  >
                    <thead
                      style={{
                        border: "none",
                        fontFamily: "poppins",
                        fontSize: "16px",
                      }}
                    >
                      <tr>
                        <th style={{ display: "flex", gap: "15px" }}>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />
                          Date{" "}
                        </th>
                        <th>Document</th>
                        <th>Offer Id</th>
                        <th>Requested By</th>
                        <th>Status</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "25px" }}>
                      {filteredCertificateReqList?.map(
                        (data: any, index: any) => {
                          return (
                            <tr key={data?.id}>
                              <td style={{ display: "flex", gap: "15px" }}>
                                <Checkbox
                                  checked={selectedRows.some(
                                    (row: any) => row.id === data.id
                                  )}
                                  onChange={() => handleCheckboxChange(data)}
                                ></Checkbox>

                                {moment(data?.created_at).format("DD/MM/YYYY")}
                                {/* {document.status === true ? "Active" : "In-Active"}{" "} */}
                              </td>
                              <td>
                                {data?.document?.template_name
                                  ? data?.document?.template_name
                                      .charAt(0)
                                      .toUpperCase() +
                                    data?.document?.template_name?.slice(1)
                                  : data?.certificate_request?.document?.template_name
                                      .charAt(0)
                                      .toUpperCase() +
                                    data?.certificate_request?.document?.template_name.slice(
                                      1
                                    )}
                              </td>
                              <td>
                                {" "}
                                {
                                  data?.certificate_request?.fields?.offerId
                                }{" "}
                              </td>
                              <td>
                                {data
                                  ?.users_certificate_request_requested_byTousers
                                  ?.first_name
                                  ? data
                                      ?.users_certificate_request_requested_byTousers
                                      ?.first_name
                                  : data?.certificate_request
                                      ?.users_certificate_request_requested_byTousers
                                      ?.first_name}
                              </td>
                              <td>
                                {data?.response_status
                                  ? data.response_status
                                      .charAt(0)
                                      .toUpperCase() +
                                    data.response_status.slice(1)
                                  : data?.certificate_request?.response_status
                                      .charAt(0)
                                      .toUpperCase() +
                                    data?.certificate_request?.response_status?.slice(
                                      1
                                    )}
                              </td>
                              <td>
                                <Button
                                  variant="outline"
                                  onClick={() => {
                                    //loop through the document info array and find the offer letter
                                    //set the image url to the offer letter
                                    //set the show pdf viewer to true
                                    //show the pdf viewer
                                    //close the pdf viewer
                                    //set the show pdf viewer to false

                                    const urlRegex = /(https?:\/\/[^\s]+)/g;

                                    const documentInfo =
                                      data.certificate_request.document_info[0];
                                    const urlKey = Object.keys(
                                      documentInfo
                                    ).find((key) =>
                                      urlRegex.test(documentInfo[key])
                                    );
                                    if (urlKey) {
                                      console.log("urlKey :", urlKey);
                                      setImageUrl(documentInfo[urlKey]);
                                      setShowPdfViewer(true);
                                    }
                                  }}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  <Group position="apart" mt="md">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box mr="md">Rows per page</Box>
                      <Select
                        onChange={(e) => setrowsperpage(e)}
                        style={{ width: "4rem" }}
                        defaultValue="10"
                        value={String(pageSize)}
                        data={[
                          { value: "10", label: "10" },
                          { value: "15", label: "15" },
                          { value: "20", label: "20" },
                        ]}
                      />
                    </Box>
                    <Pagination
                      styles={(theme) => ({
                        item: {
                          "&[data-active]": {
                            backgroundImage: theme.fn.gradient({
                              from: "blue",
                              to: "violet",
                            }),
                          },
                        },
                      })}
                      total={1}
                      // boundaries={1}
                      // page={page}
                      initialPage={1}
                      onChange={(e) => setcurrentPage(e)}
                    />
                  </Group>
                </>
              )}
            </Grid.Col>
          </Grid>
        </Container>
        <Modal
          style={{}}
          centered
          opened={modalOpen}
          onClose={() => modalClose && setModalClose(false)}
          // title="Processing Modal"
          withCloseButton={false}
          padding={0}
          radius={0}
        >
          {/* Your modal content goes here */}

          <img
            style={{ width: "100%", height: "150px" }}
            src={require("../../Assets/blockchain-loader.gif")}
            alt="DocB"
          />

          <div
            style={{
              height: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>{modalProcess}</p>

            {modalClose && (
              <>
                <Button
                  variant="outline"
                  mt={20}
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </Button>
              </>
            )}
          </div>

          {/* {modalClose && (
  <>
    <div>
      <Button>Close</Button>
    </div>
  </>
)} */}
        </Modal>
      </div>
    </>
  );
};

export default CertificateReq;
