import {
  Button,
  Card,
  Col,
  Container,
  Footer,
  Grid,
  Input,
  Text,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import verifyCertificateService, {
  verifycertificateservicePost,
} from "../../services/verifyCertificate";
import GettingCertificate from "../LottieFiles/loadingCertificate";
import OtpInput from "react-otp-input";
import { Document, Page } from "react-pdf";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const VerifyCertificate = () => {
  const [loading, setLoading] = useState(true);
  const [validCertificate, setValidCertificate] = useState<undefined | boolean>(
    false
  );
  const [imageURl, setImageUrl] = useState<undefined | string>(undefined);

  const [respData, setRespData] = useState<any>();
  const [codeOtp, setCode] = useState("");
  const [loadingNear, setLoadingNear] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpValid, setOtpValid] = useState(true);
  const [showOtpScreen, setShowOtpScreen] = useState(true);

  const certificate = async () => {
    setLoading(true);
    let arr = window.location.pathname.split("/").at(-1);
    verifyCertificateService(arr)
      .then((e) => {
        setRespData(e?.data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleOtpSubmit = async (code: any) => {
    setLoading(true);
    // setShowOtpScreen(false);
    const data = await verifycertificateservicePost({
      email: respData.mail,
      otp: code,
    });

    if (data?.status) {
      setShowOtpScreen(false);
      setValidCertificate(true);
      setRespData(data?.data);
      setImageUrl(data?.data?.image_url);
    } else {
      setOtpValid(false);
    }
    setLoading(false);

    setCode("");
  };

  const validateOtp = (otp: string) => {
    return otp.length === 6;
  };

  const handleChange = (code: any) => {
    setCode(code);
    if (code.length === 6) {
      handleOtpSubmit(code);
    }
  };

  useEffect(() => {
    if (!showOtpScreen) {
      certificate();
    }
  }, []);

  const navigateToExplorer = async () => {
    const tempHash = respData.hash_value.split(",").at(0);
    setLoadingNear(true);

    try {
      let resp: any = await fetch(
        "https://explorer-backend-testnet-prod-24ktefolwq-uc.a.run.app/trpc/utils.search?batch=1",
        {
          method: "POST",
          body: JSON.stringify({
            "0": {
              value: tempHash,
            },
          }),
        }
      );

      resp = await resp.json();
      window.open(
        `https://explorer.testnet.near.org/transactions/${resp[0]["result"]["data"]["transactionHash"]}`,
        "_blank"
      );

      setLoadingNear(false);
    } catch (error) {
      setLoadingNear(false);
    }
  };

  const openPDF = () => {
    window.open(imageURl, "_blank");
  };

  const navigate = useNavigate();
  const handleIconClick = () => {
    navigate("/");
  };

  useEffect(() => {
    certificate();
  }, []);
  return (
    <Card style={{ width: "100%", height: "100vh" }}>
      {loading ? (
        <div>
          <GettingCertificate />
        </div>
      ) : (
        <div>
          <div
            style={{
              backgroundColor: "#201C1B",
              borderRadius: "10px",
              height: "98vh",
            }}
          >
            <svg
              style={{ margin: "20px", cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#fff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              onClick={handleIconClick}
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l14 0" />
              <path d="M5 12l6 6" />
              <path d="M5 12l6 -6" />
            </svg>

            <Container
              style={{
                backgroundColor: "",
              }}
            >
              <Grid>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ backgroundColor: "#201C1B" }}
                >
                  <Grid>
                    <Col span={12} sm={12}>
                      <div>
                        {showOtpScreen ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: "70vh",
                            }}
                          >
                            <div>
                              <h1
                                style={{
                                  color: "#fff",
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                We sent you a code
                              </h1>

                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  textAlign: "center",
                                  fontWeight: "200",
                                }}
                              >
                                Please enter it below to verify your email
                              </p>

                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  textAlign: "center",
                                  fontWeight: "200",
                                  marginBottom: "1em",
                                }}
                              >
                                {respData?.mail}
                              </p>

                              <OtpInput
                                skipDefaultStyles={true}
                                value={codeOtp}
                                onChange={handleChange}
                                inputType="number"
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus={true}
                                // inputClassName={styles.noSpinner}
                                inputStyle="noSpinner"
                              />
                              {!otpValid && (
                                <p
                                  style={{
                                    color: "red",
                                    textAlign: "end",
                                    marginRight: "15px",
                                  }}
                                >
                                  Invalid OTP
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <h6
                                  style={{
                                    fontSize: "20px",
                                    paddingTop: "30px",
                                    color: "#fff",
                                    paddingLeft: "1px",
                                  }}
                                >
                                  CERTIFY
                                </h6>
                              </div>
                              {/* <img
                                style={{ width: "30%", height: "150px" }}
                                src={require("../../Assets/download.png")}
                                alt="Certify"
                              /> */}
                            </div>

                            {validCertificate ? (
                              <div style={{ color: "#fff" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    style={{ width: "100px" }}
                                    src={require("../../Assets/verify.png")}
                                    alt="Certify"
                                  />
                                  <Title>Verified</Title>
                                </div>
                                <Text my={10}>
                                  {`This certificate is authentic and issued via cerify.io`}
                                </Text>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "2%",
                                  }}
                                >
                                  <Text fw={"bold"}>Issued By:</Text>
                                  <Text pl={5}>Certify</Text>
                                </div>

                                <div>
                                  <Text mt={20} fw={"bold"}>
                                    Issued To
                                  </Text>

                                  <Text pl={25}>
                                    Name:{" "}
                                    {
                                      respData?.certificate
                                        ?.certificate_request[0]?.fields?.name
                                    }
                                  </Text>
                                  <Text pl={25}>
                                    Offer Id:{" "}
                                    {
                                      respData?.certificate
                                        ?.certificate_request[0]?.fields
                                        ?.offerId
                                    }
                                  </Text>

                                  {/*  */}
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "2%",
                                  }}
                                >
                                  <Text fw={"bold"}>Issued On:</Text>
                                  <Text pl={5}>{`${moment(
                                    respData?.created_at
                                  ).format("MM/DD/yyyy hh:mm:ss")}`}</Text>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: "30px",
                                  }}
                                >
                                  <Button
                                    mx={5}
                                    onClick={() => navigateToExplorer()}
                                    disabled={loadingNear}
                                    loading={loadingNear}
                                  >
                                    Verify in Near
                                  </Button>

                                  <Button mx={5} onClick={() => openPDF()}>
                                    Download Certificate
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div style={{ color: "#fff" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Title order={1} my={20} pl={5}>
                                    Unverified
                                  </Title>
                                </div>
                                <Text my={10}>
                                  Certificate is not valid or has been tampered.
                                </Text>
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "55px",
                              }}
                            >
                              <Text color="gray" size="xs">
                                Powered by certify.io | made with ❤️ from India
                              </Text>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Grid>
                </Col>
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </Card>
  );
};

export default VerifyCertificate;
