import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/CommonMethod";
const API_URL = API_BASE_URL;

const getDocumentFileds = async (data: any) => {
  // console.log(id);

  const body = JSON.stringify(data);

  let res = await fetch(
    `${API_URL}/list-document-fields`,
    CommonMethod.PostMethod(body)
  );
  return await res.json();
};

const fetchDocumentList = async () => {
  const body = JSON.stringify({
    where: {
      status: true,
    },
  });

  let respons = await fetch(
    `${API_URL}/list-document-type`,
    CommonMethod.PostMethod(body)
  );
  return await respons.json();
};

const ListCertificateRequest = async () => {
  const body = JSON.stringify({
    include: {
      document: {
        select: {
          template_name: true,
          id: true,
        },
      },
      certificate: true,
      users_certificate_request_requested_byTousers: {
        select: {
          username: true,
          present_role: true,
        },
      },
      users_certificate_request_issued_byTousers: {
        select: {
          username: true,
          present_role: true,
        },
      },
      users_certificate_request_rejected_byTousers: {
        select: {
          username: true,
          present_role: true,
        },
      },
      users_certificate_request_approved_byTousers: {
        select: {
          username: true,
          present_role: true,
        },
      },
    },
  });

  let respons = await fetch(
    `${API_URL}/list-certificate-request`,
    CommonMethod.PostMethod(body)
  );

  return await respons.json();
};

const ListCertificateRequestByApprove = async () => {
  const body = JSON.stringify({});

  let response = await fetch(
    `${API_URL}/approver-list-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const ListCertificateRequestForEmployee = async (id: any) => {
  let body = JSON.stringify({
    filter: {
      requested_by: id,
    },
  });

  let response = await fetch(
    `${API_URL}/list-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return response.json();
};

const ListCertificateRequestByAdminTocomplete = async () => {
  let body = JSON.stringify({
    filter: {
      response_status: "final",
    },
  });

  let response = await fetch(
    `${API_URL}/list-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const ListCertificateRequestByAdminToProcess = async () => {
  let body = JSON.stringify({
    filter: {
      response_status: "waiting",
    },
  });
  let response = await fetch(
    `${API_URL}/list-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const IssueCertificate = async (param: any, input: any) => {
  let body = JSON.stringify({
    certificate_request_id: param.uuid,
    document_info: [input],
  });
  let response = await fetch(
    `${API_URL}/issue-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const ApproveCertificate = async (param: any, input: any) => {
  let body = JSON.stringify({
    certificate_request_id: param.uuid,
    document_info: [input],
  });

  let response = await fetch(
    `${API_URL}/approve-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const CompleteCertificate = async (param: any) => {
  let body = JSON.stringify({ certificate_request_id: param.uuid });

  let response = await fetch(
    `${API_URL}/complete-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const ProcessCertificate = async (param: any) => {
  let body = JSON.stringify({ certificate_request_id: param.uuid });

  let response = await fetch(
    `${API_URL}/process-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const RejectCertificate = async (param: any, rejectMessage: any) => {
  let body = JSON.stringify({
    certificate_request_id: param.uuid,
    response: rejectMessage,
  });

  let response = await fetch(
    `${API_URL}/reject-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const ViewCertificate = async (param: any) => {
  let response = await fetch(
    `${API_URL}/certificate/${param.certificate_id}`,
    CommonMethod.GetMethod()
  );

  return await response.json();
};

//  ==========================getDropdownValues on add users======================

// const fetchDesignationList = async () => {
//   let token: any = localStorage.getItem("token");
//   const config = {
//     headers: {
//       "Content-type": "application/json",
//       Authorization: JSON.parse(token),
//     },
//   };
//   return await axios
//     .post(`${API_URL}/list-designations`, {}, config)
//     .then((response) => {
//       console.log(response);

//       return response;
//     });
// };
const fetchDesignationList = async () => {
  const body = JSON.stringify({});

  let response = await fetch(
    `${API_URL}/list-designations`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const fetchUserRoleList = async () => {
  const body = JSON.stringify({});

  let response = await fetch(
    `${API_URL}/list-roles`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const fetchDepartmentList = async () => {
  const body = JSON.stringify({
    select: {
      department_name: true,
      id: true,
    },
  });

  let response = await fetch(
    `${API_URL}/list-department`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const registerUser = async (input: any) => {
  let body = JSON.stringify(input);

  let response = await fetch(`${API_URL}/users`, CommonMethod.PostMethod(body));
  return await response.json();
};

const UpdateEmployee = async (id: any, input: any) => {
  let body = JSON.stringify({
    designation_id: input.designation_id,
    department_id: input.department_id,
  });

  let response = await fetch(
    `${API_URL}/employee/${id}`,
    CommonMethod.PutMethod(body)
  );
  return await response.json();
};

const UpdateUser = async (params: any, input: any) => {
  let body = JSON.stringify(input);

  let response = await fetch(
    `${API_URL}/users/${params}`,
    CommonMethod.PutMethod(body)
  );
  return await response.json();
};

const AddUserRoles = async (userID: any, rollID: any) => {
  let body = JSON.stringify({
    user_id: userID,
    role_id: rollID,
    can_read: true,
    can_write: true,
  });

  let response = await fetch(
    `${API_URL}/assign-role`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

//getuserLIST
const getUserList = async (data: any) => {
  let respons = await fetch(
    `${API_URL}/list-users`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );
  let k = await respons.json();
  // console.log('responseeeeee',k);

  return k;
};
const getUserListByFilter = async (data: any) => {
  let respons = await fetch(
    `${API_URL}/list-users`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );
  let k = await respons.json();
  // console.log('responseeeeee',k);

  return k;
};

const DeleteUser = async (params: any) => {
  let response = await fetch(
    `${API_URL}/users/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );
  return await response.json();
};

const ListUserRole = async () => {
  let body = JSON.stringify({
    
    include: {
      roles: true,
    },
  });

  // @ts-ignore

  let response = await fetch(
    `${API_URL}/list-user-roles`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};
const ListUserRoles = async () => {
  let body = JSON.stringify({
    include: {
      roles: true,
    },
  });

  // @ts-ignore

  let response = await fetch(
    `${API_URL}/view-user-role`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteUserRole = async (params: any) => {
  let response = await fetch(
    `${API_URL}/user-roles/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );
  return await response.json();
};
const SwitchRoles = async (role_id: any) => {
  let body = JSON.stringify({
    present_role: role_id,
  });

  let response = await fetch(
    `${API_URL}/switchRole`,
    CommonMethod.PutMethod(body)
  );
  return await response.json();
};
const Getcount = async () => {
  let response = await fetch(
    `${API_URL}/certificateCount?status=true&start=2022-12-01&end=2022-12-31`,
    CommonMethod.GetMethod()
  );
  return await response.json();
};

const API_Services = {
  // request certificate
  getDocumentFileds,
  fetchDocumentList,
  ListCertificateRequest,
  IssueCertificate,
  ApproveCertificate,
  CompleteCertificate,
  RejectCertificate,
  ViewCertificate,
  ProcessCertificate,
  ListCertificateRequestByApprove,
  ListCertificateRequestForEmployee,
  ListCertificateRequestByAdminTocomplete,
  ListCertificateRequestByAdminToProcess,
  getUserListByFilter,
  // getDropdownValue on add users
  fetchDesignationList,
  fetchUserRoleList,
  fetchDepartmentList,

  //getuserlist
  registerUser,
  AddUserRoles,
  UpdateUser,
  ListUserRoles,
  UpdateEmployee,
  getUserList,
  DeleteUser,

  // list userRolles
  ListUserRole,
  Getcount,
  SwitchRoles,
  DeleteUserRole,
};

export default API_Services;
