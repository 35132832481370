import { CloseButton, Modal } from "@mantine/core";
import React, { useState } from "react";
import Icon from "../../Icon";
import "./SuccessPop.css";

interface CardPropsType {
  message?: any;
  setIssuePop?: any;
  setRequestPop?: any
}
const SuccessPop: React.FC<CardPropsType> = (props): JSX.Element => {
  const { message, setIssuePop, setRequestPop } = props;
  const [popup, setPopup]: any = useState(true);
  if (message === "Certificate Issued  Successfully !") {
    setIssuePop(popup);
  }

  return (
    <>

      <Modal
        opened={popup}
        onClose={() => {
          setRequestPop(false)
          setPopup(false)
        }}
        title="Create Certificate"
        size="auto"
      >

        <div className="success-popup-row1">
          <Icon size={60} icon="Success" color="#066004" />
        </div>
        <div className="success-popup-row2">
          <p>{message}</p>
        </div>

      </Modal>
      {/* <div
        className={popup ? "active success-popup-main" : "success-popup-main"}
      >
        <div className="success-popup">
          <CloseButton
            className="close"
            title="Close popover"
            size="xl"
            onClick={() => {
              setRequestPop(false)
              setPopup(false)
            }}
            iconSize={20}
          />
          <div className="success-popup-row1">
            <Icon size={60} icon="Success" color="#066004" />
          </div>
          <div className="success-popup-row2">
            <p>{message}</p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default React.memo(SuccessPop);
