import "./TotalCard.css";
import React from "react";
import Icon from "../../Icon";
import { COLORS } from "../../custom";

interface CardPropsType {
  title: string;
  icon1_Name: string;
  icon2_Name?: string;
  icon1_Color?: string;
  icon2_Color?: string;
  iconSize?: number;
  cardDigit?: number;
}
const TotalCard: React.FC<CardPropsType> = (props): JSX.Element => {
  const { title, icon1_Name, icon2_Name, icon1_Color, icon2_Color, cardDigit } =
    props;

  return (
    <div className="total-card-main">
      <h3 style={{ color: COLORS.color }}>{title}</h3>
      <p style={{ color: COLORS.color }}>
        $
        {cardDigit
          ? cardDigit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0}
      </p>
      <Icon size={30} color={icon1_Color} icon={icon1_Name} />
    </div>
  );
};

export default TotalCard;
