import { Button, Grid, Input } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthService from "./AuthService";
import "./resetPassword.css";
const ResetPassword = () => {
  const dispatch = useDispatch();

  const [input, setInput]: any = useState({ email: "" });
  const [errorMessage, setErrorMessage] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<String>("");
  const [loading, setLoading]: any = useState(false);
  const navigate = useNavigate();
  const [visible, { toggle }] = useDisclosure(false);
  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    // console.log(input);
  };

  const formSubmitter = async (e: any) => {
    e.preventDefault();
    setSuccessMessage("");
    try {
      setLoading(true);
      await AuthService.sendEmailLink(input).then((res) => {
        console.log(res);

        showNotification({
          color: "green",
          message: res?.message,
        });
        setLoading(false);
        navigate("/login");
      });
    } catch (error: any) {
      console.log(error);

      setLoading(false);
      showNotification({
        color: "red",
        message: "Invalid details",
      });
    }
  };

  return (
    <>
      <Grid
        style={{
          width: "100% ",
          height: "100vh",
          margin: "px",
          justifyContent: "center",
        }}
      >
        <Grid.Col
          md={12}
          style={{
            backgroundColor: "#fff7fb",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          lg={12}
        >
          <Grid>
            <Grid.Col
              md={12}
              lg={6}
              style={{
                backgroundColor: "#ffffff",
                width: "60vh",
                height: "80vh",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                padding: "20px",
              }}
            >
              <div className="form_content">
                <div className="welcome_back">
                  <h2 className="welcomefont"> Reset Password</h2>
                </div>

                <div className="fromBox">
                  <form onSubmit={formSubmitter}>
                    <div className="inputBox">
                      <Input.Wrapper label="Email" required>
                        <Input
                          value={input.email}
                          type={"text"}
                          name="email"
                          placeholder="hello@example.com"
                          onChange={handleChange}
                          required
                        />
                      </Input.Wrapper>
                    </div>

                    <div className="forgot-password">
                      <p
                        onClick={() => {
                          navigate("/login");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Remember Password?
                      </p>
                    </div>

                    <div>
                      {errorMessage &&
                        errorMessage.length > 0 &&
                        errorMessage.map((errMsg: any) => (
                          <p style={{ color: "red", margin: "10px 0px" }}>
                            {errMsg}
                          </p>
                        ))}
                      {successMessage.length > 0 && (
                        <p style={{ color: "green" }}>{successMessage}</p>
                      )}
                    </div>
                    <div className="send_email_btn">
                      <Button
                        className="button"
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        style={{ fontSize: "15px" }}
                      >
                        {loading ? "Loading..." : "Send Email"}
                      </Button>
                    </div>
                    <div className="last-line">
                      <p>
                        Don't have an account?
                        <span
                          style={{
                            color: "#25204f",
                            cursor: "pointer",
                            paddingLeft: "10px",
                          }}
                        >
                          Signup
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </Grid.Col>
            <Grid.Col
              md={12}
              lg={6}
              style={{
                backgroundColor: "#f2f3f7",
                width: "60vh",
                height: "80vh",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <div className="img_cir">
                <img
                  style={{ width: "100%" }}
                  className="img1"
                  src={require("../../Assets/Loginlogo.png")}
                  alt="Certify"
                />
              </div>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
};
export default ResetPassword;
