import {
  Box,
  Card,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
  Button,
  Checkbox,
  Autocomplete,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import { showNotification } from "@mantine/notifications";
import "./UserRollSetting.css";
import NoData from "../../components/LottieFiles/NoData";
import Permissionservices from "../../services/PermissionServices";
import PermissionModal from "../../components/AllPopUPs/PermissionModal";
import DeleteModal from "../../components/AllPopUPs/DeleteModal";
import EditPermissionModal from "../../components/AllPopUPs/EditPermissionModal";

const RollSettingsPermissions = () => {
  //table---
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);

  //table---
  const [permissionModal, setPermissionModal]: any = useState(false);

  const [permissionlist, setPermissionlist]: any = useState("");
  const [editModal, setEditModal]: any = useState(false);
  // const [editpermission, setEditPermission]: any = useState(false);
  const [editper_data, setEditPer_Data]: any = useState();
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [Active, setActive]: any = useState(true);
  const [permissionTotal, setPermissionTotal]: any = useState();
  const [permissionName, setpermissionName]: any = useState("");

  //permissions functions
  const getPermissionlists = async () => {
    setLoading(true);
    try {
      let data = {
        skip: pageSize * (page - 1),
        limit: pageSize,
        select: {
          role_name: true,
          id: true,
          role_permissions: {
            select: {
              permission_name: true,
              status: true,
              uuid: true,
              can_read: true,
              can_write: true,
              modules: {
                select: {
                  module_name: true,
                  id: true,
                },
              },
            },
          },
        },
      };
      let data1 = {
        limit: pageSize,
        where: {
          permission_name: {
            contains: permissionName,
            mode: "insensitive",
          },
        },
      };
      let res = await Permissionservices.Permissionlists(
        permissionName ? data1 : data
      );
      if (res.status === true) {
        setPermissionlist(res.data.roles);
        setPermissionTotal(res.data.total);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log(permissionlist);
  const handleDeletepermission = async (params: any) => {
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      await Permissionservices.Deletepermission(params).then((res) => {
        if (res.status === true) {
          getPermissionlists();
          showNotification({
            color: "green",
            message: res.message,
          });
        }
      });
    } catch (error) {
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdatepermission = async (params: any) => {
    setEditPer_Data(params);
    setEditModal(true);
  };

  useEffect(() => {
    getPermissionlists();
  }, [page, pageSize, permissionName]);

  const setcurrentPage = (value: any) => {
    setPage(value);
    setPermissionlist([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setPermissionlist([]);
  };

  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {permissionModal && (
        <PermissionModal
          setPermissionModal={setPermissionModal}
          get_permission_list={getPermissionlists}
          setEditModal={setEditModal}
          edit_permission={editModal}
          editper_data={editper_data}
        />
      )}
      {editModal && (
        <EditPermissionModal
          get_permission_list={getPermissionlists}
          setEditModal={setEditModal}
          isEditModal={editModal}
          editper_data={editper_data}
        />
      )}

      <Card mt="sm" radius="lg" shadow="lg">
        <Card.Section>
          <Group position="apart">
            <Box m="md">
              <Autocomplete
                label="Filter by Name"
                placeholder="Search Permission"
                data={
                  Array.isArray(permissionlist)
                    ? permissionlist.map(
                        (provider) => `${provider.permission_name}`
                      )
                    : []
                }
                onChange={(value) => setpermissionName(value)}
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
            </Box>
            <Box p="md">
              <Button
                style={{ background: "#25204f" }}
                onClick={() => {
                  setEditModal(false);
                  setPermissionModal(true);
                }}
              >
                + Add
              </Button>
            </Box>
          </Group>
          {loading === true ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "50px",
              }}
            >
              <Loader color="#25204f" />
            </div>
          ) :
          permissionlist && permissionlist.length > 0 ? (
            <div>
              <Table verticalSpacing="lg" highlightOnHover>
                <thead style={{ border: "none" }}>
                  <tr>
                    <th>
                      <Checkbox size="sm" />
                    </th>
                    <th>
                      <div>Name</div>
                    </th>
                    <th>
                      <div>Status</div>
                    </th>
                    <th>
                      <div>Action</div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {permissionlist?.map((permission: any, index: number) =>
                    permission.role_permissions &&
                    permission?.role_permissions.length > 0 ? (
                      <tr
                        key={permission.uuid}
                        className={
                          permission?.status === false ? "in-active-list" : ""
                        }
                      >
                        <td>
                          <Checkbox size="sm" />
                        </td>
                        <td>
                          {permission?.role_permissions[0]?.permission_name}
                        </td>

                        <td>
                          {permission?.role_permissions[0]?.status === true
                            ? "Active"
                            : "In-Active"}{" "}
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              handleUpdatepermission(permission);
                              setEditModal(true);
                            }}
                            variant="default"
                            radius="md"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Want to Delete?",

                                onConfirm: () => {
                                  handleDeletepermission(permission);
                                },
                              });
                            }}
                            variant="default"
                            radius="md"
                            ml="sm"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </Table>
              <Group position="apart" mt="md" p="lg">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box mr="md">Rows per page</Box>
                  <Select
                    onChange={(e) => setrowsperpage(e)}
                    style={{ width: "4rem" }}
                    value={String(pageSize)}
                    data={[
                      { value: "5", label: "5" },
                      { value: "10", label: "10" },
                      { value: "15", label: "15" },
                      { value: "20", label: "20" },
                    ]}
                  />
                </Box>
                <Pagination
                  styles={(theme) => ({
                    item: {
                      "&[data-active]": {
                        backgroundColor: "#25204f",
                      },
                    },
                  })}
                  total={Math.ceil(permissionTotal / pageSize)}
                  boundaries={5}
                  page={page}
                  initialPage={1}
                  onChange={(e) => setcurrentPage(e)}
                />
              </Group>
            </div>
          ) : (
            <NoData />
          )}
        </Card.Section>
      </Card>
    </>
  );
};

export default RollSettingsPermissions;
