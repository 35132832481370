import Lottie from 'lottie-react';
import noData from '../../Assets/LottieFiles/NoData.json'


export default function NoData() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: noData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return (
        <div className="controlled" style={{display:'flex',justifyContent:'center'}}>
            <Lottie
               //  options={defaultOptions}
                  style={{height:'500px',width:'500px'}}
                animationData={noData}
                loop={true}
                autoplay={true}
            />
        </div>
    )
}