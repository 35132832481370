import { DEFAULT_THEME, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { store } from "./redux/configStore";
import reportWebVitals from "./reportWebVitals";
// import { store as reduxState } from '../src/redux/configStore'
import { NavigationProgress } from '@mantine/nprogress';

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("qa") as HTMLElement);
const customTheme = {
  ...DEFAULT_THEME,
  colors: {
    // ...DEFAULT_THEME.colors,
    // blue: '#604E9E', // or any other shade of green
    deepBlue: "#25204f",
  },
};
root.render(
  <React.StrictMode>
    <NotificationsProvider
      position="bottom-right"
      zIndex={2077}
      autoClose={2000}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <MantineProvider
              theme={{
               

                headings: {
                  fontFamily: "Roboto, sans-serif",
                  sizes: {
                    h1: { fontSize: 30 },
                  },
                },
              }}
            >
              <NavigationProgress />
              <App />
            </MantineProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </NotificationsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

{
  /* <AppShell navbar={<NavigateSidebar />} header={<HeaderAction links={[]} />} hidden>
          <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppShell> */
}
