import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const Documentlists = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-document`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};
const AddDocument = async (
  templateName: any,
  templatetype: any,
  image: any,
  template: any,
  status: any
) => {
  let body = JSON.stringify({
    template_name: templateName,
    template_type: templatetype,
    image: image,
    template: template,
    status: status,
  });
  let response = await fetch(
    `${API_URL}/document`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteDocument = async (params: any) => {
  let response = await fetch(
    `${API_URL}/document/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateDocument = async (template: any, imageurl: any, edit_doc: any) => {
  // console.log(edit_dep);

  let body = JSON.stringify({
    template: template,
    image: imageurl,
  });

  let response = await fetch(
    `${API_URL}/document/${edit_doc.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};
const ImageUpload = async (data: any) => {
  console.log("idddd", data);

  // let body = JSON.stringify(data);
  // console.log('body', body);

  let response = await fetch(
    `${API_URL}/upload`,
    CommonMethod.ImagePostMethod(data)
  );
  return await response.json();
};

const GetDocument = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/document/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      console.log(response.data.data.roles);

      return response.data.data.roles;
    });
};

const DocumentService = {
  Documentlists,
  AddDocument,
  DeleteDocument,
  UpdateDocument,
  ImageUpload,
  GetDocument,
};
export default DocumentService;
