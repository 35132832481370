import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Pagination,
  Select,
  Table,
  Loader,
} from "@mantine/core";

import { showNotification } from "@mantine/notifications";
import ApprovalTemplateService from "../../../services/ApprovalTemplateService";
import ApprovalTemplateModal from "../../AllPopUPs/ApprovalTemplateModal";
import ConfirmDialog from "../../Controls/ConfirmDialog";
import NoData from "../../LottieFiles/NoData";

export const ApprovalTemplate = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [docTotal, setDocTotal]: any = useState();
  const [approvalTemplateModal, setApprovalTemplateModal]: any =
    useState(false);
  const [organsizelists, setOrgansizelist]: any = useState("");
  const [approvalTemplatelist, setApprovalTemplatelist]: any = useState([]);
  const [editModal, setEditModal]: any = useState("");
  const [edit_doc, setEdit_doc]: any = useState("");
  const [approvalTemplateName, setApprovalTemplateName]: any = useState();
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setcurrentPage = (value: any) => {
    setPage(value);
    setApprovalTemplatelist([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setApprovalTemplatelist([]);
  };

  const getapprovaltemplatelist = async () => {
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
      include: {
        document: true,
      },
    };
    let data1 = {
      limit: pageSize,
      include: {
        document: true,
      },
      where: {
        certificate_approval_template_name: {
          contains: approvalTemplateName,
          mode: "insensitive",
        },
      },
    };

    setLoading(true);
    try {
      let res = await ApprovalTemplateService.ApprovalTemplatelist(
        approvalTemplateName ? data1 : data
      );
      setApprovalTemplatelist(res.data.certificate_approval_template);
      setDocTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDelete = async (param: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      let res = await ApprovalTemplateService.DeleteApprovalTemplate(param);
      getapprovaltemplatelist();
      setLoading(false);
      showNotification({
        color: "green",
        message: res.message,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdate = async (params: any) => {
    setEditModal(true);
    setEdit_doc(params);
  };

  useEffect(() => {
    getapprovaltemplatelist();
  }, [page, pageSize, approvalTemplateName]);
  return (
    <>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {approvalTemplateModal && (
        <ApprovalTemplateModal
          setDocumentFieldsModal={setApprovalTemplateModal}
          get_documentField_list={getapprovaltemplatelist}
          setEditModal={setEditModal}
          edit_document={edit_doc}
          organsize_list={organsizelists}
          editModal={editModal}
        />
      )}
      <div>
        <Card mt="sm" radius="lg" shadow="lg">
          <Card.Section>
            <Group position="apart" p="md">
              <Box>
                <Autocomplete
                  label="Filter by Name"
                  placeholder="Select TemplateName"
                  data={
                    Array.isArray(approvalTemplatelist)
                      ? approvalTemplatelist.map(
                          (provider) =>
                            `${provider.certificate_approval_template_name}`
                        )
                      : []
                  }
                  onChange={(value) => setApprovalTemplateName(value)}
                  maxDropdownHeight={400}
                  nothingFound="Nobody here"
                />
              </Box>
              <Box>
                <Button
                  size="sm"
                  onClick={() => {
                    setApprovalTemplateModal(true);
                    setEditModal(false);
                  }}
                  style={{ background: "#25204f" }}
                  // size="sm"
                >
                  +Add
                </Button>
              </Box>
            </Group>

            {loading === true ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "50px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            ) : approvalTemplatelist && approvalTemplatelist.length > 0 ? (
              <div>
                <Table verticalSpacing="lg" highlightOnHover>
                  <thead style={{ border: "none" }}>
                    <tr>
                      <th>
                        <Checkbox size="sm" />
                      </th>
                      <th>
                        <div>Template Name </div>
                      </th>
                      <th>
                        <div>Document</div>
                      </th>
                      <th>
                        <div>Status</div>
                      </th>
                      <th>
                        <div>Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {approvalTemplatelist.map(
                      (document: any, index: number) => (
                        <tr
                          key={document.uuid}
                          className={
                            document.status === false ? "in-active-list" : ""
                          }
                        >
                          <td>
                            <Checkbox size="sm" />
                          </td>

                          <td>{document?.document?.template_name}</td>
                          <td>
                            {document.status === true ? "Active" : "In-Active"}{" "}
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                handleUpdate(document);
                                setApprovalTemplateModal(true);
                                setEditModal(true);
                              }}
                              variant="default"
                              radius="md"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Want to Delete?",

                                  onConfirm: () => {
                                    handleDelete(document);
                                  },
                                });
                              }}
                              variant="default"
                              radius="md"
                              ml="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                <Group position="apart" mt="md" p="lg">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box mr="md">Rows per page</Box>
                    <Select
                      onChange={(e) => setrowsperpage(e)}
                      style={{ width: "4rem" }}
                      value={String(pageSize)}
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                    />
                  </Box>
                  <Pagination
                    styles={(theme) => ({
                      item: {
                        "&[data-active]": {
                          backgroundColor: "#25204f",
                        },
                      },
                    })}
                    total={Math.ceil(docTotal / pageSize)}
                    boundaries={5}
                    page={page}
                    initialPage={1}
                    onChange={(e) => setcurrentPage(e)}
                  />
                </Group>
              </div>
            ) : (
              <NoData />
            )}
          </Card.Section>
        </Card>
      </div>
    </>
  );
};
