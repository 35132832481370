import React, { useState, useEffect } from "react";
import { COLORS } from "../../custom";

import ServerSideGrid from "../../components/Tables/ServerSideGrid";
import "./consent req.css";
import Consent_Services from "../../services/ConsentServices";
// import ConsentReqPopup from "../../components/consent pop/requestpop";

export const ConsentPage = () => {
  const [requestlist, setRequestList]: any = useState();

  //table---
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal]: any = useState();
  const [loading, setLoading] = useState(false);
  const [reqlength, setReqLength] = useState<number>(0);
  //table---

  const columnDefsmodule = [
    {
      headerName: "ID",
      field: "id",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
    },
    {
      headerName: "EMPLOYEEID",
      field: "employee_id",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
    },
    {
      headerName: "Reason",
      field: "response",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
    },

    {
      headerName: "Response",
      field: "consent_status",
      cellStyle: { fontSize: "15px", marginLeft: "20px" },
      cellRendererFramework: (params: any) => (
        <span>
          {params.data.consent_status
            ? params.data.consent_status
            : "No response"}
        </span>
      ),
    },
  ];

  const getuserlist = async () => {
    setLoading(true);
    try {
      await Consent_Services.get_All_requests().then((res) => {
        // setTotal(res.total);
        setRequestList(res.data);
        setReqLength(res.data.length);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getuserlist();
  }, []);

  return (
    <>
     
            <div
              className="users-main-page"
              style={{ backgroundColor: COLORS.backgroundContent }}
            >
              <div
                className="users-title"
                style={{ backgroundColor: COLORS.backgroundContent }}
              >
                <p>Consent request List</p>
                <div className="users-title"></div>
              </div>
              <ServerSideGrid
                className="User-list-table"
                rowData={requestlist}
                headerHeight={80}
                columnDefs={columnDefsmodule}
                defaultColDef={{
                  sortable: true,
                  resizable: false,
                  filter: true,
                  flex: 1,
                  minWidth: 100,
                }}
                rowHeight={50}
                handleCellClick={() => { }}
                onRowClicked={() => { }}
                loading={loading}
                disableClickSelectionRenderers={false}
                noDataTxt="Loading ..."
                TableHeight={80}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                // onSelectionChanged={onSelectionChanged}
                pageSize={pageSize}
                totalDataCount={reqlength}
                serverRowSize={pageSize}
                currentPage={page + 1}
                serverSidePagination={true}
                serverPageCount={Math.ceil(reqlength / pageSize)}
                setServerRowSize={(p: number) => {
                  setPageSize(p);
                }}
                setServerSidePage={(p: number) => {
                  setPage(p - 1);
                }}
              />
            </div>
            {/* )} */}
    </>
  );
};
