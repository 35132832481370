import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const service = {
  verifyCertificate: "/verifyCertificate/",
};
const API_URL = API_BASE_URL;

const verifyCertificateService = async (id: any) => {
  let response = await fetch(
    `${API_URL}${service.verifyCertificate}${id}`,
    CommonMethod.GetMethod()
  );
  return response.json();
};
const verifycertificateservicePost = async ({email,otp}:any) => {
  const body = JSON.stringify({email,otp});

  let response = await fetch(
    `${API_URL}/verifyCertificate`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

export {verifycertificateservicePost}
export default verifyCertificateService;
