import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { CertificateSetting } from "./components/certificatesettings/CertificateSetting";
import VerifyCertificate from "./components/verifyCertificate";
import { Layout } from "./layout/layout";
import Loginform from "./pages/Login/Login";
import ResetForgetPassword from "./pages/resetForgetPassword/ResetForgetPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Admin from "./screens/Admins/Admin";
import Authorizer from "./screens/Authorizer/Authorizer";
import CertificateReq from "./screens/certificateRequest/CertificateReq";
import { ConsentPage } from "./screens/consent request/consent req";
import { RequestPendingList } from "./screens/consentReqPending/ReqPending";
import { DashboardPage } from "./screens/Dashboard/Dashboard";
import { DelegatesPage } from "./screens/Delegates/Delegates";
import { DocumentPage } from "./screens/Documents/Document";
import EmpolyeesPage from "./screens/Exemployees/empolyees";
import { FinancialPage } from "./screens/Financials/Financial";
import IssuerPage from "./screens/Issuer/Issuer";
import MyCertificate from "./screens/MyCertificate/MyCertificate";
import UserNotification from "./screens/Notification/ProfileNotification";
import OrganizationSetting from "./screens/OrganizationSettings/Organization";
import OrganizationProfile from "./screens/OrganizationSettings/Profile";
import Profile from "./screens/Profile/profile";
import { ProjectPage } from "./screens/Projects/project";
import RequestInfo from "./screens/RequestInfo/RequestInfo";
import Request from "./screens/RequestPage/Request";
import RolePage from "./screens/RolePage/RolePage";
import Settingcards from "./screens/SettingPage/Settingcard";
import UserRollSetting from "./screens/UserRollSetting/UserRollSetting";
import { Userpage } from "./screens/Users/user";
import CreateCertificate from "./screens/CreateCertificate";
export const ThemeContext = createContext({});
const AppLayout = () => (
  <>
    <Layout>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/certificates" element={<MyCertificate />} />
        <Route path="/certificate-create" element={<CreateCertificate />} />

        <Route path="/delegates" element={<DelegatesPage />} />
        <Route path="/admins" element={<Admin />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/financials" element={<FinancialPage />} />
        <Route path="/users" element={<Userpage />} />
        <Route path="/ex-empolyees" element={<EmpolyeesPage />} />
        <Route path="/documents" element={<DocumentPage />} />
        <Route path="/issuer" element={<IssuerPage />} />
        <Route path="/authorizer" element={<Authorizer />} />
        <Route path="/requestInfo" element={<RequestInfo />} />
        <Route path="/certificateRequest" element={<CertificateReq />} />
        <Route path="/setting" element={<Settingcards />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/organizationprofile" element={<OrganizationProfile />} />
        <Route path="/rolesettings" element={<UserRollSetting />} />
        <Route path="/certificatesettings" element={<CertificateSetting />} />
        <Route path="/designationsettings" element={<OrganizationSetting />} />
        <Route path="/consentRequest" element={<ConsentPage />} />
        <Route path="/consentPendingrequest" element={<RequestPendingList />} />
        <Route path="/roles" element={<RolePage />} />
        <Route path="/ProfileNotification" element={<UserNotification />} />
      </Routes>
    </Layout>
  </>
);
const App = () => {
  const [theme, setTheme]: any = useState("light");
  const userdetails = useSelector((s: any) => s.user.value);
  const isObjectEmpty = (objectName: any) => {
    return JSON.stringify(objectName) === "{}";
  };

  const toggleTheme = () => {
    setTheme((curr: any) => (curr === "light" ? "dark" : "light"));
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {isObjectEmpty(userdetails) ? (
        <Routes>
          <Route path="/certificate/verify/:id" element={<VerifyCertificate />} />
          <Route path="/certificate/verify/" element={<VerifyCertificate />} />
          <Route path="/" element={<Loginform />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/reset/:uuid/:data" element={<ResetForgetPassword />} />
          {/* <Route path="/*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      ) : (
        <Routes>
          <Route path="/certificate/verify/:id" element={<VerifyCertificate />} />
          <Route path="/certificate/verify/" element={<VerifyCertificate />} />
          <Route element={<AppLayout />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/certificates" element={<MyCertificate />} />
            <Route path="/delegates" element={<DelegatesPage />} />
            <Route path="/admins" element={<Admin />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/financials" element={<FinancialPage />} />
            <Route path="/users" element={<Userpage />} />
            <Route path="/ex-empolyees" element={<EmpolyeesPage />} />
            <Route path="/documents" element={<DocumentPage />} />
            <Route path="/issuer" element={<IssuerPage />} />
            <Route path="/authorizer" element={<Authorizer />} />
            <Route path="/requestInfo" element={<RequestInfo />} />

            <Route path="/certificateRequest" element={<CertificateReq />} />
            <Route path="/setting" element={<Settingcards />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/organizationprofile"
              element={<OrganizationProfile />}
            />
            <Route path="/rolesettings" element={<UserRollSetting />} />

            <Route
              path="/certificatesettings"
              element={<CertificateSetting />}
            />
            <Route path="/req" element={<Request />} />
            <Route
              path="/designationsettings"
              element={<OrganizationSetting />}
            />
            <Route path="/consentRequest" element={<ConsentPage />} />
            <Route
              path="/consentPendingrequest"
              element={<RequestPendingList />}
            />
            <Route path="/ProfileNotification" element={<UserNotification />} />

            <Route path="/certificate-create" element={<CreateCertificate />} />

          </Route>
        </Routes>
      )}
    </ThemeContext.Provider>
  );
};

export default App;
