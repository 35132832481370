import axios from "axios";
import { API_BASE_URL } from "../../common/config";
const API_URL = API_BASE_URL;

// const signup = (email, password) => {
//   return axios
//     .post(API_URL + "/signup", {
//       email,
//       password,
//     })
//     .then((response) => {
//       if (response.data.accessToken) {
//         localStorage.setItem("user", JSON.stringify(response.data));
//       }

//       return response.data;
//     });
// };

const login = async ({ email, password }: any) => {
  return await axios
    .post(`${API_URL}/login`, {
      email,
      password,
    })
    .then((response) => {
      if ((response.status = 200)) {
        console.log(response.data.data.user);

        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem("token", JSON.stringify(response.data.data.token));
      }

      return response.data;
    });
};

const sendEmailLink = async ({ email, password }: any) => {
  return await axios
    .post(`${API_URL}/forget`, {
      email,
    })
    .then((response) => {
      if ((response.status = 200)) {
        console.log("responseeeee", response);

        // localStorage.setItem("user", JSON.stringify(response.data.data.user));
        // localStorage.setItem("token", JSON.stringify(response.data.data.token));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const AuthService = {
  //   signup,
  login,
  logout,
  sendEmailLink,
  //   getCurrentUser,
};

export default AuthService;
