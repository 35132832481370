import React from "react";
import "./RequestInfo.css";
import { COLORS } from "../../custom";
import Data from "./RequestinfoData";

const RequestInfo = () => {
  return (
    <>
      
            <div className="Request_main_page">
              <div className="requestBox">
                <div className="request-search">
                  <h3
                    style={{
                      color: COLORS.color,
                    }}
                  >
                    Requests (27)
                  </h3>
                  <input
                    className="searchInput"
                    type="text"
                    placeholder="@ Search...."
                  />
                </div>
                <div className="request-table">
                  <table>
                    <tr>
                      <th>User_ID</th>
                      <th>UserName</th>
                      <th>Date</th>
                      <th>Action</th>
                      <th style={{ whiteSpace: "nowrap" }}>Lorem Opsum</th>
                    </tr>
                    {Data.map((item: any) => (
                      <tr key={item.User_ID}>
                        <td>{item.User_ID}</td>
                        <td>{item.UserName}</td>
                        <td>24-12-22</td>
                        <td style={{ color: "#00A0F7" }}>{item.Application}</td>
                        <td>
                          <select className="Action" id="cars">
                            <option className="option">Action</option>
                            <option className="option">Approve</option>
                            <option className="option">Issue</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                  </table>
                  {/* <table>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                    <th>Country</th>
                  </tr>
                  <tr>
                    <td>Alfreds Futterkiste</td>
                    <td>Maria Anders</td>
                    <td>Germany</td>
                  </tr>
                </table> */}
                </div>
              </div>
              <div className="req-container1">
                <div className="requests">
                  <h3
                    style={{
                      color: COLORS.color,
                    }}
                  >
                    Requests (27)
                  </h3>
                  <div>
                    <div
                      className="profile-detail1"
                      style={{
                        color: COLORS.color,
                      }}
                    >
                      <img
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        alt="img"
                      />
                      <div
                        style={{
                          color: COLORS.color,
                        }}
                      >
                        <p>Alex Nepolea</p>
                        <span>
                          {" "}
                          #6157 Requests Offer Letter & Experience Letter
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rejected-requests">
                  <h3
                    style={{
                      color: COLORS.color,
                    }}
                  >
                    Rejected Requests (7)
                  </h3>
                  <div className="reject-content">
                    <p>23Dec2021 at 2:51</p>
                    <span>
                      #6157 Alex Nepolea rejected approval notification Error
                      No: 45801
                    </span>
                    <p>Reason :</p>
                    <span>
                      The record related to this approval notification was
                      previously updated to a state that cannot be further
                      changed
                    </span>
                  </div>
                  <div className="reject-content">
                    <p>23Dec2021 at 2:51</p>
                    <span>
                      #6157 Alex Nepolea rejected approval notification Error
                      No: 45801
                    </span>
                    <p>Reason :</p>
                    <span>
                      The record related to this approval notification was
                      previously updated to a state that cannot be further
                      changed
                    </span>
                  </div>
                </div>
              </div>
            </div>
         
    </>
  );
};

export default RequestInfo;
