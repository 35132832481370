import { useEffect, useState } from "react";
// import "./CertificateSetting.css";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
} from "@mantine/core";

import { showNotification } from "@mantine/notifications";

import ApprovalLevelService from "../../../services/ApprovalTemplateLevelsServic";
import ApprovalLevelModal from "../../AllPopUPs/ApprovalLevelModal";
import DeleteModal from "../../AllPopUPs/DeleteModal";
import NoData from "../../LottieFiles/NoData";
import ApprovalTemplateService from "../../../services/ApprovalTemplateService";
import { LOADIPHLPAPI } from "dns";

export const ApprovalLevels = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [docTotal, setDocTotal]: any = useState();
  const [approvalLevelsModal, setApprovalLevelModal]: any = useState(false);
  const [organsizelists, setOrgansizelist]: any = useState("");
  const [approvalLevelList, setApprovalLevelList]: any = useState([]);
  const [documentType, setDocumentType]: any = useState([]);
  const [editModal, setEditModal]: any = useState(false);
  const [edit_doc, setEdit_doc]: any = useState("");
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  console.log(approvalLevelList);

  const setcurrentPage = (value: any) => {
    setPage(value);
    setApprovalLevelList([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setApprovalLevelList([]);
  };

  // const getapprovaltemplatelist = async () => {
  //   let skipLimitData = { skip: pageSize * (page - 1), limit: pageSize };
  //   let data = {
  //     skip: pageSize * (page - 1),
  //     limit: pageSize,
  //     include: {
  //       certificate_approval_stage: true,
  //       user_details: true,
  //       certificate_approval_template_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template:
  //         {
  //           select: {
  //             certificate_approval_template_name: true,
  //             id: true,
  //           },
  //         },
  //     },
  //   };

  //   setLoading(true);
  //   try {
  //     let res = await ApprovalLevelService.ApprovalTemplateLevelslist(data);
  //     setApprovalLevelList(res.data.certificate_approval_template_stage);
  //     setDocTotal(res.data.total);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };
  const getapprovaltemplatelist = async () => {
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,

      include: {
        certificate_approval_template_stage_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template:
          {
            where: {
              status: true,
            },
            select: {
              certificate_approval_template: true,
              certificate_approval_template_stage_name: true,
              id: true,
              status: true,
              user_details: true,
              level: true,
              uuid: true,
            },
          },
      },
    };
    // let data1 = {
    //   limit: pageSize,
    //   include: {
    //     certificate_approval_template_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template:
    //       true,

    //     // user_details: true,
    //     // certificate_approval_template_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template:
    //     //   {
    //     //     select: {
    //     //       certificate_approval_template_name: true,
    //     //       id: true,
    //     //     },
    //     //   },
    //   },
    //   // where: {
    //   //   certificate_approval_template_name: {
    //   //     contains: approvalTemplateName,
    //   //     mode: "insensitive",
    //   //   },
    //   // },
    // };

    setLoading(true);
    try {
      let res = await ApprovalTemplateService.ApprovalTemplatelist(data);
      console.log(res.data.certificate_approval_template);

      setApprovalLevelList(res.data.certificate_approval_template);
      setDocTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };
  const handleDelete = async (param: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      let res = await ApprovalLevelService.DeleteApprovalTemplateLevels(param);
      getapprovaltemplatelist();
      setLoading(false);
      showNotification({
        color: "green",
        message: res.message,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdate = async (params: any) => {
    setEditModal(true);
    setEdit_doc(params);
  };

  useEffect(() => {
    getapprovaltemplatelist();
  }, [page, pageSize]);
  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {approvalLevelsModal && (
        <ApprovalLevelModal
          setApprovalLevelModal={setApprovalLevelModal}
          get_approvallevel_list={getapprovaltemplatelist}
          edit_approvalLevel_data={edit_doc}
          editModal={editModal}
        />
      )}
      <div>
        <Card mt="sm" radius="lg" shadow="lg">
          <Card.Section>
            <Group position="right" p="md">
              <Button
                onClick={() => {
                  setApprovalLevelModal(true);
                  setEditModal(false);
                }}
                size="sm"
                style={{ background: "#25204f" }}
              >
                +Add
              </Button>
            </Group>

            {loading === true ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "50px",
                }}
              >
                <Loader color="#25204f" />
              </div>
            ) : approvalLevelList && approvalLevelList.length > 0 ? (
              <div>
                <Table verticalSpacing="lg" highlightOnHover>
                  <thead style={{ border: "none" }}>
                    <tr>
                      <th>
                        <Checkbox size="sm" />
                      </th>
                      <th>
                        <div>Approval Template Name</div>
                      </th>
                      <th>
                        <div>user Name</div>
                      </th>
                      <th>
                        <div>Status</div>
                      </th>
                      <th>
                        <div>Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalLevelList.map(
                      (approvallevel: any, index: number) => (
                        <tr
                          key={approvallevel.uuid}
                          className={
                            approvallevel.status === false
                              ? "in-active-list"
                              : ""
                          }
                        >
                          <td>
                            <Checkbox size="sm" />
                          </td>
                          <td>
                            {approvallevel.certificate_approval_template_name}
                          </td>
                          <td>
                            {
                              approvallevel
                                ?.certificate_approval_template_stage_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template
                                .user_details?.user_detail_name
                            }
                          </td>
                          <td>
                            {approvallevel.status === true
                              ? "Active"
                              : "In-Active"}{" "}
                          </td>

                          <td>
                            <Button
                              onClick={() => {
                                handleUpdate(approvallevel);
                                setApprovalLevelModal(true);
                                setEditModal(true);
                              }}
                              variant="default"
                              radius="md"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Want to Delete?",

                                  onConfirm: () => {
                                    handleDelete(approvallevel);
                                  },
                                });
                              }}
                              variant="default"
                              radius="md"
                              ml="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                <Group position="apart" mt="md" p="lg">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box mr="md">Rows per page</Box>
                    <Select
                      onChange={(e) => setrowsperpage(e)}
                      style={{ width: "4rem" }}
                      value={String(pageSize)}
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                    />
                  </Box>
                  <Pagination
                    styles={(theme) => ({
                      item: {
                        "&[data-active]": {
                          backgroundColor: "#25204f",
                        },
                      },
                    })}
                    total={Math.ceil(docTotal / pageSize)}
                    boundaries={5}
                    page={page}
                    initialPage={1}
                    onChange={(e) => setcurrentPage(e)}
                  />
                </Group>
              </div>
            ) : (
              <div className="loading">
                <NoData />
              </div>
            )}
          </Card.Section>
        </Card>
      </div>
    </>
  );
};
