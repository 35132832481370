import React from "react";
import Input from "../../components/InputBox/Input";
import Table from "../../components/Tables/Table";
import "./Admin.css";
const Admin = () => {
  return (
          <div className="Admin-main">
            <Input />
            <h2 className="title">Project Admin</h2>
            <Table />
          </div>
  );
};

export default Admin;
