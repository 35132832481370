import React, { useEffect, useState } from "react";
// import Switch from "@mui/material/Switch";
import { Group, Modal, Switch, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import ModuleServices from "../../services/ModuleServices";

import { Typography } from "@material-ui/core";
// import { Loader } from "@mantine/core";
import Button from "../Controls/Button";
interface CardPropsType {
  confirmDialog: any;
  setConfirmDialog: any;
}
const DeleteModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const { confirmDialog, setConfirmDialog } = props;
  console.log(confirmDialog);

  return (
    // add module
    <div>
       <Modal
            size="md"
            opened={confirmDialog.isOpen}
            withCloseButton={false}
            onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
            title={confirmDialog.dialogTitle}
                centered
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
            <Typography variant="h6">{confirmDialog.title}</Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <button className="cancel-btn"             
                onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }>
                Cancel
              </button>
              <button
                className="request-btn"
                style={{ background: "#25204f" }}
                onClick={confirmDialog.onConfirm}
                >
                Delete
              </button>
            </div>
          </Modal>


      {/* <Modal
        opened={confirmDialog.isOpen}
        size={300}
        onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        title={confirmDialog.dialogTitle}
        centered
      >
        <Group position="center">
          <div>
            <Typography variant="h6">{confirmDialog.title}</Typography>
            <Typography  variant="subtitle1">
              {confirmDialog.subTitle}
            </Typography>
            <Group mt='lg'>
            <Button
              text="No"
              color="error"
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
              mt='sm'
            />
            <Button
              text="Yes"
              color="success"
              onClick={confirmDialog.onConfirm}
            />
            </Group>
          </div>
        </Group>
      </Modal> */}
    </div>
  );
};

export default DeleteModal;