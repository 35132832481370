import React from "react";
import { COLORS } from "../../custom";
import "./Input.css";

interface CardPropsType {
  Box_title?: string;
  Box_num?: number;
  Box_numColor?: string;
}
const Input: React.FC<CardPropsType> = (props): JSX.Element => {
  const { Box_title, Box_num, Box_numColor } = props;
  return (
    <div className="input_box">
      <input
        className={Box_title ? "input-sm" : "input-lg"}
        type={"date"}
        style={{ color: COLORS.color, background: COLORS.backgroundContent }}
        placeholder="01 Jan 2022 - 31 Jan 2022"
      />
      <input
        className={Box_title ? "input-sm" : "input-lg"}
        type={"text"}
        style={{ color: COLORS.color, background: COLORS.backgroundContent }}
        placeholder="Search by keywords"
      />
      {Box_title ? (
        <div style={{ background: COLORS.backgroundContent }}>
          <p style={{ color: COLORS.color }}>{Box_title}</p>
          <span style={{ color: Box_numColor }}>({Box_num})</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Input;
