import React, { useState, useEffect } from "react";
import { COLORS, Theme } from "../../custom";
import AddDepartmentpop from "../../components/AllPopUPs/AddDepartmentpop";
import "./Organization.css";
import ConfirmDialog from "../../components/Controls/ConfirmDialog";
import DepartmentServices from "../../services/DepartmentService";
import { showNotification } from "@mantine/notifications";
import {
  Box,
  Card,
  Checkbox,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
  Text,
  Button,
  LoadingOverlay,
  Autocomplete,
} from "@mantine/core";
import NoData from "../../components/LottieFiles/NoData";
import DeleteModal from "../../components/AllPopUPs/DeleteModal";
const Department = () => {
  // table.tsx
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [DeptTotal, setDeptTotal]: any = useState();

  const [Departmentpop, setDepartmentpop]: any = useState(false);
  const [organsizelists, setOrgansizelist]: any = useState("");
  const [departmentlists, setDepartmentlists]: any = useState([]);
  const [editpop, setEditpop]: any = useState("");
  const [edit_dep, setEdit_dep]: any = useState("");
  const [visible, setVisible]: any = useState();
  const [departmentName, setDepartmentName]: any = useState("");
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setcurrentPage = (value: any) => {
    setPage(value);
    setDepartmentlists([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setDepartmentlists([]);
  };

  const getdepartmentlists = async () => {
    setVisible(true);
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    let data1 = {
      limit: pageSize,
      where: {
        department_name: {
          contains: departmentName,
          mode: "insensitive",
        },
      },
    };
    setLoading(true);

    try {
      let res = await DepartmentServices.Departmentlists(
        departmentName ? data1 : data
      );
      if (res.status === true) {
        setDepartmentlists(res.data.department);
        setDeptTotal(res.data.total);
        setVisible(false);
        setLoading(false);
      } else if (res.status === false) {
        setVisible(false);
        setLoading(false);
        console.log(res);
      }
    } catch (error) {
      setVisible(false);
      console.log(error);
    }
  };

  const handleDeletedep = async (param: any) => {
    setVisible(true);
    setLoading(true);

    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      let res = await DepartmentServices.DeleteDepartment(param);
      if (res.status === true) {
        getdepartmentlists();
        setVisible(false);
        showNotification({
          color: "green",
          message: res.message,
        });
        setLoading(false);
      } else if (res.status === false) {
        setVisible(false);
        setLoading(false);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      setVisible(false);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdatedep = async (params: any) => {
    setEditpop(true);
    setEdit_dep(params);
  };
  useEffect(() => {
    getdepartmentlists();
  }, [page, pageSize, departmentName]);
  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {Departmentpop && (
        <AddDepartmentpop
          setDepartmentpop={setDepartmentpop}
          get_department_lists={getdepartmentlists}
          setEditpop={setEditpop}
          edit_dep={edit_dep}
          editpop={editpop}
        />
      )}

      <div>
        <div>
          <Card mt="sm" radius="lg" >
            <Card.Section>
              <Group position="apart">
                <Box m="md">
                  <Autocomplete
                    label="Filter by Name"
                    placeholder="Search Department"
                    data={
                      Array.isArray(departmentlists)
                        ? departmentlists.map(
                            (provider) => `${provider.department_name}`
                          )
                        : []
                    }
                    onChange={(value) => setDepartmentName(value)}
                    maxDropdownHeight={400}
                    nothingFound="Nobody here"
                  />
                </Box>
                <Box p="md">
                  <Button
                    onClick={() => {
                      setDepartmentpop(true);
                      setEditpop(false);
                    }}
                    variant="filled"
                    style={{ background: "#25204f" }}
                    size="sm"
                  >
                    + Add 
                  </Button>
                </Box>
              </Group>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "50px",
                  }}
                >
                  <Loader color="#25204f" />
                </div>
              ) : departmentlists.length > 0 ? (
                <div>
                  <Table verticalSpacing="lg" highlightOnHover>
                    <thead >
                      <tr>
                        <th>
                          <Checkbox size="sm" />
                        </th>
                        <th>
                          <div>Name</div>
                        </th>
                        <th>
                          <div>Status</div>
                        </th>
                        <th>
                          <div>Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {departmentlists.map((department: any, index: number) => (
                        <tr
                          key={department?.uuid}
                          className={
                            department?.status === false ? "in-active-list" : ""
                          }
                        >
                          <td>
                            <Checkbox size="sm" />
                          </td>
                          <td>{department?.department_name}</td>
                          <td>
                            {department?.status === true
                              ? "Active"
                              : "In-Active"}{" "}
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                handleUpdatedep(department);
                                setDepartmentpop(true);
                                setEditpop(true);
                              }}
                              variant="default"
                              radius="md"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Want to Delete?",

                                  onConfirm: () => {
                                    handleDeletedep(department);
                                  },
                                });
                              }}
                              variant="default"
                              radius="md"
                              ml="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Group position="apart" mt="md" p="lg">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box mr="md">Rows per page</Box>
                      <Select
                        onChange={(e) => setrowsperpage(e)}
                        style={{ width: "4rem" }}
                        value={String(pageSize)}
                        data={[
                          { value: "5", label: "5" },
                          { value: "10", label: "10" },
                          { value: "15", label: "15" },
                          { value: "20", label: "20" },
                        ]}
                      />
                    </Box>
                    <Pagination
                      styles={(theme) => ({
                        item: {
                          "&[data-active]": {
                            backgroundColor: "#25204f",
                          },
                        },
                      })}
                      color="#25204f"
                      total={Math.ceil(DeptTotal / pageSize)}
                      boundaries={5}
                      page={page}
                      initialPage={1}
                      onChange={(e) => setcurrentPage(e)}
                    />
                  </Group>
                </div>
              ) : (
                <NoData />
              )}
            </Card.Section>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Department;
