import React, { useCallback, useEffect, useState } from "react";
import { html } from "@codemirror/lang-html";
import {
  Button,
  TextInput,
  Switch,
  Modal,
  Group,
  Select,
  FileInput,
  Box,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import CodeMirror from "@uiw/react-codemirror";
import DocumentService from "../../services/DocumentService";
import ViewTemplateModal from "./ViewTemplate";
interface DocumentPropsType {
  setDocumentFieldsModal: any;
  get_documentField_list: any;
  setEditModal: any;
  edit_document: any;
  editModal: any;
  documentType: any;
}
const DocoumentModal: React.FC<DocumentPropsType> = (props): JSX.Element => {
  const {
    setDocumentFieldsModal,
    get_documentField_list,
    edit_document,
    editModal,
    documentType,
  } = props;
  const [popup, setPopup] = useState(true);
  const [Documentname, setDocumentName]: any = useState("");

  const [loading, setLoading]: any = useState(false);
  const [required, setRequired]: any = useState(true);

  const [documentid, setDocumentId]: any = useState();
  const [status, setStatus]: any = useState(false);
  const [htmlString, setHtmlString] = useState("");
  console.log(htmlString, "htmlString");

  // const [file, setFile]: any = useState();
  const [visible, setVisible] = useState(false);
  const [imageurl, setImageurl]: any = useState();
  const [show, setShow] = useState(false);

  const getimage = async (img: any) => {
    if (img) {
      setVisible(true);
      try {
        let formData: any = new FormData();
        formData.append("file", img);
        let res = await DocumentService.ImageUpload(formData);
        if (res.status === true) {
          setImageurl(res.data.imageURL[0].Location);
        }
        if (res.status === false) {
          showNotification({
            color: "violet",
            message: "image upload failed",
          });
        }
        setVisible(false);
      } catch (error) {
        setVisible(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setVisible(false);
      showNotification({
        color: "violet",
        message: "Please upload the file",
      });
    }
  };
  useEffect(() => {
    if (editModal) {
      setDocumentName(edit_document.template_name);
      setHtmlString(edit_document.template);
    }

    setDocumentFieldsModal(popup);
  }, [popup]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setVisible(true);
    if (imageurl) {
      if (!htmlString) {
        setLoading(false);
        setVisible(false);
        showNotification({
          color: "violet",
          message: "Please Enter the Html String",
        });
      } else {
        try {
          let res = await DocumentService.AddDocument(
            Documentname,
            documentid,
            imageurl,
            htmlString,
            required
          );
          if (res.status === true) {
            get_documentField_list();
            setVisible(false);
            setPopup(false);
            showNotification({
              color: "green",
              message: res.message,
            });
          }
        } catch (error) {
          setVisible(false);
          console.log(error);
          showNotification({
            color: "red",
            message: "error",
          });
        }
      }
    } else {
      setLoading(false);
      setVisible(false);
      showNotification({
        color: "violet",
        message: "Please Give the Image",
      });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      let res = await DocumentService.UpdateDocument(
        htmlString,
        imageurl,
        edit_document
      );
      if (res.status === true) {
        get_documentField_list();
        setLoading(false);
        setPopup(false);
        showNotification({
          color: "violet",
          message: res.message,
        });
      }
    } catch (error) {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "error",
      });
    }
  };

  return (
    <>
      {show && (
        <ViewTemplateModal
          settemplatemodal={setShow}
          TemplateString={htmlString}
        />
      )}
      {editModal === false && (
        <Modal
          size={800}
          opened={popup}
          onClose={() => {
            setPopup(false);
          }}
          title="Add Document Template"
          centered
        >
          <LoadingOverlay
            loaderProps={{ color: "#25204f" }}
            visible={visible}
            overlayBlur={0.1}
          />
          <form onSubmit={handleSubmit}>
            <div>
              <TextInput
                value={Documentname}
                required
                withAsterisk
                size="sm"
                mt="sm"
                onChange={(event) => setDocumentName(event.currentTarget.value)}
                label="Document Name"
              />
              <Select
                label="select Document Type"
                placeholder="Document Type"
                required
                withAsterisk
                data={
                  Array.isArray(documentType)
                    ? documentType.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.name,
                        };
                      })
                    : []
                }
                onChange={(value) => {
                  setDocumentId(value);
                }}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
              <FileInput
                required
                withAsterisk
                label="image"
                size="sm"
                mt="sm"
                mb="sm"
                placeholder="document"
                onChange={(img) => getimage(img)}
              />
              <CodeMirror
                value="<><>"
                height="200px"
                extensions={[html()]}
                onChange={(e) => setHtmlString(e)}
              />
            </div>
            <Group position="apart">
              <Switch
                onLabel="true"
                label="Required"
                checked={required}
                size="md"
                mt="sm"
                onChange={(event) => setRequired(event.currentTarget.checked)}
                offLabel="false"
              />

              <Group position="right">
                <Button
                  mt="md"
                  style={{ background: "#25204f" }}
                  loading={loading}
                  onClick={() => setShow(true)}
                >
                  Preview
                </Button>
                <Button
                  type="submit"
                  style={{ background: "#25204f" }}
                  mt="md"
                  loading={loading}
                  // onClick={() => handleSubmit()}
                >
                  Add
                </Button>
              </Group>
            </Group>
          </form>
        </Modal>
      )}
      {editModal === true && (
        <>
          <Modal
            opened={popup}
            size={"70%"}
            onClose={() => {
              setPopup(false);
            }}
            title="Edit Document Template"
            centered
            style={{ height:"600px"}}
          >
            <LoadingOverlay
              loaderProps={{ color: "#25204f" }}
              visible={visible}
              overlayBlur={0.1}
            />

            <div>
              <TextInput
                value={Documentname}
                disabled
                size="sm"
                mt="sm"
                onChange={(event) => setDocumentName(event.currentTarget.value)}
                label="Document Name"
              />
              <FileInput
                label="image"
                size="sm"
                mt="sm"
                mb="sm"
                placeholder="document"
                onChange={(img) => getimage(img)}
              />
              <CodeMirror
                value={htmlString}
                height="250px"
                extensions={[html()]}
                onChange={(e) => setHtmlString(e)}
              />

              {/* <Switch
                onLabel="true"
                label="Required"
                checked={required}
                size="md"
                mt="sm"
                onChange={(event) => setRequired(event.currentTarget.checked)}
                offLabel="false"
              />
              <Switch
                label={required ? "Active" : "In-active"}
                radius="lg"
                onLabel="true"
                offLabel="false"
                size="md"
                mt="sm"
                checked={status}
                onChange={(event) => setStatus(event.currentTarget.checked)}
              /> */}
            </div>

            <Group position="right" mt="lg">
              <Button
                style={{ background: "#25204f" }}
                loading={loading}
                onClick={() => setShow(true)}
              >
                Preview
              </Button>
              <Button
                style={{ background: "#25204f" }}
                loading={loading}
                onClick={() => handleUpdate()}
              >
                Save
              </Button>
            </Group>
          </Modal>
        </>
      )}
    </>
  );
};

export default DocoumentModal;
