import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const Designationlists = async (data: any) => {
  let body = JSON.stringify(data);

  let response = await fetch(
    `${API_URL}/list-designations`,
    CommonMethod.PostMethod(body)
  );

  return response.json();
};
const AddDesignation = async (value: any, checked: any, organsizeId: any) => {
  let body = JSON.stringify({
    organization_id: organsizeId,
    designation_name: value,
    status: checked,
  });
  let response = await fetch(
    `${API_URL}/designations`,
    CommonMethod.PostMethod(body)
  );

  return response.json();
};

const DeleteDesignation = async (params: any) => {
  console.log(params.uuid);

  let response = await fetch(
    `${API_URL}/designations/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateDesignation = async (
  edit: any,
  designation_name: any,
  status: any
) => {
  let body = JSON.stringify({
    designation_name: designation_name,
    status: status,
  });

  let response = await fetch(
    `${API_URL}/designations/${edit.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

const GetDesignation = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/designations/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      console.log(response.data.data.roles);

      return response.data.data.roles;
    });
};

const DesignationServices = {
  Designationlists,
  AddDesignation,
  DeleteDesignation,
  UpdateDesignation,
  GetDesignation,
};
export default DesignationServices;
