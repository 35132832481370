import React, { useEffect, useState } from "react";

import DocumentTypesServices from "../../services/DocoumentTypeService";
import { Button, TextInput, Switch, Modal, Group } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

interface CardPropsType {
  setDocumentModal: any;
  get_document_list: any;
  organsizelist: any;
  setEditModal: any;
  edit_document: any;
  editModal: any;
}
const DocoumentTypeModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const {
    setDocumentModal,
    get_document_list,
    edit_document,
    editModal,
  } = props;
  const [popup, setPopup]: any = useState(true);
  const [checked, setChecked]: any = useState(true);
  const [value, setValue]: any = useState("");
  const [edited, setEdited]: any = useState("");
  const [loading, setLoading]: any = useState(false);



  useEffect(() => {
    if (editModal) {
      setChecked(edit_document.status);
      setEdited(edit_document.name);
    }

    setDocumentModal(popup);
  }, [popup]);

  const handleSubmit = async (e: any) => {
    console.log('ee', e);
    e.preventDefault();
    console.log('ee11', e);
    setLoading(true);
    if (value) {
      try {
        let res = await DocumentTypesServices.AddDocoumentType(value, checked);
        if (res.status === true) {
          get_document_list();
          setLoading(false);
          setPopup(false);
          showNotification({
            color: "violet",
            message: res.message,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please Enter the Department name",
      });
    }
  };

  const handleUpdate = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let res = await DocumentTypesServices.UpdateDocoument(
        edited,
        edit_document,
        checked
      );
      if (res.status === true) {
        get_document_list();
        setLoading(false);
        setPopup(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      }

    } catch (error) {
      // console.log(error);
      setLoading(false);
      showNotification({
        color: "violet",
        message: "error",
      });
    }
  };

  return (
    <>
      {editModal === false && (
        <Modal
          size={500}
          opened={popup}
          onClose={() => {
            setPopup(false);
            setChecked(false);
          }}
          title="Add Document Types"
          centered
        >  
        {/* <Editor/>ss */}
          <form onSubmit={handleSubmit}>
            <div>
              <TextInput
                value={value}
                required
                withAsterisk
                size="md"
                onChange={(event) => setValue(event.currentTarget.value)}
                label="Document name"
              />

              <Switch
                label={checked ? "Active" : "In-active"}
                radius="lg"
                size="sm"
                mt="sm"
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
              />
            </div>
            <Group position="right">
              <Button type="submit" style={{ background: "#25204f" }} loading={loading}
              // onClick={() => handleSubmit()}
              >
                Add
              </Button>
            </Group>
          </form>
        </Modal>
      )}
      {editModal === true && (
        <>
          <Modal
            opened={popup}
            onClose={() => {
              setPopup(false);
              setChecked(false);
            }}
            title="Edit Document Types"
            centered
          >
            <form onSubmit={handleUpdate}>
              <div>
                <TextInput
                  required
                  size="md"
                  value={edited}
                  onChange={(e) => setEdited(e.target.value)}
                  label="Document name"
                />

                <Switch
                  label={checked ? "Active" : "In-active"}
                  radius="lg"
                  size="sm"
                  mt="sm"
                  className="switch"
                  checked={checked}
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                />
              </div>
              <Group position="right" mt="lg">
                <Button type="submit" style={{ background: "#25204f" }} loading={loading}
                // onClick={() => handleUpdate()}
                >
                  Save
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default DocoumentTypeModal;
