import React, { useState, useEffect } from "react";
import AddDesignationPop from "../../components/AllPopUPs/AddDesignationpop";
import "./Organization.css";
import DesignationServices from "../../services/Designationservices";
import { showNotification } from "@mantine/notifications";
import NoData from "../../components/LottieFiles/NoData";

import {
  Box,
  Card,
  Checkbox,
  Group,
  Pagination,
  Select,
  Table,
  Loader,
  Button,
  Autocomplete,
} from "@mantine/core";
import DeleteModal from "../../components/AllPopUPs/DeleteModal";

const Designation = () => {
  // table.tsx

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [DesignTotal, setDesignTotal]: any = useState();

  const [designationpop, setDesignationpop]: any = useState(false);
  const [designationslist, setDesignationslists]: any = useState([]);
  const [deseditpop, setDeseditpop]: any = useState("");
  const [editdata, setEditdata]: any = useState("");
  const [designationName, setDesignationName] = useState("");
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const setcurrentPage = (value: any) => {
    setPage(value);
    setDesignationslists([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setDesignationslists([]);
  };

  const getdesignationlists = async () => {
    setLoading(true);
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    let data1 = {
      limit: pageSize,
      where: {
        designation_name: {
          contains: designationName,
          mode: "insensitive",
        },
      },
    };
    try {
      let res = await DesignationServices.Designationlists(
        designationName ? data1 : data
      );
      setDesignTotal(res.data.total);
      setDesignationslists(res.data.designations);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDelete = async (param: any) => {
    setLoading(true);
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      await DesignationServices.DeleteDesignation(param).then((res) => {
        getdesignationlists();
        setLoading(false);
        showNotification({
          color: "green",
          message: res.message,
        });
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      showNotification({
        color: "red",
        message: "Error While removing",
      });
    }
  };

  const handleUpdatedes = async (params: any) => {
    setDeseditpop(true);
    setEditdata(params);
  };
  useEffect(() => {
    getdesignationlists();
  }, [page, pageSize, designationName]);
  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {designationpop && (
        <AddDesignationPop
          setDesignationpop={setDesignationpop}
          getdesignationlists={getdesignationlists}
          setDeseditpop={setDeseditpop}
          editdata={editdata}
          deseditpop={deseditpop}
        />
      )}
      <Box mt='sm'>
        <div>
          <Card radius="lg" >
            <Card.Section>
              <Group position="right" p="md">
                <Button
                  style={{ background: "#25204f" }}
                  variant="filled"
                  onClick={() => {
                    setDeseditpop(false);
                    setDesignationpop(true);
                  }}
                  size="sm"
                >
                  +Add
                </Button>
              </Group>
              {loading === true ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "50px",
                  }}
                >
                  <Loader color="#25204f" />
                </div>
              ) : designationslist.length > 0 ? (
                <div>
                  <Table verticalSpacing="lg" highlightOnHover>
                    <thead style={{ border: "none" }}>
                      <tr>
                      <th>
                          <div>
                              <Checkbox size="sm" />
                          </div>
                         
                        </th>
                        <th>
                          <div>
                             Name
                          </div>
                         
                        </th>
                        <th>
                        <div>
                            Status
                          </div>
                        </th>
                        <th>
                         <div>
                             Action
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {designationslist && designationslist ? (
                      <tbody>
                        {designationslist?.map(
                          (designation: any, index: number) => (
                            <tr
                              className={
                                designation?.status === false
                                  ? "in-active-list"
                                  : ""
                              }
                              key={designation?.uuid}
                            >
                              <td>
                                <Checkbox
                                  size="sm"
                                />
                              </td>
                              <td>
                                {designation?.designation_name}  
                              </td>
                              <td>
                                {designation?.status === true
                                  ? "Active"
                                  : "In-Active"}{" "}
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    handleUpdatedes(designation);
                                    setDesignationpop(true);
                                    setDeseditpop(true);
                                  }}
                                  variant="default"
                                  radius="md"
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title: "Want to Delete?",

                                      onConfirm: () => {
                                        handleDelete(designation);
                                      },
                                    });
                                  }}
                                  variant="default"
                                  radius="md"
                                  ml="sm"
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    ) : (
                      <>no data</>
                    )}
                    <tfoot></tfoot>
                  </Table>
                  <Group position="apart" mt="md" p="lg">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box mr="md">Rows per page</Box>
                      <Select
                        onChange={(e) => setrowsperpage(e)}
                        style={{ width: "4rem" }}
                        value={String(pageSize)}
                        data={[
                          { value: "5", label: "5" },
                          { value: "10", label: "10" },
                          { value: "15", label: "15" },
                          { value: "20", label: "20" },
                        ]}
                      />
                    </Box>
                    <Pagination
                      styles={(theme) => ({
                        item: {
                          "&[data-active]": {
                            backgroundColor: "#25204f"
                          },
                        },
                      })}
                      total={Math.ceil(DesignTotal / pageSize)}
                      boundaries={5}
                      page={page}
                      initialPage={1}
                      onChange={(e) => setcurrentPage(e)}
                    />
                  </Group>
                </div>
              ) : (
                <NoData />
              )}
            </Card.Section>
          </Card>
        </div>
      </Box>
    </>
  );
};

export default Designation;
