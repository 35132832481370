import React, { useState } from "react";
import "./RejectPop.css";
import { CloseButton, Textarea } from "@mantine/core";
import API_Services from "../../services/CertificateUsersService";
import SuccessPop from "../successPopup/SuccessPop";

interface CardPropsType {
  setIssuePop?: any;
  detail?: any;
  getRequestList?: any;
}
const RejectPop: React.FC<CardPropsType> = (props): JSX.Element => {
  const { detail, getRequestList } = props;
  const [popup, setPopup]: any = useState(true);
  const [rejectMessage, setRejectMessage]: any = useState();
  const [rejectPop, setRejectPop]: any = useState(true);
  const [tost, setTost]: any = useState(false);
  const sendRejectMessage = async () => {
    try {
      await API_Services.RejectCertificate(detail, rejectMessage).then(
        (res) => {
          console.log(res);
          setRejectPop(false);
          setTost(true);
          getRequestList();
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {tost && <SuccessPop message={"Certificate Rejected Succefully"} />}
      {rejectPop && (
        <div
          className={popup ? "active reject-popup-main" : "reject-popup-main"}
        >
          <div className="reject-popup">
            <CloseButton
              className="close"
              title="Close popover"
              size="xl"
              onClick={() => setPopup(false)}
              iconSize={20}
            />
            <p>Rejected With Reason :</p>
            <table>
              <tr>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Document</th>
              </tr>
              <tr>
                <td>ID-45453423</td>
                <td>{detail.document_info[0].toName}</td>
                <td>{detail.template_name}</td>
              </tr>
            </table>
            <div className="Reject-text-area">
              <Textarea
                label="Reson For Rejection"
                onChange={(e) => setRejectMessage(e.target.value)}
                size="md"
              />
            </div>

            <button
              className="reject-popup-send-btn"
              onClick={() => sendRejectMessage()}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RejectPop;
