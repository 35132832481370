import {
  Box,
  Button,
  Card,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import { useEffect, useState } from "react";
import Adduser from "../../components/AddUser/Adduser";
import ConsentReqPopup from "../../components/consent pop/consentrequestpop";
import ConfirmDialog from "../../components/Controls/ConfirmDialog";
import Edituser from "../../components/EditUser/EditUser";
import NoData from "../../components/LottieFiles/NoData";
import { Theme } from "../../custom";
import API_Services from "../../services/CertificateUsersService";
import DeleteModal from "../../components/AllPopUPs/DeleteModal";

import "./user.css";

export const Userpage = () => {
  const [openAdduser, setOpenAdduser]: any = useState(false);
  const [openEdituser, setOpenEdituser]: any = useState(false);
  const [editData, setEditData]: any = useState();
  const [consentpop, setConsentPop]: any = useState(false);
  const [userlist, setUserList]: any = useState([]);
  const [requestdata, setRequestdata]: any = useState("");
  const [usersdata, setUsersData]: any = useState();
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [total, setTotal]: any = useState();
  const [loading, setLoading]: any = useState(false);
  console.log(userlist);

  const getuserlist = async () => {
    let data = {
      include: {
        user_details: {
          include: {
            users: true,
            department: true,
            designations: true,
          },
        },
        user_roles: {
          where: {
            status: true,
          },
          include: {
            roles: true,
          },
        },
      },
      limit: pageSize,
      skip: (page - 1) * pageSize,
    };

    setLoading(true);
    try {
      await API_Services.getUserList(data).then((res) => {
        setTotal(res.data.total);
        setUserList(res.data.users);
        // console.log(res.data.status)
        setLoading(false);
      });
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  };

  const setcurrentPage = (value: any) => {
    setPage(value);
    setUserList([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setUserList([]);
  };

  const handleDelete_user = async (params: any) => {
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      await API_Services.DeleteUser(params).then((res: any) => {
        getuserlist();
        showNotification({
          color: "green",
          message: res.message,
        });
      });
    } catch (error) {
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  useEffect(() => {
    getuserlist();
  }, [page, pageSize]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      /> */}
      {consentpop && (
        <ConsentReqPopup
          setConsentPop={setConsentPop}
          requestdata={requestdata}
          usersdata={usersdata}
          getuserlist={getuserlist}
        />
      )}

      {openAdduser && (
        <Adduser setOpenAdduser={setOpenAdduser} getuserlist={getuserlist} />
      )}
      {openEdituser && (
        <Edituser
          setOpenEdituser={setOpenEdituser}
          editData={editData}
          getuserlist={getuserlist}
        />
      )}

      {!openAdduser && !openEdituser && (
        <div>
          <Card m="lg" radius="lg" shadow="lg">
            <Card.Section style={{ margin: "20px" }}>
              <Card m="lg" radius="lg">
                <Card.Section p="xl" mb="xl">
                  <Group position="apart">
                    <h2
                      style={{
                        color: Theme.primary,
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      {" "}
                      User List{" "}
                    </h2>
                    <Button
                      size="sm"
                      style={{ background: "#25204f" }}
                      onClick={() => {
                        setOpenAdduser(true);
                      }}
                    >
                      + Add
                    </Button>
                  </Group>
                </Card.Section>
              </Card>
              {loading === true ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    margin: "50px",
                  }}
                >
                  <Loader color="#25204f" />
                </div>
              ) : userlist.length > 0 ? (
                <div>
                  <Table
                    style={{ padding: "0px" }}
                    verticalSpacing="md"
                    highlightOnHover
                  >
                    <thead className="text">
                      <tr>
                        <th>
                          <div>User ID</div>
                        </th>
                        <th>
                          <div>Email</div>
                        </th>
                        <th>
                          <div>Designation</div>
                        </th>
                        <th>
                          <div>Department</div>
                        </th>
                        <th>
                          <div>Role</div>
                        </th>
                        <th>
                          <div> Joined Date</div>
                        </th>
                        <th>
                          <div>Status</div>
                        </th>
                        <th>
                          <div>Actions</div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {userlist.map((user: any, index: number) => (
                        <tr
                          className={
                            user?.status === false ? "in-active-list" : ""
                          }
                          key={user.uuid}
                        >
                          <td>
                            {user?.user_details?.user_id
                              ? user?.user_details?.user_id
                              : "_"}
                          </td>
                          <td>{user?.email ? user?.email : "_"}</td>
                          <td>
                            {user?.user_details?.designations?.designation_name
                              ? user?.user_details?.designations
                                  ?.designation_name
                              : "_"}
                          </td>
                          <td>
                            {user?.user_details?.department?.department_name
                              ? user?.user_details?.department?.department_name
                              : "_"}
                          </td>
                          <td>
                            {user?.user_roles[0]?.roles?.role_name
                              ? user?.user_roles[0]?.roles?.role_name
                              : "_"}
                          </td>
                          <td>
                            {user?.user_details?.date_of_joining
                              ? moment(
                                  user?.user_details?.date_of_joining
                                ).format("DD-MM-YYYY")
                              : "_"}
                          </td>
                          <td>
                            {user?.user_details?.status === true
                              ? "Active"
                              : "Inactive"}
                          </td>
                          <td>
                            {user?.status === true ? (
                              <div style={{ display: "flex" }}>
                                <Button
                                  onClick={() => {
                                    setEditData(user);
                                    setOpenEdituser(true);
                                  }}
                                  style={{ borderRadius: "7px" }}
                                  variant="default"
                                  radius="xs"
                                >
                                  edit
                                </Button>
                                <Button
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title: "Want to Delete ?",
                                      onConfirm: () => {
                                        handleDelete_user(user);
                                      },
                                    });
                                  }}
                                  variant="default"
                                  radius="xs"
                                  ml="sm"
                                  style={{ borderRadius: "7px" }}
                                >
                                  delete
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  onClick={() => {
                                    setEditData(user);
                                    setOpenEdituser(true);
                                  }}
                                  variant="default"
                                  radius="xs"
                                  disabled
                                  style={{
                                    backgroundColor: "#ebebeb",
                                    border: "1px solid #ced4da",
                                    borderRadius: "7px",
                                  }}
                                >
                                  edit
                                </Button>

                                <Button
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title: "Want to Delete ?",
                                      onConfirm: () => {
                                        handleDelete_user(user);
                                      },
                                    });
                                  }}
                                  disabled
                                  variant="default"
                                  radius="xs"
                                  ml="sm"
                                  style={{ borderRadius: "7px" }}
                                >
                                  delete
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Group position="apart" mt="md" p="lg">
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box mr="md">Rows per page</Box>
                      <Select
                        onChange={(e) => setrowsperpage(e)}
                        style={{ width: "4rem" }}
                        value={String(pageSize)}
                        data={[
                          { value: "5", label: "5" },
                          { value: "10", label: "10" },
                          { value: "15", label: "15" },
                          { value: "20", label: "20" },
                        ]}
                      />
                    </Box>
                    <Pagination
                      styles={(theme) => ({
                        item: {
                          "&[data-active]": {
                            backgroundColor: "#25204f",
                          },
                        },
                      })}
                      total={Math.ceil(total / pageSize)}
                      boundaries={5}
                      page={page}
                      initialPage={1}
                      onChange={(e) => setcurrentPage(e)}
                    />
                  </Group>
                </div>
              ) : (
                <NoData />
              )}
            </Card.Section>
          </Card>
        </div>
      )}
    </>
  );
};
