import { Loader } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoleCards from "../../components/RolesCards/RoleCards";
import { setToken } from "../../redux/features/TokenSlice";
import { logins } from "../../redux/features/userSlice";
import API_Services from "../../services/CertificateUsersService";

import "./RolePage.css";
const RolePage = () => {
  const dispatch = useDispatch();
  const [userRolls, setUserRolls]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const userdetails = useSelector((s: any) => s.user.value);

  useEffect(() => {
    ListUserRoll();
    // console.log(userdetails.role_id);
  }, []);

  const SwitchRole = async (e: any) => {
    console.log("clickeddd", e);
    if (e.role_id !== userdetails.present_role) {
      setLoading(true);
      try {
        let res: any = await API_Services.SwitchRoles(e.role_id);
        dispatch(logins(res.data.user));
        dispatch(setToken(res.data.token));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setLoading(false);
        if (res.status) {
          showNotification({
            color: "green",
            message: "Role Switched successfully",
          });
        } else {
          showNotification({
            color: "red",
            message: "Something went wrong",
          });
        }
      } catch {
        showNotification({
          color: "red",
          message: "Something went wrong",
        });
      }
    }
  };

  const ListUserRoll = async () => {
    setLoading(true);

    try {
      await API_Services.ListUserRoles().then((res: any) => {
        console.log(res.data?.userRoles);

        setUserRolls(res.data?.userRoles);
        setLoading(false);
        console.log(res.data?.userRoles);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="RolePage-main">
      <h1>My Role</h1>

      <div className="role-box">
        {loading ? (
          <div className="loading">
            <Loader color="#25204f" />
          </div>
        ) : (
          userRolls?.length > 0 &&
          userRolls?.map((e: any) => {
            return (
              <div
                className={
                  userdetails.present_role === e.role_id ? "disabledbutton" : ""
                }
                onClick={() => {
                  SwitchRole(e);
                }}
              >
                <RoleCards
                  icon1_Name="Users"
                  icon1_Color="#25204f"
                  title={e?.roles.role_name}
                  role={e?.roles?.id}
                  text={e?.userRoles?.roles.role_name}
                />
              </div>
            );
          })
        )
        }
      </div>
    </div>
  );
};

export default RolePage;
