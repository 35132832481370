import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/CommonMethod";
const API_URL = API_BASE_URL;

export const getOrganizationProfile = async (props: any) => {
  console.log("orr", props);

  const query = JSON.stringify({
    where: {
      id: props,
    },
    include: {
      business_type: true,
    },
  });

  let respons = await fetch(
    `${API_URL}/list-organization`,
    CommonMethod.PostMethod(query)
  );
  return await respons.json();
};

export const updateOrganizationalProfile = async (id: any, data: any) => {
  console.log("idddd", id, data);

  let body = JSON.stringify(data);
  console.log("body", body);

  let response = await fetch(
    `${API_URL}/organization/${id}`,
    CommonMethod.PutMethod(body)
  );
  return await response.json();
};
