import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

// const Departmentlists = async ( page: any, pageSize: any) => {
//   let body = JSON.stringify({
//     limit: pageSize,
//     skip: page,
//   });
//   let response = await fetch(
//     `${API_URL}/list-department`,
//     CommonMethod.PostMethod(body)
//   );

//   return response.json();
// };

const Departmentlists = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-department`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};
const AddDepartment = async (value: any, status: any, organsizeid: any) => {
  let body = JSON.stringify({
    organization_id: organsizeid,
    department_name: value,
    status: status,
  });
  let response = await fetch(
    `${API_URL}/department`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteDepartment = async (params: any) => {
  let response = await fetch(
    `${API_URL}/department/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateDepartment = async (edited: any, edit_dep: any, checked: any) => {
  // console.log(edit_dep);

  let body = JSON.stringify({
    department_name: edited,
    status: checked,
  });

  let response = await fetch(
    `${API_URL}/department/${edit_dep.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

const GetDepartment = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/department/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      console.log(response.data.data.roles);

      return response.data.data.roles;
    });
};

const DepartmentServices = {
  Departmentlists,
  AddDepartment,
  DeleteDepartment,
  UpdateDepartment,
  GetDepartment,
};
export default DepartmentServices;
