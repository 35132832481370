import { useEffect, useState } from "react";

import "./Dashboard.css";
import { Button, Grid, Loader, useMantineTheme } from "@mantine/core";
import { Card } from "@mantine/core";

import moment from "moment";

import { default as Mycertificateservices } from "../../services/Mycertificateservices";
import CertificateRequestService from "../../services/CertificateRequestsService";
import { useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Notificationservices from "../../services/Notificationservices";
import { Divider } from "@mantine/core";
import ReactApexChart from "react-apexcharts";
import NotifyService from "../../services/NotificationService";
import { Modal } from "@mantine/core";
export const DashboardPage: any = () => {
  const [opened, setOpened] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [BarChartData, setBarChartData] = useState([]);
  const [uploadedItems, setUploadedItems] = useState();
  const [rejectedResponse, setRejectedResponse] = useState([]);
  const [completedResponses, setCompletedResponses] = useState([]);
  const [pendingResponses, setPendingResponses] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const theme = useMantineTheme();
  const [modalData, setModalData] = useState({
    username: "",
    email: "",
    module: "",
    details: "",
    action: "",
    status: "",
    created: "",
  });
  const [activity, setActivity] = useState([]);
  const [data, setData]: any = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [countData, setCountData]: any = useState<object>();
  const navigate = useNavigate();
  const [Notification, setNotification]: any = useState([]);

  const getActivity = async () => {
    try {
      let res = await NotifyService.getNotification();
      setActivity(res?.data?.data?.log);
    } catch (error) {}
  };

  useEffect(() => {
    getActivity();
  }, []);

  useEffect(() => {
    loadData();
    Notificationlists();
    certificateCount();
  }, []);

  const loadData = async () => {
    setTableLoading(true);
    let str: any;
    await Mycertificateservices.listCertificateRequest(
      {
        requested_by: str?.user_details?.id,
        OR: [
          { response_status: "completed" },
          {
            response_status: "rejected",
          },
        ],
      },
      10
    ).then((res) => {
      setData(res.data?.certificate_request);
      setTableLoading(false);
    });
  };

  const certificateCount = async () => {
    await CertificateRequestService.cefrtificateCount().then((res) => {
      let prevData: any = {};
      res?.data?.getCount.map((d: any) => {
        if (d?.response_status === "waiting") {
          prevData.yetToStart = d?._count?.response_status;
        }
        if (d?.response_status === "pending") {
          prevData.pending = d?._count?.response_status;
        }
        if (d?.response_status === "rejected") {
          prevData.rejected = d?._count?.response_status;
        }
        if (d?.response_status === "completed") {
          prevData.completed = d?._count?.response_status;
        }
        if (d?.response_status === "final") {
          prevData.final = d?._count?.response_status;
        }
      });
      setCountData(prevData);
    });
  };

  const Notificationlists = async () => {
    await Notificationservices.Notificationlists().then((res) => {
      setNotification(res.data.notification_templates);
    });
  };

  const getGraphData = async () => {
    let res = await CertificateRequestService.dashboardGraphStatus();
    setGraphData(res?.data?.result);

    const transformedData = res?.data?.result.reduce((acc: any, item: any) => {
      const dateKey = new Date(item.date).toLocaleDateString("en-GB");
      const existingItem = acc.find((entry: any) => entry.name === dateKey);

      if (existingItem) {
        existingItem.completed +=
          item.response_status === "completed" ? item.total : 0;
        existingItem.rejected +=
          item.response_status === "rejected" ? item.total : 0;
        existingItem.pending +=
          item.response_status === "pending" ? item.total : 0;
      } else {
        acc.push({
          name: dateKey,
          completed: item.response_status === "completed" ? item.total : 0,
          rejected: item.response_status === "rejected" ? item.total : 0,
          pending: item.response_status === "pending" ? item.total : 0,
        });
      }

      return acc;
    }, []);

    setBarChartData(transformedData);
    console.log(transformedData, "transformedData");
    const completedResponses = res?.data?.result?.filter(
      (item: any) => item.response_status === "completed"
    );
    const completedCount: any = completedResponses.reduce(
      (total: any, item: any) => total + (item.total || 0),
      0
    );

    const pendingResponses = res?.data?.result?.filter(
      (item: any) => item.response_status === "pending"
    );
    const rejectedResponse = res?.data?.result?.filter(
      (item: any) => item.response_status === "rejected"
    );

    const pendingCount: any = pendingResponses.reduce(
      (total: any, item: any) => total + (item.total || 0),
      0
    );
    const rejectedCount: any = rejectedResponse.reduce(
      (total: any, item: any) => total + (item.total || 0),
      0
    );
    const totalCount: any = res?.data?.result?.reduce(
      (total: any, item: any) => total + (item.total || 0),
      0
    );
    setUploadedItems(totalCount);
    console.log(totalCount, "totalcount");
    setCompletedResponses(completedCount);
    setRejectedResponse(rejectedCount);
    setPendingResponses(pendingCount);
    setGraphLoading(false);
  };

  useEffect(() => {
    setGraphLoading(true);
    getGraphData();
  }, []);
  const [chartData, setChartData] = useState<any>({
    series: [
      {
        name: "Completed",
        data: [],
      },
      {
        name: "Pending",
        data: [],
      },
      {
        name: "Rejected",
        data: [],
      },
    ],

    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: "Certificates",
        align: "left",
      },
      colors: ["#66DA26", " #FF9800", "red"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,

          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
        },
        title: {
          text: "No Of Certificates",
        },

        grid: {
          show: false,
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: any) {
            return val.toFixed(0);
          },
        },
      },
    },
  });
  useEffect(() => {
    const capitalizedSeries = chartData.series.map((series: any) => {
      return {
        ...series,
        name: series.name.charAt(0).toUpperCase() + series.name.slice(1),
      };
    });

    setChartData({
      ...chartData,
      series: capitalizedSeries,
    });
  }, [chartData]);

  useEffect(() => {
    const transformedData = graphData.reduce((acc: any, item: any) => {
      const seriesIndex = acc.findIndex(
        (series: any) => series.name === item.response_status
      );

      if (seriesIndex !== -1) {
        const timestamp = new Date(item.date).getTime();
        const dataPoint = { x: timestamp, y: item.total };

        const existingTimestampIndex = acc[seriesIndex].data.findIndex(
          (dataPoint: any) => dataPoint.x === timestamp
        );

        if (existingTimestampIndex === -1) {
          acc[seriesIndex].data.push(dataPoint);
        } else {
          acc[seriesIndex].data[existingTimestampIndex] = dataPoint;
        }
      } else {
        const newSeries = {
          name: item.response_status,
          data: [{ x: new Date(item.date).getTime(), y: item.total }],
        };

        acc.push(newSeries);
      }

      return acc;
    }, []);

    console.log("Transformed Data:", transformedData);

    setChartData((prevChartData: any) => ({
      ...prevChartData,
      series: transformedData,
    }));
  }, [graphData]);

  const function1223 = (text: any) => {
    return text;
  };

  return (
    <div>
      <Grid gutter="lg">
        <Grid.Col span={12}>
          <Grid gutter="lg">
            <Grid.Col span={8}>
              <div
                style={{
                  backgroundColor: "#fff",
                  flexGrow: "1",
                  padding: "20px",
                  borderRadius: "12px 12px 0px 0px",
                  transition: "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                  // height: "80vh",
                  paddingBottom: "5%",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h6 style={{ fontSize: "20px" }}>Hello, Certify</h6>
                  </div>
                </div>
                <Grid>
                  <Grid.Col>
                    <Divider my="sm" />
                    <Grid>
                      <Grid.Col span={3}>
                        <Card
                          style={{
                            display: "flex",
                            padding: "20px",
                            backgroundColor: "#fff",
                            gap: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                                backgroundColor: "#eff4fa ",
                                borderRadius: "50%",
                                marginBottom: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="#25204F"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                <path d="M7 9l5 -5l5 5" />
                                <path d="M12 4l0 12" />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "17px",
                                padding: "2px",
                                fontWeight: "500",
                              }}
                            >
                              Uploaded
                            </p>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                alignItems: "center",
                              }}
                            >
                              {uploadedItems ?? 0}
                            </div>
                          </div>
                        </Card>
                      </Grid.Col>

                      <Grid.Col span={3}>
                        <Card
                          style={{
                            display: "flex",
                            padding: "20px",
                            backgroundColor: "#fff",
                            gap: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                                backgroundColor: "#eff4fa ",
                                borderRadius: "50%",
                                marginBottom: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="40"
                                viewBox="0 -960 960 960"
                                width="40"
                                fill="#66DA26"
                              >
                                <path d="M400-304 240-464l56-56 104 104 264-264 56 56-320 320Z" />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "17px",
                                padding: "2px",
                                fontWeight: "500",
                              }}
                            >
                              Approved
                            </p>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                alignItems: "center",
                              }}
                            >
                              {completedResponses ?? 0}
                            </div>
                          </div>
                        </Card>
                      </Grid.Col>

                      <Grid.Col span={3}>
                        <Card
                          style={{
                            display: "flex",
                            padding: "20px",
                            backgroundColor: "#fff",
                            gap: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                                backgroundColor: "#eff4fa ",
                                borderRadius: "50%",
                                marginBottom: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="30"
                                viewBox="0 -960 960 960"
                                width="30"
                                fill="#FF9800"
                              >
                                <path d="M160-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T400-790v84q-72 26-116 88.5T240-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H160Zm400-10v-84q72-26 116-88.5T720-482q0-45-17-87.5T650-648l-10-10v98h-80v-240h240v80H690l16 14q49 49 71.5 106.5T800-482q0 111-66.5 197.5T560-170Z" />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "17px",
                                padding: "2px",
                                fontWeight: "500",
                              }}
                            >
                              Awaiting
                            </p>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                alignItems: "center",
                              }}
                            >
                              {pendingResponses ?? 0}
                            </div>
                          </div>
                        </Card>
                      </Grid.Col>
                      <Grid.Col span={3}>
                        <Card
                          style={{
                            display: "flex",
                            padding: "20px",
                            backgroundColor: "#fff",
                            gap: "15px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                                backgroundColor: "#eff4fa ",
                                borderRadius: "50%",
                                marginBottom: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="25"
                                viewBox="0 -960 960 960"
                                width="25"
                                fill="red"
                              >
                                <path d="M240-840h440v520L400-40l-50-50q-7-7-11.5-19t-4.5-23v-14l44-174H120q-32 0-56-24t-24-56v-80q0-7 2-15t4-15l120-282q9-20 30-34t44-14Zm360 80H240L120-480v80h360l-54 220 174-174v-406Zm0 406v-406 406Zm80 34v-80h120v-360H680v-80h200v520H680Z" />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "17px",
                                padding: "2px",
                                fontWeight: "500",
                              }}
                            >
                              Rejected
                            </p>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "10px",
                                alignItems: "center",
                              }}
                            >
                              {rejectedResponse ?? 0}
                            </div>
                          </div>
                        </Card>
                      </Grid.Col>
                    </Grid>
                    <Divider my="sm" />
                  </Grid.Col>
                </Grid>
                {graphLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Loader color="#25204f" />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",

                      padding: "5px",
                    }}
                  >
                    {/* <BarChart
                      width={900}
                      height={300}
                      data={BarChartData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="completed"
                        stackId="a"
                        fill=" #82ca9d"
                        name="Completed"
                      />
                      <Bar
                        dataKey="pending"
                        stackId="a"
                        name="Pending"
                        fill="#FF9800"
                      />
                      <Bar
                        dataKey="rejected"
                        name="Rejected"
                        stackId="a"
                        fill="red"
                      />
                    </BarChart> */}

                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      height={350}
                      type="area"
                      style={{ marginTop: "20px", width: "100%" }}
                    />
                  </div>
                )}

                {/* <div style={{ margin: "20px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h6 style={{ fontSize: "20px", paddingLeft: "10px" }}>
                        Recent Certificates
                      </h6>
                    </div>
                    <div></div>
                  </div>
                  <div
                    className="table-responsive"
                    style={{ marginTop: "30px" }}
                  >
                    <table className="no-border" style={{ lineHeight: "1.9" }}>
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        {data?.map((item: any) => (
                          <>
                            <tr>
                              <td>
                                <div
                                  className="heading_text"
                                  style={{
                                    display: "flex",
                                    gap: "15px",

                                    alignItems: "center",
                                  }}
                                >
                                  <div className="btn2">
                                    <img
                                      style={{ width: "30px" }}
                                      className="img1"
                                      src={require("../../Assets/genpactlogo.png")}
                                      alt="Certify"
                                    />
                                  </div>
                                  <div>
                                    {item?.document?.template_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      item?.document?.template_name.slice(1)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: "15px",
                                  }}
                                >
                                  <div
                                    className="btn2"
                                    style={{
                                      backgroundColor:
                                        item?.response_status === "completed"
                                          ? "green"
                                          : "red",
                                      width: "10px",
                                      height: "10px",
                                    }}
                                  ></div>
                                  {`${item?.response_status
                                    .charAt(0)
                                    .toUpperCase()}${item?.response_status
                                    .slice(1)
                                    .toLowerCase()}`}
                                </div>
                              </td>
                              <td>
                                {moment(item?.created_at).format("MM-DD-YYYY")}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>
            </Grid.Col>

            <Grid.Col
              span={4}
              style={{
                backgroundColor: "#fff",
                height: "80vh",
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "darkgrey lightgrey",
                msOverflowStyle: "none",

                flexGrow: "1",
                padding: "20px",
                marginTop: "10px",
                borderRadius: "12px 12px 12px 12px",
                transition: "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
              }}
            >
              <div>
                <h6 style={{ fontSize: "20px" }}>Activity</h6>
              </div>

              <Divider
                my="xs"
                labelPosition="center"
                style={{ marginTop: "11px" }}
              />

              <section className="" style={{ cursor: "pointer" }}>
                {activity?.map((item: any, index: any) => (
                  <div
                    onClick={() => {
                      setOpened(true);
                      setModalData({
                        username: item?.user_name,
                        email: item?.email,
                        module: item?.module,
                        details: item?.details,
                        action: item?.action,
                        status: item?.status === true ? "true" : "false",
                        created: item?.created_at,
                      });
                    }}
                  >
                    <main className="">
                      <div className="msg left-msg">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></div>
                        <div>
                          <div className="msg-bubble">
                            <div
                              className="msg-info"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    backgroundColor: "#25204f",
                                    borderRadius: "50%",
                                    marginBottom: "0px",
                                    color: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  G
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "300px",
                                }}
                              >
                                <div style={{ padding: "2px" }}>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    {item?.user_name}
                                  </p>
                                  <div
                                    className="msg-text"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Certified By
                                  </div>
                                </div>

                                <div className="msg-info-time">
                                  {item?.created_at &&
                                    new Date(
                                      item.created_at
                                    ).toLocaleDateString()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="msg right-msg">
                        <div className="msg-bubble">
                          <div
                            className="msg-text"
                            style={{ fontSize: "14px" }}
                          >
                            {item?.details}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Divider
                          style={{ marginTop: "20px", width: "330px" }}
                          my="xs"
                          labelPosition="center"
                        />
                      </div>
                    </main>
                  </div>
                ))}
              </section>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Modal
        size={"lg"}
        styles={{
          modal: {
            borderRadius: "20px",
          },
        }}
        withCloseButton={false}
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <h2
          style={{
            paddingBottom: "25px",
            paddingLeft: "25px",
            paddingTop: "10px",
          }}
        >
          Log details
        </h2>

        <Grid style={{ marginTop: "3px" }}>
          <Grid.Col span={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "flex-start",
                fontSize: "14px",
                paddingLeft: "25px",
              }}
            >
              <div>Username</div>
              <div>Email</div>
              <div>Module</div>
              <div>Details</div>
              <div>Action</div>
              <div>Status</div>
              <div>Created</div>
            </div>
          </Grid.Col>
          <Grid.Col span={9}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "flex-start",
                fontSize: "14px",

                width: "auto",
              }}
            >
              <div>{modalData?.username}</div>
              <div>{modalData?.email}</div>
              <div>{modalData?.module}</div>
              <div>{modalData?.details}</div>
              <div>{modalData?.action}</div>
              <div>{modalData?.status}</div>
              <div>
                {modalData?.created &&
                  new Date(modalData?.created).toLocaleDateString()}
              </div>
            </div>
          </Grid.Col>
        </Grid>
        <div
          style={{
            display: "flex",

            justifyContent: "flex-end",
            paddingBottom: "25px",
            paddingTop: "10px",
            paddingRight: "15px",
          }}
          onClick={() => setOpened(false)}
        >
          <Button style={{ backgroundColor: "lightgray", color: "black" }}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};
