import axios from "axios";
import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/Common";
const API_URL = API_BASE_URL;

const DocoumentFieldslist = async (data: any) => {
  let response = await fetch(
    `${API_URL}/list-document-fields`,
    CommonMethod.PostMethod(JSON.stringify(data))
  );

  return response.json();
};
const DocoumentTypeslist = async () => {
  let body = JSON.stringify({
    select: {
      status: true,
      uuid: true,
      id: true,
      name: true,
    },
  });
  let response = await fetch(
    `${API_URL}/list-document-type`,
    CommonMethod.PostMethod(body)
  );

  return response.json();
};
const AddDocoumentField = async (
  documentid: any,
  value: any,
  fieldtype: any,
  fieldlabel: any,
  required: any,
  status: any
) => {
  let body = JSON.stringify({
    document_type_id: documentid,
    field_name: value,
    field_type: fieldtype,
    field_label: fieldlabel,
    is_required: required,
    status: status,
  });
  let response = await fetch(
    `${API_URL}/document-fields`,
    CommonMethod.PostMethod(body)
  );
  return await response.json();
};

const DeleteDocoumentFiled = async (params: any) => {
  let response = await fetch(
    `${API_URL}/document-fields/${params.uuid}`,
    CommonMethod.DeleteMethod()
  );

  return response.json();
};

const UpdateDocoumentFiled = async (
  fieldname: any,
  fieldLabel: any,
  fieldType: any,
  edit_feilds: any,
  checked: any
) => {
  // console.log(edit_dep);

  let body = JSON.stringify({
    field_name: fieldname,
    field_type: fieldType,
    field_label: fieldLabel,
    status: checked,
  });

  let response = await fetch(
    `${API_URL}/document-fields/${edit_feilds.uuid}`,
    CommonMethod.PutMethod(body)
  );

  return response.json();
};

const GetDocoumentFiled = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
  };
  return await axios
    .get(
      `${API_URL}/document-fields/${"6e384b94-26be-4bf0-93e5-1fa96bd6c7fb"}`,
      config
    )
    .then((response) => {
      return response.data.data.roles;
    });
};

const DocumentFieldsServices = {
  DocoumentFieldslist,
  DocoumentTypeslist,
  AddDocoumentField,
  DeleteDocoumentFiled,
  UpdateDocoumentFiled,
  GetDocoumentFiled,
};
export default DocumentFieldsServices;
