import React, { useEffect, useState } from "react";

import {
  Group,
  Button,
  TextInput,
  Switch,
  Modal,
} from "@mantine/core";
import DesignationServices from "../../services/Designationservices";
import { showNotification } from "@mantine/notifications";

interface CardPropsType {
  setDeseditpop: any;
  editdata: any;
  deseditpop: any;
  setDesignationpop: any;
  getdesignationlists: any;
}
const AddDesignationPop: React.FC<CardPropsType> = (props): JSX.Element => {
  const {
    setDesignationpop,
    getdesignationlists,
    setDeseditpop,
    editdata,
    deseditpop,
  } = props;
  const [popup, setPopup]: any = useState(true);
  const [checked, setChecked]: any = useState(true);
  const [value, setValue]: any = useState("");
  const [designationname, setDesignationname]: any = useState("");
  const [loading, setLoading]: any = useState(false);

  let data = localStorage.getItem("user");
  let organizationId: any;
  if (data) {
    organizationId = JSON.parse(data);
    organizationId = organizationId?.user_details.organization_id;
  }
  const handleSubmit = async () => {
    setLoading(true);
    if (value) {
      try {
        await DesignationServices.AddDesignation(
          value,
          checked,
          organizationId
        ).then((res) => {
          getdesignationlists();
          setLoading(false);
          setPopup(false);
          showNotification({
            color: "green",
            message: res.message,
          });
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      showNotification({
        color: "red",
        message: "please Enter Designation name",
      });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await DesignationServices.UpdateDesignation(
        editdata,
        designationname,
        checked
      ).then((res) => {
        getdesignationlists();
        setLoading(false);
        setDeseditpop(false);
        setDesignationpop(false);
        showNotification({
          color: "violet",
          message: res.message,
        });
      });
    } catch (error) {
      // console.log(error);
      showNotification({
        color: "violet",
        message: "error",
      });
    }
  };

  useEffect(() => {
    if (editdata) {
      setChecked(editdata.status);
      setDesignationname(editdata.designation_name);
    }
    setDesignationpop(popup);
  }, [popup]);

  return (
    <>
      {deseditpop === false && (
        <Modal
          opened={popup}
          onClose={() => setPopup(false)}
          title="Add designation"
          centered
        >

          <div className="pop-body">
            <TextInput
              value={value}
              size="md"
              onChange={(event) => setValue(event.currentTarget.value)}
              label="Designation name"
            />

            <Switch
              label={checked ? "Active" : "In-active"}
              radius="lg"
              size="sm"
              className="switch"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button loading={loading}   style={{ background: "#25204f" }} onClick={() => handleSubmit()}>
              Add
            </Button>
          </Group>
        </Modal>
      )}
      {deseditpop === true && (
        <Modal
          opened={popup}
          onClose={() => setPopup(false)}
          title="Edit Designation"
          centered
        >
          <div>
            <TextInput
              size="md"
              mb='sm'
              value={designationname}
              onChange={(e) => setDesignationname(e.target.value)}
              label="Designation name"
            />

            <Switch
              label={checked ? "Active" : "In-active"}
              radius="lg"
              size="sm"
              className="switch"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button
              loading={loading}
              style={{ background: "#25204f" }}
              onClick={() => handleUpdate()}
            >
              Save
            </Button>
          </Group>
        </Modal>
      )}
    </>
  );
};

export default AddDesignationPop;
