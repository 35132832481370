import React, { useEffect, useState } from "react";

import {
  Button,
  CloseButton,
  Group,
  Modal,
  Switch,
  TextInput,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import RoleServices from "../../services/RoleSevices";


interface CardPropsType {
  setEditpop: any;
  editdata: any;
  setRolePop: any;
  editpop: any;
  getRolelists: any;
}
const AddRolePop: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setRolePop, getRolelists, setEditpop, editdata, editpop } = props;
  const [popup, setPopup]: any = useState(true);
  const [checked, setChecked]: any = useState(true);
  const [value, setValue]: any = useState("");
  const [edited, setEdited]: any = useState();
  const [loading, setLoading]: any = useState(false);
  // const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  //   console.log(checked);

  const handleSubmit = async () => {
    setLoading(true);
    if (value) {
      try {
        await RoleServices.AddRole(value, checked).then((res) => {
          if (res.status === true) {
            getRolelists();
            setLoading(false);
            setPopup(false);
            showNotification({
              color: "violet",
              message: res.message,
            });
          }
        });
      } catch (error) {
        setLoading(false);
        showNotification({
          color: "violet",
          message: "Somthing went wrong",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "please enter the role name ",
      });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (edited) {
      try {
        await RoleServices.Updaterole(editdata.uuid, edited, checked).then(
          (res) => {
            if (res.status === true) {
              getRolelists();
              setLoading(false);
              setEditpop(false);
              setPopup(false);
              showNotification({
                color: "violet",
                message: "Role updated successfully",
              });
            }
          }
        );
      } catch (error) {
        setEditpop(false);
        setPopup(false);
        setLoading(false);
        showNotification({
          color: "violet",
          message: "Something Went Wrong",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please Update the Role",
      });
    }
  };

  useEffect(() => {
    if (editdata) {
      setChecked(editdata.status);
      setEdited(editdata.role_name);
    }

    setRolePop(popup);
  }, [popup]);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      {editpop === false && (
        <Modal
          size={500}
          opened={popup}
          onClose={() => {
            setPopup(false);
            setChecked(false);
          }}
          title="Add Roles"
          centered
        >
          <div>
            <TextInput
              value={value}
              size="md"
              onChange={(event) => setValue(event.currentTarget.value)}
              label="Role name"
            />

            <Switch
              label={checked ? "Active" : "In-active"}
              radius="lg"
              mt="sm"
              size="sm"
              className="switch"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button style={{ background: "#25204f" }} loading={loading} onClick={() => handleSubmit()}>
              Add
            </Button>
          </Group>
        </Modal>
      )}
      {editpop === true && (
        <Modal
          size={500}
          opened={popup}
          onClose={() => {
            setPopup(false);
            setChecked(false);
          }}
          title="Edit Roles"
          centered
        >
          <div className="pop-body">
            <TextInput
              size="md"
              value={edited}
              onChange={(e) => setEdited(e.target.value)}
              label="Role name"
            />

            <Switch
              label={checked ? "Active" : "In-active"}
              radius="lg"
              mt="sm"
              size="sm"
              className="switch"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button 
            style={{ background: "#25204f" }}
              loading={loading}

              onClick={() => handleUpdate()}
            >
              Save
            </Button>
          </Group>
        </Modal>
      )}
    </>
  );
};

export default AddRolePop;
