import React from "react";
import "./Delegates.css";
import Input from "../../components/InputBox/Input";
import Table from "../../components/Tables/Table";

export const DelegatesPage = () => {
  return (
    <div className="DelegatesPage-main">
      <Input Box_title="Delegates" Box_num={12} Box_numColor="#E96201" />
      <h2 className="title">Delegates</h2>
      <Table />
    </div>
  );
};
