import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { backdropClasses, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { COLORS } from "../../custom";
import {
  CloseButton,
  Button,
  TextInput,
  Switch,
  Modal,
  Group,
} from "@mantine/core";
import DepartmentServices from "../../services/DepartmentService";
import { showNotification } from "@mantine/notifications";
interface CardPropsType {
  setDepartmentpop: any;
  get_department_lists: any;
  setEditpop: any;
  edit_dep: any;
  editpop: any;
}
const AddDepartmentpop: React.FC<CardPropsType> = (props): JSX.Element => {
  const {
    setDepartmentpop,
    get_department_lists,
    edit_dep,
    editpop,
  } = props;
  const [popup, setPopup]: any = useState(true);
  const [checked, setChecked]: any = useState(true);

  const [value, setValue]: any = useState("");
  const [edited, setEdited]: any = useState("");
  const [loading, setLoading]: any = useState(false);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };
  console.log(checked, "check");

  useEffect(() => {
    if (editpop) {
      setChecked(edit_dep.status);
      setEdited(edit_dep.department_name);
    }

    setDepartmentpop(popup);
  }, [popup]);
  let data = localStorage.getItem("user");
  let organizationId: any;
  if (data) {
    organizationId = JSON.parse(data);
    organizationId = organizationId?.user_details.organization_id;
  }
  const handleSubmit = async () => {
    setLoading(true);
    if (value) {
        console.log(value);
        
      try {
        let res = await DepartmentServices.AddDepartment(value, checked,organizationId);
        if(res.status===true){
          get_department_lists();
          setLoading(false);
          setPopup(false);
          showNotification({
            color: "violet",
            message: res.message,
          });
        }
          if(res.status===false){
            showNotification({
              color: "violet",
              message: "error",
            });
            console.log(res);
          }
      } catch (error) {
        setLoading(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please Enter the Department name",
      });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      let res = await DepartmentServices.UpdateDepartment(
        edited,
        edit_dep,
        checked
      );
      get_department_lists();
      setLoading(false);
      setPopup(false);

      showNotification({
        color: "green",
        message: res.message,
      });
    } catch (error) {
      // console.log(error);
      setLoading(false);
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      {editpop === false && (
        <Modal
          size={500}
          opened={popup}
          onClose={() => {
            setPopup(false);
          }}
          title="Add Department"
          centered
        >
          <div>
            <TextInput
              value={value}
              size="md"
              onChange={(event) => setValue(event.currentTarget.value)}
              label="Department name"
            />

            <Switch
              label={checked ? "Active" : "In-active"}
              radius="lg"
              size="sm"
              mt="sm"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button loading={loading}   style={{ background: "#25204f" }} onClick={() => handleSubmit()}>
              Add
            </Button>
          </Group>
        </Modal>
      )}
      {editpop === true && (
        <>
          <Modal
            opened={popup}
            onClose={() => {
              setPopup(false);
            }}
            title="Edit Department"
            centered
          >
            <div>
              <TextInput
                size="md"
                value={edited}
                onChange={(e) => setEdited(e.target.value)}
                label="Department name"
              />

              <Switch
                label={checked ? "Active" : "In-active"}
                radius="lg"
                size="sm"
                mt="sm"
                className="switch"
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
              />
            </div>
            <Group position="right" mt="lg">
              <Button loading={loading}   style={{ background: "#25204f" }} onClick={() => handleUpdate()}>
                Save
              </Button>
            </Group>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddDepartmentpop;
