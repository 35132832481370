/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import ReactResizeDetector from "react-resize-detector";
import classnames from "classnames";
import _ from "lodash";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { Pagination } from "@mantine/core";
import { Box, LinearProgress, MenuItem, Select, styled } from "@mui/material";

const TableStyles = {
  tableStyles: {
    headerBgColor: "",
    headerTxtColor: "#373A46",
    rowBgColor: "rgba(255,255,255,1)",
    rowTxtColor: "rgba(0,0,0,0.87)",
    rowSeparatorColor: "rgba(226,226,226,1)",
  },
  scrollBar: {
    trackColor: "rgba(255, 255, 255, 0)",
    thumbColor: "rgba(213, 213, 220, 1)",
  },
};
const TABLE_HEADER_HEIGHT = 30;
const defaultPagination = [5, 10, 20, 30, 40, 50, 100];
interface Iprops extends AgGridReactProps {
  handleCellClick: any;
  serverPageCount?: number;
  serverRowSize?: number;
  currentPage: number;
  setServerRowSize?: any;
  serverSidePagination: boolean;
  setServerSidePage: any;
  totalDataCount: number;
  loading: boolean;
  disableClickSelectionRenderers: boolean;
  noDataTxt: string;
  TableHeight: number;
  pageSize: number;
  // eslint-disable-next-line react/require-default-props
  pageginationCount?: number[];
  columnDefs: any;
  rowData: any;
  onRowClicked: any;
  defaultColDef: any;
  rowHeight: any;
  headerHeight: any;
  rowSelection: string;
}

const StyledBox = styled(Box)((theme: any) => ({
  position: "absolute",
  top: TABLE_HEADER_HEIGHT,
  paddingTop: "16px",
  width: "100%",
  height: "100%",
  textAlign: "center",
  background: "#ffffff",
}));

const GridWrapper = styled(Box)((theme: any) => ({
  // position: 'relative',
  // height: '100VH',

  "&.ag-theme-material": {
    "& ::-webkit-scrollbar, ::-webkit-scrollbar-track": {
      width: "10px",
      height: "10px",
      "-webkit-appearance": "none",
      backgroundColor: _.get(
        TableStyles,
        "scrollBar.trackColor",
        "rgba(255, 255, 255, 0)"
      ), // 'transparent'
    },
    "& ::-webkit-scrollbar-thumb": {
      backgroundColor: _.get(
        TableStyles,
        "scrollBar.thumbColor",
        "rgba(213, 213, 220, 1)"
      ),
      // theme.palette.almostBlack[400],
      height: "80px",
      borderRadius: "5px",
    },
  },

  "& .ag-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "Capitalize",
    background: "#ffffff", //change bg
    letterSpacing: "1px",
    height: "100px",
    fontSize: "24px",
    // background: _.get(TableStyles, 'tableStyles.headerBgColor'),
    border: 0,
    "& .ag-header-cell-label": {
      justifyContent: "center",
    },
    "& .ag-pinned-left-header": {
      cursor: "not-allowed",
      // backgroundColor: theme.palette.grey[500],
    },
  },
  "&.ag-theme-material .ag-header-cell": {
    color: _.get(TableStyles, "tableStyles.headerTxtColor"),
  },

  "&.ag-theme-material .ag-cell": {
    fontSize: "15px",
    paddingRight: "0px",
    paddingLeft: "1px",
    "& .ag-react-container": {
      height: "100%",
      "& div": {
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        oveflow: "visible",
      },
    },
  },

  // '&.ag-theme-material .ag-cell-focus': {
  //   border: 'none !important',
  // },
  // '&.ag-theme-material .ag-cell-not-inline-editing': {
  //   border: 'none',
  // },
  "&.ag-theme-material .ag-row": {
    // background: _.get(TableStyles, 'tableStyles.rowBgColor'),
    background: "#ffffff", //change bg
    border: "none",
    color: _.get(TableStyles, "tableStyles.rowTxtColor"),
    borderColor: _.get(TableStyles, "tableStyles.rowSeparatorColor"),
    cursor: "pointer",
    "& .ag-cell-last-left-pinned": {
      cursor: "not-allowed",
      // backgroundColor: theme.palette.grey[500],
    },
  },
  "&.ag-theme-material .ag-row:hover": {
    backgroundColor: "rgba(250,250,251,1)",
  },
}));

function CustomGrid(props: Iprops) {
  const {
    columnDefs,
    rowData,
    onRowClicked,
    setServerRowSize,
    serverRowSize,
    TableHeight,
    defaultColDef,
    handleCellClick,
    currentPage,
    loading,
    serverPageCount,
    rowHeight,
    noDataTxt,
    children,
    setServerSidePage,
    serverSidePagination,
    totalDataCount,
    pageginationCount,
    ...rest
  } = props;

  const gridOptions: any = useRef();
  const gridref: any = useRef();
  const [gridApi, setGridApi]: any = useState(null);
  const [rowSize, setRowSize]: any = useState(10);
  const [page, setCurrentpage] = useState(0);
  const [totalPageSize, setTotalPageSize]: any = useState(4);


  const setOrUnsetResizeColsToFit = useCallback(
    (sizeColumns: any, columnApi: any) => {
      const allColumnIds: any = [];
      columnApi.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.getId());
      });
      if (!sizeColumns) columnApi.autoSizeAllColumns(allColumnIds, false);
      else if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    },
    [gridApi]
  );

  const onColumnResized = useCallback((params: any) => {}, []);
  const updateRowData = useCallback(
    (columnApi: any) => {
      gridApi?.setRowData(rowData);
      setOrUnsetResizeColsToFit(true, columnApi);
    },
    [setOrUnsetResizeColsToFit, gridApi, rowData]
  );

  useEffect(() => {
    if (_.get(gridOptions, "current.api")) {
      updateRowData(gridOptions.current.columnApi);
    }
  }, [rowData, updateRowData]);

  const [currentWidth, setCurrentWidth] = useState(null);

  useEffect(() => {
    if (_.get(gridOptions, "current.api")) {
      const gridColApi = gridOptions.current.columnApi;
      gridApi.setColumnDefs(columnDefs);
      gridApi.resetRowHeights();

      setOrUnsetResizeColsToFit(true, gridColApi);
    }
  }, [columnDefs, setOrUnsetResizeColsToFit, currentWidth, gridApi]);

  const onResizeLayout = (width: any) => {
    setCurrentWidth(width);
  };
  const onGridReady = useCallback(
    (params: any) => {
      gridOptions.current = params;
      setGridApi(params.api);
      setOrUnsetResizeColsToFit(true, params.columnApi);
    },
    [setOrUnsetResizeColsToFit]
  );
  // const classes = useStyles({
  //   tableStyles: {
  //     headerBgColor: 'rgba(158,156,156,0.08)',
  //     headerTxtColor: 'rgba(68,68,79,1)',
  //     rowBgColor: 'rgba(255,255,255,1)',
  //     rowTxtColor: 'rgba(0,0,0,0.87)',
  //     rowSeparatorColor: 'rgba(226,226,226,1)',
  //   },
  //   scrollBar: {
  //     trackColor: 'rgba(255, 255, 255, 0)',
  //     thumbColor: 'rgba(213, 213, 220, 1)',
  //   },
  // });

  const onCellClicked = (e: any) => {
    if (!e.column.colDef.disableClickSelection) {
      handleCellClick && handleCellClick(e);
    } else {
      e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
    }
  };
  const onPageSizeChanged = (value: any) => {
    gridref.current.api.paginationSetPageSize(Number(value));
  };

  const gridStyle = useMemo(
    () => ({ height: `${TableHeight}vh`, width: "100%" }),
    [TableHeight]
  );
  const onPaginationChanged = useCallback(() => {
    // Workaround for bug in events order
    if (gridref.current.api) {
      setTotalPageSize(gridref.current.api.paginationGetTotalPages());
    }
  }, []);

  const setPage = (event: any, page: number) => {
    setCurrentpage(page);

    if (gridref.current.api) {
      gridref.current.api.paginationGoToPage(page - 1);
    }
  };
  return (
    <GridWrapper className={classnames("ag-theme-material")}>
      {loading && <LinearProgress />}
      <Box style={gridStyle}>
        <AgGridReact
          ref={gridref}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableCellChangeFlash
          rowData={rowData}
          animateRows
          onGridReady={onGridReady}
          onColumnResized={onColumnResized}
          onRowClicked={onRowClicked && onRowClicked}
          onCellClicked={onCellClicked}
          rowHeight={rowHeight || 50}
          paginationPageSize={10}
          suppressPaginationPanel
          pagination={true}
          overlayLoadingTemplate={
            '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
          }
          overlayNoRowsTemplate={noDataTxt || undefined}
          onPaginationChanged={onPaginationChanged}
          suppressDragLeaveHidesColumns
          {...rest}
        >
          {children}
        </AgGridReact>
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "center",
          margin: "25px 20px",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box sx={{ mr: 1 }}>Rows per page</Box>
          <Select
            variant="standard"
            disabled={loading}
            value={serverSidePagination ? serverRowSize : rowSize}
            onChange={(e) => {
              !serverSidePagination
                ? setRowSize(Number(e.target.value))
                : setServerRowSize(e.target.value);
              // onPageSizeChanged(e.target.value);
            }}
          >
            {_.map(pageginationCount || defaultPagination, (value, idx) => (
              <MenuItem key={idx} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Pagination
          total={serverSidePagination ? serverPageCount : totalPageSize}
          page={!serverSidePagination ? page : currentPage}
          onChange={!serverSidePagination ? setPage : setServerSidePage}
          radius="sm"
        />
      </Box>

      <ReactResizeDetector handleWidth onResize={onResizeLayout} />
    </GridWrapper>
  );
}

export default CustomGrid;
