import { API_BASE_URL } from "../common/config";
const API_URL = API_BASE_URL;

const get_All_requests = async () => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
    // body: JSON.stringify({
    //   limit: pageSize,
    //   page_number: page,
    // }),
  };
  return await fetch(`${API_URL}/consent-request/getall`, config)
    .then((res) => res.json())
    .then((res) => {
      console.log(res.data);
      return res.data;
    });
};

const getpendinglists = async () => {
  let token: any = localStorage.getItem("token");

  const config = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
    // body: JSON.stringify({
    //   limit: pageSize,
    //   page_number: page,
    // }),
  };
  return await fetch(`${API_URL}/consent-request-by-employee-id`, config)
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      return res.data;
    });
};

// const getSeveralrequests = async () => {
//   let token: any = localStorage.getItem("token");
//   const uuid = "4d6593f9-4baf-4443-90db-60bb9e899988";

//   const config = {
//     method: "GET",
//     headers: {
//       "Content-type": "application/json",
//       Authorization: JSON.parse(token),
//     },
//     // body: JSON.stringify({
//     //   limit: pageSize,
//     //   page_number: page,
//     // }),
//   };
//   return await fetch(
//     `${API_URL}/consent-request/getseveral/${uuid}`,
//     config
//   )
//     .then((res) => res.json())
//     .then((res) => {
//       console.log(res);
//       return res.data;
//     });
// };
const Update_pendingreq = async (params: any) => {
  // console.log(edit_dep);
  let token: any = localStorage.getItem("token");
  const config = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
    body: JSON.stringify({
      consent_status: "approved",
    }),
  };
  return await fetch(`${API_URL}/consent-request/${params}`, config)
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};
const Reject_request = async (params: any) => {
  // console.log(edit_dep);
  let token: any = localStorage.getItem("token");
  const config = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
    body: JSON.stringify({
      consent_status: "rejected",
    }),
  };
  return await fetch(`${API_URL}/consent-request/${params}`, config)
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};

const addConsentRequest = async (payload: any) => {
  let token: any = localStorage.getItem("token");
  const config = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: JSON.parse(token),
    },
    body: JSON.stringify(payload),
  };
  return await fetch(`${API_URL}/consent-request/create`, config)
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};

const Consent_Services = {
  addConsentRequest,
  get_All_requests,
  getpendinglists,
  Update_pendingreq,
  Reject_request,
  // getSeveralrequests,
};
export default Consent_Services;
