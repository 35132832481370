import { API_BASE_URL } from "../common/config";
import CommonMethod from "../utils/CommonMethod";
const API_URL = API_BASE_URL;

const getCertificate = async (skip: number, limit: number) => {
  const query = JSON.stringify({
    include: {
      document: {
        select: {
          template_name: true,
          template_type: true,
          id: true,
        },
      },
      certificate: true,
      users_certificate_request_requested_byTousers: {
        select: {
          first_name: true,
          present_role: true,
        },
      },
      users_certificate_request_issued_byTousers: {
        select: {
          first_name: true,
          present_role: true,
        },
      },
      users_certificate_request_rejected_byTousers: {
        select: {
          first_name: true,
          present_role: true,
        },
      },
      users_certificate_request_approved_byTousers: true,
    },
    where: {
      OR: [{ response_status: "waiting" }, { response_status: "final" }],
    },
    skip: skip,
    limit: limit,
  });

  let respons = await fetch(
    `${API_URL}/list-certificate-request`,
    CommonMethod.PostMethod(query)
  );
  return await respons.json();
};

const cefrtificateCount = async () => {
  let respons = await fetch(
    `${API_URL}/get-requests-count`,
    CommonMethod.GetMethod()
  );
  return await respons.json();
};
const dashboardGraphStatus = async () => {
  let respons = await fetch(`${API_URL}/dashboard`, CommonMethod.GetMethod());
  return await respons.json();
};

const certificateProcess = async (id: any) => {
  let body = JSON.stringify({ certificate_request_id: id });
  let respons = await fetch(
    `${API_URL}/process-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await respons.json();
};
const certificateIssue = async (id: any) => {
  let body = JSON.stringify({ certificate_request_id: id });
  let respons = await fetch(
    `${API_URL}/issue-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await respons.json();
};
const certificateComplete = async (id: any) => {
  let body = JSON.stringify({ certificate_request_id: id });
  let respons = await fetch(
    `${API_URL}/complete-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await respons.json();
};
const getSpecificCertificate = async () => {
  // let body = JSON.stringify({ certificate_request_id: id })
  let respons = await fetch(
    `${API_URL}/approver-certificate-request`,
    CommonMethod.PostMethod()
  );
  return await respons.json();
};

const rejectCertificate = async (data: any) => {
  let body = JSON.stringify({
    certificate_request_id: data,
  });
  let respons = await fetch(
    `${API_URL}/reject-certificate-request`,
    CommonMethod.PostMethod(body)
  );
  return await respons.json();
};

const CertificateRequestService = {
  getCertificate,
  cefrtificateCount,
  certificateProcess,
  certificateIssue,
  certificateComplete,
  getSpecificCertificate,
  rejectCertificate,
  dashboardGraphStatus,
};

export default CertificateRequestService;
