import React from "react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.2),
  },
  label: {
    textTransform: "none",
  },
}));

export default function Buttons(props: any) {
  const { text, size, color, variant, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <>
      <Button
        variant={variant || "contained"}
        size={size || "large"}
        color={color || "secondary"}
        onClick={onClick}
        {...other}
        classes={{ root: classes.root, label: classes.label }}
      >
        {text}
      </Button>
    </>
    // <Button
    //   variant={variant || "contained"}
    //   size={size || "large"}
    //   color={color || "secondary"}
    //   onClick={onClick}
    //   {...other}
    //   classes={{ root: classes.root, label: classes.label }}
    // >
    //   {text}
    // </Button>
    // <MuiButton
    //   variant={variant || "contained"}
    //   size={size || "large"}
    //   color={color || "secondary"}
    //   onClick={onClick}
    //   {...other}
    //   classes={{ root: classes.root, label: classes.label }}
    // >
    //   {text}
    // </MuiButton>
  );
}
