import React from "react";

import Input from "../../components/InputBox/Input";
import Table from "../../components/Tables/Table";
import TotalCard from "../../components/TotalCard/TotalCard";
import "./employees.css";

const EmpolyeesPage = () => {
  return (
    <div className="exEmployee-main">
      <Input />
      <h2 className="title">Empolyee Details</h2>
      <div className="detail-box">
        <div className="total-card">
          <TotalCard
            title="Total Purchase"
            cardDigit={158478.24}
            icon1_Color="#066004"
            icon1_Name="Admins"
          />
          <TotalCard
            title="Total Revenue Generated"
            cardDigit={158478.24}
            icon1_Color="#D11317"
            icon1_Name="Admins"
          />
        </div>
        <Table />
      </div>

      {/* <Table/> */}
    </div>
  );
};

export default EmpolyeesPage;
