import React from "react";
import {
  Text,
  Modal,
  Timeline,
  Box,
  Group,
  Tooltip,
  Badge,
} from "@mantine/core";
import moment from "moment";
import { IconCircleCheck } from "@tabler/icons-react";

interface Props {
  opened: boolean;
  setOpened: any;
  requestCycle: any;
  setRequestCycle: any;
}

function Statuspopup({ opened, setOpened, requestCycle }: Props) {
  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      >
        {requestCycle?.length === 0 ? (
          <div style={{width:"100%",height:"5vh",display:"flex",alignItems:"center",justifyContent:"center", color:"orange",fontSize:"17px"}}>
            <p><strong>Process Yet To Start</strong></p>
          </div>
        ) : (
          <div>
            <Timeline active={1} bulletSize={20} lineWidth={1} key={1}>
              {requestCycle?.map((item: any, index: any) => {
                return (
                  <Timeline.Item
                    style={{
                      height: 100,
                    }}
                    bullet={<Text>{index + 1}</Text>}
                    key={index}
                  >
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Group
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text fs="md" fw={500}>
                            {item.certificate_approval_stage[0]?.action_performed?.toUpperCase()}{" "}
                            <IconCircleCheck size={18} color="green" />
                          </Text>
                          <Text color="gray">
                            Processed By:
                            <span
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                paddingLeft: 10,
                              }}
                            >
                              {item?.certificate_approval_template_stage_name}
                            </span>
                          </Text>
                        </Group>

                        <Tooltip
                          style={{
                            fontSize: 10,
                          }}
                          label="updated date"
                          withArrow
                        >
                          <Badge>
                            {`${moment(
                              item.certificate_approval_stage[0]
                                ?.created_at
                            ).format("DD-MM-YYYY")} ${moment(
                              item?.certificate_approval_stage
                                ?.action_performed_at
                            ).format("LT")}`}
                          </Badge>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
        )}
        {/* {requestCycle?.map((item: any, index: any) => {
            return (
              <Timeline.Item
                style={{
                  height: 100,
                }}
                bullet={<Text>{index + 1}</Text>}
              >
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Group
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text fs="md" fw={500}>
                        {item.certificate_approval_stage[0]?.action_performed?.toUpperCase()}{" "}
                        <IconCircleCheck size={18} color="green" />
                      </Text>
                      <Text color="gray">
                        Processed By:
                        <span
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            paddingLeft: 10,
                          }}
                        >
                          {item?.certificate_approval_template_stage_name}
                        </span>
                      </Text>
                    </Group>

                    <Tooltip
                      style={{
                        fontSize: 10,
                      }}
                      label="updated date"
                      withArrow
                    >
                      <Badge>
                        {`${moment(
                          item.certificate_approval_stage?.action_performed_at
                        ).format("DD-MM-YYYY")} ${moment(
                          item?.certificate_approval_stage?.action_performed_at
                        ).format("LT")}`}
                      </Badge>
                    </Tooltip>
                  </Box>
                </Box>
              </Timeline.Item>
            );
          })} */}
      </Modal>
    </div>
  );
}

export default Statuspopup;
