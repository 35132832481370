import {
  ActionIcon,
  Container,
  Divider,
  Grid,
  Image,
  Loader,
  Modal,
  Skeleton,
  TextInput,
  Title,
} from "@mantine/core";
import { IconRotateClockwise2, IconSearch } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import "./MyCertificate.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Badge, Card, Group, Text } from "@mantine/core";

import ViewTemplateModal from "../../components/AllPopUPs/ViewTemplate";
import NoData from "../../components/LottieFiles/NoData";
import RequestPop from "../../components/RequestPopup/RequestPop";

import Statuspopup from "../../components/AllPopUPs/Statuspopup";
import MyCertificateServices from "../../services/Mycertificateservices";
import Mycertificateservices from "../../services/Mycertificateservices";
import moment from "moment";

import { useDebounce } from "use-debounce";

function generateId(uuid: string) {
  return uuid.split("-")[0];
}

const MyCertificate = () => {
  const [requestCycle, setRequestCycle]: any = useState([]);
  const [requestPop, setRequestPop]: any = useState(false);
  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(0);
  const [opened, setOpened] = useState(false);
  const [has, setHas] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [data, setData]: any = useState([]);
  const [totalData, setTotalData]: any = useState();

  const [dataLength, setDataLength]: any = useState(0);
  const [reqInput, setReqInput]: any = useState();
  const [tmeplateModal, setViewTemplateModal]: any = useState(false);
  const [loading, setLoading]: any = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [imageURl, setImageUrl] = useState<undefined | string>(undefined);

  useEffect(() => {
    loadData(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  let getUserid = localStorage.getItem("user");
  let str: any;
  if (getUserid) {
    str = JSON.parse(getUserid);
  }

  const fetchRequestCycle = async (
    templateId: number,
    requestId: number,
    status: string
  ) => {
    let data = {
      where: {
        certificate_approval_template: templateId,
      },
      include: {
        certificate_approval_stage: {
          include: {
            user_details: {
              include: {
                users: {
                  include: {
                    user_roles: {
                      include: {
                        roles: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
    try {
      if (status === "pending") {
        setOpened(false);
      } else {
        setOpened(true);
        await MyCertificateServices.getApprovalTemplateStages(
          templateId,
          requestId,
          getUserid
        ).then((res: any) => {
          setRequestCycle(res.data.certificate_approval_template_stage);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMore = async () => {
    if (data?.length < totalData) {
      let payload = {};
      try {
        await Mycertificateservices.listCertificateRequest(
          payload,
          limit,
          data.length === 0 ? 0 : data.length
        )
          .then((res) => {
            setData((prevData: any) => [
              ...prevData,
              ...res.data.certificate_request,
            ]);
            if (res.data.certificate_request.length < limit) {
              setHasMore(false);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      } catch (error) {}
    } else {
      setHasMore(false);
    }
  };

  const loadData = async (search = "") => {
    setLoading(true);
    let payload = {};

    if (search && search.length > 0) {
      payload = {
        uuid: {
          contains: search,
        },
      };
    }
    await Mycertificateservices.listCertificateRequest(payload, limit, skip)
      .then((res) => {
        setDataLength(res.data.certificate_request.length);
        // if (res.data.certificate_request.length < res.data.total) {
        //   setHasMore(true);
        // } else {
        //   setHasMore(false);
        // }
        setData(res.data.certificate_request);
        if (res.data.certificate_request.length < limit) {
          setHasMore(false);
        }
        setTotalData(res.data.total);
      })
      .catch((err: any) => {
        console.log(err);
      });

    setLoading(false);
  };

  const ICON = (
    <ActionIcon size="xs" color="blue" radius="xl" variant="transparent">
      <IconRotateClockwise2 style={{ transform: "rotate(-40deg)" }} />
    </ActionIcon>
  );

  const dataCard = [
    { img: "img" },
    { img: "img" },
    { img: "img" },
    { img: "img" },
    { img: "img" },
    { img: "img" },
  ];
  return (
    <Container size={"lg"}>
      <Statuspopup
        opened={opened}
        setOpened={setOpened}
        requestCycle={requestCycle}
        setRequestCycle={setRequestCycle}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title>Offer Letters</Title>

        <TextInput
          icon={<IconSearch />}
          placeholder="Search by id"
          size="md"
          radius="md"
          onChange={(event) => {
            setSearchTerm(event.currentTarget.value);
          }}
        />
      </div>
      {loading ? (
        <>
          <Grid style={{ paddingTop: "20px" }}>
            {dataCard?.map((item: any, index: any) => {
              return (
                <Grid.Col span={3} key={index}>
                  <Card p={40} withBorder>
                    <Card.Section style={{ overflow: "hidden" }}></Card.Section>
                    <Card.Section style={{ overflow: "hidden" }}>
                      <Skeleton style={{ height: "150px" }} />
                    </Card.Section>

                    <Group>
                      <Skeleton
                        style={{
                          height: "30px",
                          width: "100px",
                        }}
                      >
                        {item.img}
                      </Skeleton>
                    </Group>
                    <Group></Group>
                  </Card>
                </Grid.Col>
              );
            })}
          </Grid>
        </>
      ) : data?.length > 0 ? (
        <>
          {tmeplateModal && (
            <ViewTemplateModal
              settemplatemodal={setViewTemplateModal}
              TemplateString={reqInput}
            />
          )}

          {requestPop === true && (
            <RequestPop
              setRequestPop={setRequestPop}
              getRequestList={loadData}
            />
          )}

          <Modal
            fullScreen
            opened={showPdfViewer}
            onClose={() => setShowPdfViewer(false)}
          >
            <Group position="center">
              <object
                data={imageURl}
                type="application/pdf"
                className="pdfobject"
              />
            </Group>
          </Modal>
          <InfiniteScroll
            dataLength={data?.length}
            style={{ overflow: "hidden" }}
            next={handleLoadMore}
            hasMore={hasMore}
            scrollThreshold={0.9}
            loader={
              <div style={{ textAlign: "center" }}>
                <Loader color="red" />
              </div>
            }
          >
            <Grid style={{ paddingTop: "20px" }}>
              {data?.map((item: any, index: any) => {
                return (
                  <>
                    <Grid.Col span={3} key={index}>
                      <Card
                        radius="lg"
                        withBorder
                        onClick={() => {
                          if (item.response_status === "completed") {
                            if (
                              item.certificate &&
                              item.certificate.digitalized_certificate &&
                              item.certificate.digitalized_certificate[0] &&
                              item.certificate.digitalized_certificate[0]
                                .image_url
                            ) {
                              setImageUrl(
                                item.certificate.digitalized_certificate[0]
                                  .image_url
                              );
                              setShowPdfViewer(true);
                            }
                            // window.open(
                            //   `${item?.certificate?.digitalized_certificate[0]?.block_image}`,
                            //   "_blank"
                            // );
                            return;
                          }
                        }}
                      >
                        <Card.Section>
                          <Image
                            src={item.document?.image}
                            height={150}
                            alt="Norway"
                          />
                        </Card.Section>
                        <Group
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text fw={500}>
                            {`Offer Letter-${generateId(item?.uuid)}`}
                          </Text>

                          <Group position="center">
                            <Badge
                              variant="filled"
                              radius="md"
                              color={
                                item.response_status === "waiting"
                                  ? "black"
                                  : item.response_status === "completed"
                                  ? "green"
                                  : item.response_status === "final"
                                  ? "green"
                                  : item.response_status === "pending"
                                  ? "yellow"
                                  : item.response_status === "rejected"
                                  ? "red"
                                  : ""
                              }
                            >
                              {item?.response_status?.charAt(0)?.toUpperCase() +
                                item?.response_status?.slice(1)}
                            </Badge>
                          </Group>
                        </Group>
                        <Divider my="xs" />

                        {item?.fields && item?.fields?.name && (
                          <div>
                            <Text
                              color="gray"
                              size="xs"
                              style={{ textTransform: "capitalize" }}
                            >
                              Issued To: {item?.fields?.name}
                            </Text>
                            <Text
                              color="gray"
                              size="xs"
                              style={{ textTransform: "capitalize" }}
                            >
                              Offer Id: {item?.fields?.offerId}
                            </Text>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "2px",
                          }}
                        >
                          <Text color="gray" size="xs">
                            Issued At:{" "}
                            {moment(item?.created_at).format(
                              "MM/DD/YYYY hh:mm:ss"
                            )}
                          </Text>
                        </div>
                      </Card>
                    </Grid.Col>
                  </>
                );
              })}
            </Grid>
          </InfiniteScroll>
          {/* <Group position="center" pt="md">
            {hasMore && (
              <Badge
                pl={0}
                size="xl"
                color="blue"
                radius="xl"
                leftSection={ICON}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  loadData();
                }}
              >
                Load More
              </Badge>
            )}
          </Group> */}
        </>
      ) : (
        <>
          {/* <Group position="right" pt="md">
            <Button
              style={{
                backgroundColor: "#25204f",
                color: "#fff",
                fontSize: "14px",
              }}
              onClick={() => {
                setRequestPop(true);
              }}
            >
              Certificate Request
            </Button>
          </Group> */}
          <NoData />
        </>
      )}
    </Container>
  );
};

export default MyCertificate;
