import Home from "../screens/home";
export const SOCKET_URL = "wss://certify.kprinfosolution.com";
export const API_BASE_URL =
  process.env.REACT_APP_ENV === "prod"
    ? "https://certify.kprinfosolution.com/api"
    : process.env.REACT_APP_ENV === "qa"
    ? "https://certify.kprinfosolution.com/api"
    : process.env.REACT_APP_ENV === "stage"
    ? "https://certify.kprinfosolution.com/api"
    : process.env.REACT_APP_ENV === "dev"
    ? "https://certify.kprinfosolution.com/api"
    : "https://certify.kprinfosolution.com/api";

export const APP_ROUTES = [
  {
    pathName: "/",
    title: "Home",
    component: Home,
    childRoutes: [],
  },
];
