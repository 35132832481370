import { CloseButton, Input, Loader } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { TextStyle } from "../../custom";
import API_Services from "../../services/CertificateUsersService";
import ConfirmPop from "../confirmPopup/ConfirmPop";

import RejectPop from "../RejectPopup/RejectPop";
import "./Approvepop.css";

interface CardPropsType {
  setApporvePop?: any;
  detail?: any;
  setConfirm: any;
  input?: any;
  setInput?: any;
  getRequestList?: any;
}
const ApprovePopup: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setApporvePop, detail, setConfirm, input, setInput, getRequestList } =
    props;
  const [popup, setPopup]: any = useState(true);
  const [approveBox, setBox]: any = useState(true);
  const [rejectPop, setRejectPop]: any = useState(false);
  const [confirmBox, setConfirmBox]: any = useState(false);
  const [requestFields, setRequestField]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  setApporvePop(popup);

  useEffect(() => {
    getIssueFields();
  }, []);

  useEffect(() => {
    if (detail) {
      setInput(detail.document_info[0]);
    }
  }, [detail]);
  console.log(requestFields);


  const getIssueFields = async () => {
    setLoading(true);
    await API_Services.getDocumentFileds(detail.document_type_id).then(
      (res) => {
        setRequestField(res.data.document_fields);
        setLoading(false);
      }
    );
  };

  const handleChange = (e: any) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <>
      {approveBox && (
        <div className={popup ? "approve-pop-main active" : "approve-pop-main"}>
          <div className="approve-pop" style={{ background: "white" }}>
            <CloseButton
              className="close1"
              title="Close popover"
              size="xl"
              onClick={() => setPopup(false)}
              iconSize={20}
            />
            <h3>Approve Certificate</h3>
            <div>
              <table>
                <tr>
                  <th>Employee Name</th>
                  <th>Document</th>
                </tr>
                <tr>
                  <td>{input && input.employeeName}</td>
                  <td>{detail.template_name}</td>
                </tr>
              </table>
            </div>
            {loading && (
              <div style={{ marginTop: "50px" }}>
                <Loader color="#25204f" />
              </div>
            )}

            {/* RequestFields populate */}
            {requestFields.length > 0 ? (
              <div
                className="issue-inputfield"
                style={{ fontFamily: TextStyle.fontFamily }}
              >
                {requestFields.map((e: any, id: any) => {
                  if (e.field_type === "date") {
                    return (
                      <DatePicker
                        placeholder={
                          input[e.field_name] ? input[e.field_name] : "Pick one"
                        }
                        label={e.field_label}
                        inputFormat="DD/MM/YYYY"
                        labelFormat="DD/MM/YYYY"
                        radius="sm"
                        size="sm"
                        className="inputfields"
                        name={e.field_name}
                        onChange={(val) => {
                          // @ts-ignore
                          let event = new Date(val).toLocaleString().split(",");
                          setInput({ ...input, [e.field_name]: event[0] });
                        }}
                      />
                    );
                  } else {
                    return (
                      <Input.Wrapper
                        id={id}
                        label={e.field_label}
                        className="inputfields"
                      >
                        <Input
                          type={e.field_type}
                          value={input[e.field_name]}
                          onChange={handleChange}
                          name={e.field_name}
                          id={id}
                          radius="sm"
                          size="sm"
                        />
                      </Input.Wrapper>
                    );
                  }
                  // return <Input.Wrapper id={id} label={e.field_label} className="inputfields">
                  //   <Input type={e.field_type} value={input[e.field_name]} onChange={handleChange} name={e.field_name} id={id} radius="sm" size="sm" />
                  // </Input.Wrapper>
                })}
              </div>
            ) : (
              <div style={{ height: "50px" }}></div>
            )}

            <div>
              <button
                className="approve-cancel-btn"
                onClick={() => {
                  setBox(false);
                  setRejectPop(true);
                }}
              >
                Reject
              </button>
              <button
                className="approve-request-btn"
                onClick={() => {
                  setBox(false);
                  setConfirmBox(true);
                }}
              >
                Approve{" "}
              </button>
            </div>
          </div>
        </div>
      )}
      {rejectPop && (
        <RejectPop detail={detail} getRequestList={getRequestList} />
      )}
      {confirmBox && (
        <ConfirmPop
          setConfirmBox={setConfirmBox}
          setConfirm={setConfirm}
          setBox={setBox}
          message="Approve Certificate ?"
        />
      )}
    </>
  );
};

export default ApprovePopup;
