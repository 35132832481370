import React, { useEffect, useMemo, useState } from "react";
import { TextStyle } from "../../../custom";
import { useRef } from "react";
import { Text, Group, Button, useMantineTheme, TextInput } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons-react";
import { Autocomplete, Input, Loader, Modal, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import _, { set } from "lodash";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../../common/config";
import API_Services from "../../../services/CertificateUsersService";
import { useNavigate } from "react-router-dom";

import "../../../components/RequestPopup/RequestPop.css";
import { userProfileImageUpload } from "../../../services/UserProfileService";
import ConfirmPop from "../../../components/confirmPopup/ConfirmPop";
import SuccessPop from "../../../components/successPopup/SuccessPop";

import {
  startNavigationProgress,
  completeNavigationProgress,
} from "@mantine/nprogress";

interface CardPropsType {
  getRequestList?: any;
  setRequestPop?: any;
}

const CertificateForm: React.FC<CardPropsType> = (props): JSX.Element => {
  const [input, setInput]: any = useState();
  const [uploadFileName, setUploadFileName] = useState("");
  const [documentTypeList, setDocumentTypeList]: any = useState([]);
  const [imageUpload, setImageUpload] = useState<any>([]);
  const [confirmBox, setConfirmBox]: any = useState(false);
  const [confirm, setConfirm]: any = useState(false);
  const [selectedDocument, setSelectedDocument]: any = useState();
  const [certificteRequest, setCertificateRequest]: any = useState();
  const [requestStatus, setRequestStatus]: any = useState();
  const [message, setMessage]: any = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [requestFields, setRequestField]: any = useState([]);
  const [userList, setUserList]: any = useState([]);
  const [userListChange, setUserListChange]: any = useState<string>();
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);
  const userdetails = useSelector((s: any) => s.user.value);
  const [error,setError] = useState<any>(null);

  const [departments, setDepartments] = useState<any>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);

  const [fields, setFields] = useState<any>({});

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (confirm === true) {
      sendRequest();
    }
  }, [confirm]);

  const handleChange = async (files: any) => {
    try {
      setUploadLoading(true);
      if (files) {
        const formData: any = new FormData();
        files.forEach((file: any, index: any) => {
          formData.append(`file`, file);
        });
        formData.append("extractTextFromPDF", true);
        const res = await userProfileImageUpload(formData);
        const imageUrls = res?.data?.imageURL;
        console.log(imageUrls, "imageUrls");
        console.log(requestFields);
        let documentEntries = []
        setError(null);
        for(let i=0;i<imageUrls.length;i++){
          let item = imageUrls[i];
          if(item?.error){
            setError('Invalid or duplicate offer letter(s) found. It will be skipped')
          }else{
            let final_data = {
              document_id: selectedDocument,
              document_info: [
                {
                  [requestFields[0].field_name]: item?.Location,
                },
              ],
              fields: item?.fields,
            }
            documentEntries.push(final_data)
          }
          
        }
        // const documentEntries = imageUrls.map((item: any, index: any) => ());
        console.log(documentEntries, "documentEntries");
        setImageUpload(documentEntries);
        setUploadLoading(false);
      } else {
        console.error("No files selected");
        setUploadLoading(false);
      }
    } catch (error) {
      console.error("Error handling change:", error);
      setUploadLoading(false);
    }
  };

  let sendRequest = () => {
    startNavigationProgress();
    setLoading(true);

    let token: any = localStorage.getItem("token");
    // for (let i = 0; i < input.length; i++) {
    //   if (_.isEmpty(input[i].object.keys)) {
    //   }
    // }

    const config = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `bearer ${JSON.parse(token)}`,
      },
      body: JSON.stringify({
        data: imageUpload,
      }),
    };
    try {
      fetch(`${API_BASE_URL}/certificate-request`, config)
        .then((res) => res.json())
        .then((data) => {
          if (data.code == 200) {
            showNotification({
              color: "green",
              message: data.message,
            });
            setConfirm(false);
            completeNavigationProgress();
            setLoading(false);
            navigate("/certificates");
          } else if (data.code == 400) {
            setMessage(data.data.errors[0]);
            setRequestStatus("rejected");
            completeNavigationProgress();
            setLoading(false);
          }
        });
    } catch (error) {
      completeNavigationProgress();
      setLoading(false);
    }
  };

  let getDocumentList = () => {
    startNavigationProgress();
    setLoading(true);

    try {
      API_Services.fetchDocumentList().then((res: any) => {
        setDocumentTypeList(res.data.documentType);

        completeNavigationProgress();
        setLoading(false);
      });
    } catch (error) {
      completeNavigationProgress();
      setLoading(false);
    }
  };

  let getDepartments = () => {
    startNavigationProgress();
    setLoading(true);

    try {
      API_Services.fetchDepartmentList().then((res: any) => {
        setDepartments(res.data.department);

        completeNavigationProgress();
        setLoading(false);
      });
    } catch (error) {
      completeNavigationProgress();
      setLoading(false);
    }
  };

  let getDocumentFields = async (e: any) => {
    startNavigationProgress();
    setLoading(true);

    try {
      let data = {
        where: {
          document_type_id: e,
          status: true,
        },
        include: {
          document_type: true,
        },
      };
      API_Services.getDocumentFileds(data).then((res: any) => {
        setRequestField(res.data.document_fields);
        completeNavigationProgress();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      completeNavigationProgress();
      setLoading(false);
    }
  };

  const getUserListByFilter = async (value: any) => {
    let data;
    if (value) {
      data = {
        limit: 10,
        where: {
          OR: [
            {
              first_name: {
                contains: value,
                mode: "insensitive",
              },
            },
            {
              last_name: {
                contains: value,
                mode: "insensitive",
              },
            },
          ],
        },
      };
    } else {
      data = { skip: 0, limit: 10 };
    }
    API_Services.getUserListByFilter(data)
      .then((res) => {
        if (res?.status) {
          setUserList(res?.data?.users);
        } else {
          console.log("err", "UserList By Filter Error...");
        }
      })
      .catch((err) => {
        console.log("eer", err);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (selectedDocument) {
      setConfirmBox(true);
    }
  };

  useEffect(() => {
    getUserListByFilter("");
  }, [userListChange]);

  useMemo(async () => {
    getDocumentList();
    getDepartments();
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className="doc-btn">
        <Text>Choose document type</Text>
        <Text color="gray" size="sm">
          Select the type of document you need from the options below.
        </Text>
        <Select
          onChange={async (e) => {
            setSelectedDocument(e);
            getDocumentFields(e);
          }}
          mt={8}
          radius="md"
          size="md"
          placeholder="Document"
          data={
            Array.isArray(documentTypeList)
              ? documentTypeList
                  .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                  })
                  .map((e: any) => {
                    return { value: e.id, label: e.name };
                  })
              : []
          }
        />
      </div>
{/* 
      <div className="doc-btn">
        <TextInput
          placeholder="John"
          label="Full name"
          withAsterisk
          onChange={(e) => {
            setFields({ ...fields, name: e.target.value });
          }}
        />
      </div> */}

      {/* <div className="doc-btn">
        <TextInput
          placeholder="john@example.com"
          label="Email Address"
          withAsterisk
          onChange={(e) => {
            setFields({ ...fields, email_address: e.target.value });
          }}
        />
      </div> */}

      {/* <div className="doc-btn">
        <Text>Choose department</Text>
        <Text color="gray" size="sm">
          Select the department.
        </Text>
        <Select
          onChange={async (e) => {
            setSelectedDepartment(e);
          }}
          mt={8}
          radius="md"
          size="md"
          placeholder="Department"
          data={departments
            .sort((a:any, b:any) => {
              if (a.department_name.toLowerCase() < b.department_name.toLowerCase())
                return -1;
              if (
                a.department_name.toLowerCase() >
                b.department_name.toLowerCase()
              )
                return 1;
              return 0;
            })
            .map((e: any) => {
              return { value: e.id, label: e.department_name };
            })}
        />
      </div> */}

      {userdetails?.user_roles &&
      userdetails?.user_roles[`REQUEST_CERTIFICATE_AGAINST`] &&
      userdetails?.user_roles[`REQUEST_CERTIFICATE_AGAINST`][0].can_read ? (
        <Autocomplete
          // label="List Users"
          // variant="filled"
          placeholder="List Users"
          name="user"
          limit={10}
          className="doc-btn"
          // data={['Arun', 'Dev']}
          size="lg"
          radius="md"
          maxDropdownHeight={400}
          data={
            Array.isArray(userList)
              ? userList.map((provider) => {
                  return {
                    label: `${provider.first_name}`,
                    value: `${provider.id}`,
                  };
                })
              : []
          }
          onChange={(value) => {
            setCertificateRequest(value);
            setUserListChange(value);
            getUserListByFilter(value);
          }}
          nothingFound="Nobody here"
        />
      ) : null}

      <form onSubmit={handleSubmit}>
        <div
          className="request-inputfield"
          style={{ fontFamily: TextStyle.fontFamily }}
        >
          {requestFields.map((e: any, id: any) => {
            if (e.field_type === "date") {
              return (
                <DatePicker
                  placeholder="Pick date"
                  label={e.field_label}
                  inputFormat="DD/MM/YYYY"
                  labelFormat="DD/MM/YYYY"
                  radius="md"
                  size="md"
                  className="inputfields"
                  name={e.field_name}
                  onChange={(val) => {
                    // @ts-ignore
                    let event = new Date(val).toLocaleString().split(",");
                    setInput({ ...input, [e.field_name]: event[0] });
                  }}
                />
              );
            } else if (e.field_type === "file") {
              return (
                <div>
                  <Dropzone
                    mt={10}
                    openRef={openRef}
                    onDrop={(acceptedFiles) => {
                      handleChange(acceptedFiles);
                      const fileNames = acceptedFiles
                        .map((file) => file.name)
                        .join(", ");
                      setUploadFileName(fileNames);
                    }}
                    style={{
                      width: "500px",
                    }}
                    radius="md"
                    accept={[MIME_TYPES.pdf]}
                    maxSize={30 * 1024 ** 2}
                    loading={uploadLoading}
                  >
                    <div style={{ pointerEvents: "none" }}>
                      <Group
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Dropzone.Accept>
                          <IconDownload
                            color={theme.colors.blue[6]}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX color={theme.colors.red[6]} stroke={1.5} />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          <IconCloudUpload stroke={1.5} />
                        </Dropzone.Idle>
                      </Group>

                      <Text ta="center" fw={700} fz="lg" mt="xl">
                        <Dropzone.Accept>Drop files here</Dropzone.Accept>
                        <Dropzone.Reject>
                          Pdf files less than 30mb each
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          Drag and Drop or choose files to upload
                        </Dropzone.Idle>
                      </Text>
                      <Text ta="center" fz="sm" mt="xs" c="dimmed">
                        We can accept only <i>.pdf</i> files that are less than
                        30mb each in size.
                      </Text>
                    </div>
                  </Dropzone>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingTop: "10px",
                      gap: "5px",
                      alignItems: "center",

                      width: "500px",
                    }}
                  >
                    <Text >{uploadFileName}</Text>
                    <Text color="red">{error}</Text>
                  </div>
                </div>
              );
            } else {
              return (
                <Input.Wrapper
                  id={id}
                  label={e.field_label}
                  className="inputfields"
                  withAsterisk={e?.is_required}
                >
                  <Input
                    type={e.field_type}
                    onChange={handleChange}
                    name={e.field_name}
                    required={e?.is_required}
                    id={id}
                    radius="md"
                    size="md"
                  />
                </Input.Wrapper>
              );
            }
          })}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            mt={20}
            radius="md"
            style={{
              backgroundColor: "#25204f",
            }}
            loading={loading}
            size="md"
            disabled={loading || imageUpload.length === 0}
            onClick={() => {
              // if (
              //   fields.name === undefined ||
              //   fields.email_address === undefined 
              // ) {
              //   return showNotification({
              //     color: "red",
              //     title: "Error",
              //     message: "Please fill all the required fields",
              //   });
              // }
              if (selectedDocument) {
                setConfirmBox(true);
              }
            }}
          >
            Upload
          </Button>
        </div>
      </form>
      {confirmBox && (
        <ConfirmPop
          setConfirmBox={setConfirmBox}
          setConfirm={setConfirm}
          message={"Proceed with Certificate Creation?"}
          description="Please ensure that all provided information is accurate as this action cannot be undone."
        />
      )}

      {requestStatus === "created" && <SuccessPop message={message} />}
    </div>
  );
};

export default React.memo(CertificateForm);
