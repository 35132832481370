import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import AddModulePop from "../../components/AllPopUPs/AddModulePop";
import DeleteModal from "../../components/AllPopUPs/DeleteModal";
import ConfirmDialog from "../../components/Controls/ConfirmDialog";
import NoData from "../../components/LottieFiles/NoData";
import ModuleServices from "../../services/ModuleServices";
import "./UserRollSetting.css";

const RollSettingsModules = () => {
  //table---
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [ModulePop, setModulePop]: any = useState(false);
  const [modulelist, setModulelist]: any = useState([]);
  const [editModulepop, setEditModulepop]: any = useState("");
  const [editmoduledata, setEditModuledata]: any = useState("");
  const [moduleName, setModuleName]: any = useState("");
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  console.log(modulelist);

  const [modulesTotal, setmodulesTotal]: any = useState();

  const getModulelists = async () => {
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
      select: {
        id: true,
        uuid: true,
        module_name: true,
        status: true,
      },
    };
    let data1 = {
      limit: pageSize,
      select: {
        id: true,
        uuid: true,
        module_name: true,
        status: true,
      },
      where: {
        module_name: {
          contains: moduleName,
          mode: "insensitive",
        },
      },
    };
    setLoading(true);
    try {
      let res = await ModuleServices.Modulelists(moduleName ? data1 : data);
      if (res.status === true) {
        setModulelist(res.data.modules);
        setmodulesTotal(res.data.total);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeletemodule = async (params: any) => {
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      await ModuleServices.DeleteModules(params).then((res) => {
        if (res.status === true) {
          getModulelists();
          showNotification({
            color: "green",
            message: res.message,
          });
        }
      });
    } catch (error) {
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  const handleUpdatemodule = async (params: any) => {
    setEditModuledata(params);
    setEditModulepop(true);
  };

  //role functions end---
  useEffect(() => {
    getModulelists();
  }, [page, pageSize, moduleName]);
  const setcurrentPage = (value: any) => {
    setPage(value);
    setModulelist([]);
  };
  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setModulelist([]);
  };

  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {ModulePop && (
        <AddModulePop
          ModulePop={ModulePop}
          setModulePop={setModulePop}
          getModulelists={getModulelists}
          editModulepop={editModulepop}
          editmoduledata={editmoduledata}
        />
      )}

      <Card mt="sm" radius="lg" shadow="lg">
        <Card.Section>
          <Group position="apart">
    
          <Box m="md">
            <Autocomplete
              label="Filter by Name"
              placeholder="Search Module"
              data={
                Array.isArray(modulelist)
                  ? modulelist.map((provider) => `${provider.module_name}`)
                  : []
              }
              onChange={(value) => setModuleName(value)}
              maxDropdownHeight={400}
              nothingFound="Nobody here"
            />
          </Box>
          <Box  p="md">
            <Button
              style={{ background: "#25204f" }}
              onClick={() => {
                setModulePop(true);
                setEditModulepop(false);
              }}
            >
              + Add
            </Button>
          </Box>
          </Group>
          {loading === true ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "50px",
              }}
            >
              <Loader color="#25204f" />
            </div>
          ) : modulelist && modulelist.length > 0 ? (
            <div>
              <Table verticalSpacing="lg" highlightOnHover>
                <thead style={{ border: "none" }}>
                  <tr>
                    <th>
                      <Checkbox size="sm" />
                    </th>
                    <th>
                      <div>Name</div>
                    </th>
                    <th>
                      <div>Status</div>
                    </th>
                    <th>
                      <div>Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {modulelist?.map((modules: any, index: number) => (
                    <tr
                      className={
                        modules?.status === false ? "in-active-list" : ""
                      }
                      key={modules?.uuid}
                    >
                      <td>
                        <Checkbox size="sm" />
                      </td>
                      <td>{modules?.module_name}</td>
                      <td>{modules?.status ? "Active" : "In-Active"}</td>
                      <td>
                        <Button
                          onClick={() => {
                            handleUpdatemodule(modules);
                            setEditModulepop(true);
                            setModulePop(true);
                          }}
                          variant="default"
                          radius="md"
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: "Want to Delete?",

                              onConfirm: () => {
                                handleDeletemodule(modules);
                              },
                            });
                          }}
                          variant="default"
                          radius="md"
                          ml="sm"
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Group position="apart" mt="md" p="lg">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box mr="md">Rows per page</Box>
                  <Select
                    onChange={(e) => setrowsperpage(e)}
                    style={{ width: "4rem" }}
                    value={String(pageSize)}
                    data={[
                      { value: "5", label: "5" },
                      { value: "10", label: "10" },
                      { value: "15", label: "15" },
                      { value: "20", label: "20" },
                    ]}
                  />
                </Box>
                <Pagination
                  styles={(theme) => ({
                    item: {
                      "&[data-active]": {
                        backgroundColor: "#25204f",
                      },
                    },
                  })}
                  total={Math.ceil(modulesTotal / pageSize)}
                  boundaries={5}
                  page={page}
                  initialPage={1}
                  onChange={(e) => setcurrentPage(e)}
                />
              </Group>
            </div>
          ) : (
            <NoData />
          )}
        </Card.Section>
      </Card>
    </>
  );
};

export default RollSettingsModules;
