import React, { useState, useEffect } from "react";
import { showNotification } from "@mantine/notifications";
import { COLORS, Theme } from "../../custom";
import Adduser from "../../components/AddUser/Adduser";
import API_Services from "../../services/CertificateUsersService";
import Edituser from "../../components/EditUser/EditUser";
import ConfirmDialog from "../../components/Controls/ConfirmDialog";
import ConsentReqPopup from "../../components/consent pop/consentrequestpop";
import {
  Menu,
  Button,
  Table,
  Group,
  Checkbox,
  Pagination,
  Box,
  Select,
  Loader,
  Card,
} from "@mantine/core";

const ProfileNotification = () => {
  const [openAdduser, setOpenAdduser]: any = useState(false);
  const [openEdituser, setOpenEdituser]: any = useState(false);
  const [editData, setEditData]: any = useState();
  const [consentpop, setConsentPop]: any = useState(false);
  const [userlist, setUserList]: any = useState([]);
  const [requestdata, setRequestdata]: any = useState("");
  const [usersdata, setUsersData]: any = useState();
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [total, setTotal]: any = useState();
  const [loading, setLoading]: any = useState(false);

  const getuserlist = async () => {
    setLoading(true);
    let data = {
      limit: pageSize,
      skip: page,
    };
    try {
      await API_Services.getUserList(data).then((res) => {
        setTotal(res.data.total);
        setUserList(res.data.users);
        console.log(res.data.users);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const setcurrentPage = (value: any) => {
    setPage(value);
    setUserList([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setUserList([]);
  };

  const handleDelete_user = async (params: any) => {
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      await API_Services.DeleteUser(params).then((res) => {
        getuserlist();
        showNotification({
          color: "green",
          message: res.message,
        });
      });
    } catch (error) {
      showNotification({
        color: "red",
        message: "error",
      });
    }
  };

  useEffect(() => {
    getuserlist();
  }, [page, pageSize]);

  return (
    <div className="RolePage-main">
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading === true ? <Loader color='#25204f'/> : ""}
        </div>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        {consentpop && (
          <ConsentReqPopup
            setConsentPop={setConsentPop}
            requestdata={requestdata}
            usersdata={usersdata}
            getuserlist={getuserlist}
          />
        )}

        {openAdduser && (
          <Adduser setOpenAdduser={setOpenAdduser} getuserlist={getuserlist} />
        )}
        {openEdituser && (
          <Edituser
            setOpenEdituser={setOpenEdituser}
            editData={editData}
            getuserlist={getuserlist}
          />
        )}

        {!openAdduser && !openEdituser && (
          <div>
            <Card m="lg" radius="lg" shadow="lg">
              <Card.Section p="xl" mb="xl">
                <Group position="apart">
                  <p style={{ color: Theme.primary }}>User List</p>
                  <button
                    style={{ backgroundColor: COLORS.backgroundContent }}
                    className="add-user-button"
                    onClick={() => {
                      setOpenAdduser(true);
                    }}
                  >
                    + Add user
                  </button>
                </Group>
              </Card.Section>
            </Card>
            <Card m="lg" radius="lg" shadow="lg">
              <Card.Section>
                <Table verticalSpacing="md" highlightOnHover>
                  <thead style={{ border: "none" }}>
                    <tr>
                      <th>
                        <Checkbox size="sm" label="SI no" />
                      </th>
                      <th>Employee ID</th>
                      <th>Email Address</th>
                      <th>Designation</th>
                      <th>Department</th>
                      <th>Role</th>
                      <th>Joined Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {userlist.length >= 0 ? (
                    <tbody>
                      {userlist.map((user: any) => (
                        <tr key={user.uuid}>
                          <td>
                            <Checkbox size="sm" label={user.user_id} />
                          </td>
                          <td>{user.employee_id}</td>
                          <td>{user.email}</td>
                          <td>{user.designation_name}</td>
                          <td>{user.department_name}</td>
                          <td>{user.role_name}</td>
                          <td>{user.date_of_joining}</td>

                          <td>
                            <Button
                              onClick={() => {
                                setEditData(user);
                                setOpenEdituser(true);
                              }}
                              variant="default"
                              radius="md"
                            >
                              edit
                            </Button>
                            <Button
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Want to Delete ?",
                                  onConfirm: () => {
                                    handleDelete_user(user);
                                  },
                                });
                              }}
                              variant="default"
                              radius="md"
                              ml="sm"
                            >
                              delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <>no data</>
                  )}
                  <tfoot></tfoot>
                </Table>
                <Group position="apart" mt="md" p="lg">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Box mr="md">Rows per page</Box>
                    <Select
                      onChange={(e) => setrowsperpage(e)}
                      style={{ width: "4rem" }}
                      defaultValue="10"
                      data={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                      ]}
                    />
                  </Box>
                  <Pagination
                    total={Math.ceil(total / pageSize)}
                    boundaries={5}
                    page={page}
                    initialPage={1}
                    onChange={(e) => setcurrentPage(e)}
                  />
                </Group>
              </Card.Section>
            </Card>
          </div>
        )}
      </>
    </div>
  );
};

export default ProfileNotification;
