import {
  Avatar,
  Button,
  Collapse,
  createStyles,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  Modal,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {
  IconAward,
  IconChevronDown,
  IconChevronUp,
  IconLogout,
  IconSettings,
  IconUser,
  IconUserCircle,
} from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { persistor } from "../redux/configStore";
import { deleteToken, setToken } from "../redux/features/TokenSlice";
import { logins, logouts } from "../redux/features/userSlice";
import API_Services from "../services/CertificateUsersService";

const useStyles = createStyles((theme, _params) => ({
  item: {
    "&[data-hovered]": {
      backgroundColor: "#25204f",
      color: theme.white,
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "light"
        ? theme.colors.dark[9]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    links: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md,
    },

    linksInner: {
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
    },

    "&:hover": {
      backgroundColor: "#25204f",
      // theme.colorScheme === "light"
      //   ? theme.colors.dark[9]
      //   : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[0],
    },
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: "#25204f",
      color: theme.colorScheme === "dark" ? theme.white : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
}));

interface HeaderSearchProps {
  links: {
    link: string;
    label: string;
    links: { link: string; label: string }[];
  }[];
}

export default function HeaderMenuColored({ links }: HeaderSearchProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [opene, setOpened] = useState(false);
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("");
  const [userRolls, setUserRolls]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const userdetails = useSelector((s: any) => s.user.value);
  const location = useLocation();
  const currentPath = location.pathname;

  const [view, setView]: any = useState(false);
  let navData = [
    {
      title: "Dashboard",
      path: "/",
      iconName: "Dashboard",
      iconSize: 25,
    },
    {
      title: "Hub",
      path: "/certificates",
      iconName: "mycert",
      iconSize: 25,
    },
  ];

  if (
    userdetails?.user_roles &&
    userdetails?.user_roles[`VIEW_CERTIFICATE_CREATE`] &&
    userdetails?.user_roles[`VIEW_CERTIFICATE_CREATE`][0].can_read
  ) {
    navData.push({
      title: "Upload Offer Letter",
      path: "/certificate-create",
      iconName: "createCert",
      iconSize: 25,
    });
  }

  if (
    userdetails?.user_roles &&
    userdetails?.user_roles[`VIEW_CERTIFICATE_REQUEST`] &&
    userdetails?.user_roles[`VIEW_CERTIFICATE_REQUEST`][0].can_read
  ) {
    navData.push({
      title: "Request(s)",
      path: "/certificateRequest",
      iconName: "certReq",
      iconSize: 25,
    });
  }
  const linkss = navData.map((item, index) => (
    <UnstyledButton
      className={cx(classes.link, {
        [classes.linkActive]: item.path === currentPath,
      })}
      key={index}
      onClick={() => {
        navigate(item.path);
        setActive(item.iconName);
      }}
    >
      <span style={{ cursor: "pointer" }}>{item.title}</span>
    </UnstyledButton>
  ));

  const dispatch = useDispatch();

  useEffect(() => {
    ListUserRoll();
  }, []);

  const SwitchRole = async (e: any) => {
    if (e.role_id !== userdetails.present_role) {
      setLoading(true);
      try {
        setOpened(true);
        let res: any = await API_Services.SwitchRoles(e.role_id);
        dispatch(logins(res.data.user));
        dispatch(setToken(res.data.token));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setLoading(false);
        setOpened(false);
        if (res.status) {
          showNotification({
            color: "green",
            message: "Role Switched successfully",
          });
        } else {
          showNotification({
            color: "red",
            message: "Something went wrong",
          });
        }
      } catch {
        showNotification({
          color: "red",
          message: "Something went wrong",
        });
      }
    }
  };

  const ListUserRoll = async () => {
    setLoading(true);

    try {
      await API_Services.ListUserRoles().then((res: any) => {
        setUserRolls(res.data?.userRoles);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  // console.log("userDetails Reduyx", userdetails);

  let data = localStorage.getItem("user");
  let organizationIdData: any;
  if (data) {
    organizationIdData = JSON.parse(data);
    organizationIdData = organizationIdData?.user_details?.profile_img;
  }

  const logout = async () => {
    dispatch(logouts({}));
    dispatch(deleteToken(""));
    await persistor.purge();
    localStorage.clear();
    console.log("userDetails Reduyx", userdetails);
    navigate("/");
  };

  const handleImageClick = () => {
    navigate("/");
  };

  return (
    <>
      <div className={"header"}>

      <div>
                    <h6 style={{ fontSize: "20px", paddingLeft:"20px" }}>CERTIFY</h6>
                  </div>

        {/* <Image
          className="img1"
          src={require("../Assets/genpactlogo.png")}
          alt="Certify"
          width={80}
          radius="md"
          style={{ cursor: "pointer" }}
          onClick={handleImageClick}
          w="auto"
          fit="contain"
        /> */}
        <Group>
          <Group style={{ paddingRight: "30px" }}>{linkss}</Group>
          <Menu
            position="bottom-end"
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            classNames={classes}
            withArrow
          >
            <Menu.Target>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40"
                viewBox="0 -960 960 960"
                width="40"
              >
                <path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" />
              </svg>
            </Menu.Target>

            <Menu.Dropdown>
              {userdetails?.user_roles &&
              userdetails?.user_roles[`PROFILE`] &&
              userdetails?.user_roles[`PROFILE`][0].can_read ? (
                <Menu.Item
                  onClick={() => {
                    navigate("/profile");
                    setActive("setting");
                  }}
                >
                  <span>
                    <div style={{ display: "flex" }}>
                      {/* <Icon size={20} icon="Settings-2" /> */}
                      <IconUserCircle stroke={1.2} />
                      <p
                        style={{
                          paddingLeft: "18px",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        My Profile
                      </p>
                    </div>
                  </span>
                </Menu.Item>
              ) : null}
              {userdetails?.user_roles &&
              userdetails?.user_roles[`ORGANIZATION_PROFILE`] &&
              userdetails?.user_roles[`ORGANIZATION_PROFILE`][0].can_read ? (
                <Menu.Item
                  onClick={() => {
                    navigate("/organizationprofile");
                    setActive("setting");
                  }}
                >
                  <span>
                    <div style={{ display: "flex" }}>
                      {/* <Icon size={20} icon="Settings-2" /> */}
                      <IconAward stroke={1.2} />
                      <p
                        style={{
                          paddingLeft: "18px",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        Organization Profile
                      </p>
                    </div>
                  </span>
                </Menu.Item>
              ) : null}

              <Menu.Divider />

              {userdetails?.user_roles &&
              userdetails?.user_roles[`SWITCH_ROLE`] &&
              userdetails?.user_roles[`SWITCH_ROLE`][0].can_read ? (
                <>
                  <Menu.Label
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setView((o: any) => !o);
                    }}
                  >
                    <span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <Icon size={20} icon="Settings-2" /> */}

                        <p
                          style={{
                            paddingLeft: "18px",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Switch Role
                        </p>
                        {view ? (
                          <IconChevronUp stroke={1.2} />
                        ) : (
                          <IconChevronDown stroke={1.2} />
                        )}
                      </div>
                    </span>
                  </Menu.Label>
                  <Collapse in={view}>
                    {userRolls?.length > 0 &&
                      userRolls?.map((e: any) => {
                        return (
                          <Menu.Item
                            onClick={() => {
                              SwitchRole(e);
                              setActive("setting");
                            }}
                          >
                            <span>
                              <div style={{ display: "flex" }}>
                                <span>
                                  {userdetails.present_role === e.role_id ? (
                                    <span className=""></span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <IconUser stroke={1.2} />
                                <p
                                  style={{
                                    paddingLeft: "18px",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                  }}
                                >
                                  {e?.roles.role_name}
                                </p>
                              </div>
                            </span>
                          </Menu.Item>
                        );
                      })}
                  </Collapse>
                </>
              ) : null}

              <Menu.Divider />

              {userdetails?.user_roles &&
              userdetails?.user_roles[`SETTINGS_MASTER`] &&
              userdetails?.user_roles[`SETTINGS_MASTER`][0].can_read ? (
                <Menu.Item
                  onClick={() => {
                    navigate("/setting");
                    setActive("setting");
                  }}
                >
                  <span>
                    <div style={{ display: "flex" }}>
                      {/* <Icon size={20} icon="Settings-2" /> */}
                      <IconSettings stroke={1.2} />
                      <p
                        style={{
                          paddingLeft: "18px",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        Settings
                      </p>
                    </div>
                  </span>
                </Menu.Item>
              ) : null}

              <Menu.Item color="#ff7070" onClick={() => setOpen(true)}>
                <span>
                  <div style={{ display: "flex" }}>
                    {/* <Icon color="#ff7070" size={20} icon="Logout" /> */}
                    <IconLogout stroke={1.2} />
                    <p
                      style={{
                        paddingLeft: "18px",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Logout
                    </p>
                  </div>
                </span>
              </Menu.Item>
            </Menu.Dropdown>

            <Modal
              size="md"
              opened={open}
              withCloseButton={false}
              onClose={() => {}}
              centered
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Title order={4}>Do you wish to end your session?</Title>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant="outline"
                  color="red"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button color="#25204f" onClick={() => logout()}>
                  Logout
                </Button>
              </div>
            </Modal>
          </Menu>
          <LoadingOverlay
            style={{ position: "absolute", height: "100vh" }}
            loaderProps={{ color: "#25204f" }}
            overlayOpacity={0.8}
            visible={loading}
            overlayBlur={2}
          />
        </Group>
      </div>
    </>
  );
}
