import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import ApprovalLevelService from "../../services/ApprovalTemplateLevelsServic";
import ApprovalTemplateService from "../../services/ApprovalTemplateService";
import API_Services from "../../services/CertificateUsersService";

interface CardPropsType {
  setApprovalLevelModal: any;
  get_approvallevel_list: any;
  edit_approvalLevel_data: any;
  editModal: any;
}
const ApprovalLevelModal: React.FC<CardPropsType> = (props): JSX.Element => {
  const {
    setApprovalLevelModal,
    get_approvallevel_list,
    edit_approvalLevel_data,
    editModal,
  } = props;
  const [popup, setPopup] = useState(true);
  const [loading, setLoading]: any = useState(false);
  const [approvalTemplate, setApprovalTemplate] = useState([]);
  const [visible, setVisible] = useState(false);
  const [userlist, setUserList] = useState([]);
  const [inputList, setInputList] = useState({
    stageName: editModal
      ? edit_approvalLevel_data.certificate_approval_template_name
      : "",
    approvalId: editModal
      ? edit_approvalLevel_data
          ?.certificate_approval_template_stage_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template[0]
          .certificate_approval_template
      : "",
  });
  const [editLen, setEditLen]: any = useState();
  console.log(editLen, "editLen");

  const [levels, setLevels]: any = useState([
    {
      userId: "",
      level: 1,
    },
  ]);
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [edit, setEdit] = useState(
    edit_approvalLevel_data?.certificate_approval_template_stage_certificate_approval_template_stage_certificate_approval_templateTocertificate_approval_template?.map(
      (item: any) => ({
        level: item?.level,
        userId: item?.user_details?.id,
        id: item?.id ? item.id : null,
        uuid: item.uuid,
      })
    )
  );
  useEffect(() => {
    if (editModal) {
      setEditLen(edit.length);
    }

    getApprovalTemplate();
    getuserlist();
    setApprovalLevelModal(popup);
  }, [popup]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (inputList.approvalId && inputList.stageName) {
      let add = levels.map((item: any) => ({
        certificate_approval_template_stage_name: inputList.stageName,
        certificate_approval_template: inputList.approvalId,
        level: parseInt(item.level),
        user_detail_id: item.userId,
      }));
      let editData = edit?.map((item: any) => ({
        certificate_approval_template_stage_name: inputList.stageName,
        certificate_approval_template: inputList.approvalId,
        level: parseInt(item.level),
        user_detail_id: item.userId,
      }));
      try {
        let res = await ApprovalLevelService.AddApprovalTemplateLevels(
          editModal ? editData : add
        );
        if (res.status === true) {
          get_approvallevel_list();
          setLoading(false);
          setPopup(false);
          showNotification({
            color: "Violet",
            message: res.message,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please Enter the Department name",
      });
    }
  };

  const handleUpdate = async (values: any) => {
    setVisible(true);
    if (values.userId && values.id) {
      let data = {
        certificate_approval_template: inputList.approvalId,
        user_detail_id: values.userId,
        level: parseInt(values.level),
        id: values?.id,
      };

      try {
        let res = await ApprovalLevelService.UpdateApprovalTemplateLevels(
          data,
          values.uuid
        );
        if (res.status === true) {
          get_approvallevel_list();
          setVisible(false);
          showNotification({
            color: "violet",
            message: res.message,
          });
        }
      } catch (error) {
        setVisible(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setVisible(false);
      showNotification({
        color: "violet",
        message: "Please Enter the Department name",
      });
    }
  };

  const getApprovalTemplate = async () => {
    let data = {
      select: {
        certificate_approval_template_name: true,
        id: true,
      },
    };
    setLoading(true);
    try {
      let res = await ApprovalTemplateService.ApprovalTemplatelist(data);
      setApprovalTemplate(res.data.certificate_approval_template);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getuserlist = async () => {
    let data = {
      include: {
        user_details: {
          select: {
            user_detail_name: true,
            id: true,
          },
        },
      },
    };
    setLoading(true);
    try {
      await API_Services.getUserList(data).then((res) => {
        setUserList(res.data.users);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const CreateLevel = (length: any) => {
    console.log(length, "createLevel");

    if (editModal) {
      setEdit([
        ...edit,
        {
          userId: "",
          level: length + 1,
        },
      ]);
    }
    setLevels([
      ...levels,
      {
        userId: "",
        level: length + 1,
      },
    ]);
  };
  const handleRemoveClick = async (data?: any, index?: any) => {
    if (editModal) {
      if (!data.id) {
        const list = [...edit];
        list.splice(index, 1);
        setEdit(list);
      } else if (data.id) {
        setVisible(true);
        try {
          let res: any = await ApprovalTemplateService.DeleteApprovalTemplate(
            data
          );
          if (res.status === true) {
            const list = [...edit];
            list.splice(index, 1);
            let arr = list.map((item: any, i) => ({
              userId: item.userId,
              level: i + 1,
              id: item?.id ? item.id : null,
              uuid: item.uuid,
            }));
            setEdit(arr);
            for (let i = 0; i < arr.length; i++) {
              console.log(arr[i]);
              let data = {
                certificate_approval_template: inputList.approvalId,
                user_detail_id: arr[i].userId,
                level: arr[i]?.level,
                id: arr[i]?.id,
                uuid: arr[i].uuid,
              };
              let res = await ApprovalLevelService.UpdateApprovalTemplateLevels(
                data,
                data.uuid
              );
              if (res.status === true) {
                get_approvallevel_list();
                setVisible(false);
              }
            }
            setVisible(false);
            setLoading(false);
            showNotification({
              color: "green",
              message: "Levels Updataed",
            });
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          setVisible(false);
          showNotification({
            color: "red",
            message: "error",
          });
        }
      }
    } else {
      const list = [...levels];
      list.splice(index, 1);
      setLevels(list);
    }
  };
  console.log(edit_approvalLevel_data.certificate_approval_template_name);

  return (
    <>
      {editModal === false && (
        <Modal
          size={800}
          opened={popup}
          onClose={() => {
            setPopup(false);
          }}
          title="Add Approval Level"
          centered
        >
          <form onSubmit={handleSubmit}>
            <div>
              <TextInput
                size="sm"
                mt="sm"
                onChange={(event) =>
                  setInputList({ ...inputList, stageName: event.target.value })
                }
                label="Certificate Stage Name"
                required
              />
              <Select
                label="Certificate Approval Template"
                placeholder="Certificate Approval Template"
                required
                data={
                  Array.isArray(approvalTemplate)
                    ? approvalTemplate.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.certificate_approval_template_name
                            ? e.certificate_approval_template_name
                            : "_",
                        };
                      })
                    : []
                }
                onChange={(value: any) =>
                  setInputList({ ...inputList, approvalId: value })
                }
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
              <Group position="right">
                <Button
                  mt="sm"
                  style={{ background: "#25204f" }}
                  onClick={() => CreateLevel(levels.length)}
                >
                  Add Levels
                </Button>
              </Group>
              <Divider my="sm" variant="dashed" />

              {levels.map((item: any, index: any) => (
                <Group id="create-level">
                  <Box mt="lg">
                    <h4>{index + 1}</h4>
                  </Box>
                  <Select
                    label="Level"
                    name={String(item?.level)}
                    value={String(item?.level)}
                    placeholder="Level"
                    required
                    data={[
                      { value: "1", label: "One" },
                      { value: "2", label: "Two" },
                      { value: "3", label: "Three" },
                      { value: "4", label: "Four" },
                      { value: "5", label: "Five" },
                      { value: "6", label: "Six" },
                      { value: "7", label: "Seven" },
                      { value: "8", label: "Eight" },
                      { value: "9", label: "Nine" },
                      { value: "10", label: "Ten" },
                    ]}
                    onChange={(value) => {
                      {
                        const list = [...levels];
                        list[index]["level"] = value;
                        setLevels(list);
                      }
                    }}
                    searchable
                    maxDropdownHeight={400}
                    nothingFound="Nobody here"
                  />
                  <Select
                    label="Select Users"
                    placeholder="Users"
                    name={item?.userId}
                    required
                    data={
                      Array.isArray(userlist)
                        ? userlist.map((e: any, i: any) => {
                            return {
                              value: e?.id,
                              label: e?.first_name ? e?.first_name : "_",
                            };
                          })
                        : []
                    }
                    onChange={(value) => {
                      {
                        const list = [...levels];
                        list[index]["userId"] = value;
                        setLevels(list);
                      }
                    }}
                    searchable
                    maxDropdownHeight={400}
                    nothingFound="Nobody here"
                  />
                  <Box mt="lg">
                    <Button
                      variant="default"
                      onClick={() => handleRemoveClick(index)}
                      radius="md"
                    >
                      Remove
                    </Button>
                  </Box>
                </Group>
              ))}
            </div>

            <Group position="right">
              <Button
                type="submit"
                mt="sm"
                loading={loading}
                style={{ background: "#25204f" }}
                //  onClick={(e) => handleSubmit(e)}
              >
                Add
              </Button>
            </Group>
          </form>
        </Modal>
      )}
      {editModal === true && (
        <>
          <Modal
            size={800}
            opened={popup}
            onClose={() => {
              setPopup(false);
            }}
            title="Edit Approval Level"
            centered
          >
            <LoadingOverlay
              loaderProps={{ color: "#25204f" }}
              visible={visible}
              overlayBlur={0.1}
            />
            <div>
              <form>
                <TextInput
                  size="sm"
                  mt="sm"
                  value={
                    edit_approvalLevel_data.certificate_approval_template_name
                  }
                  onChange={(event) =>
                    setInputList({
                      ...inputList,
                      stageName: event.target.value,
                    })
                  }
                  label="Certificate Stage Name"
                  required
                />
                <Select
                  label="Certificate Approval Template"
                  placeholder="Certificate Approval Template"
                  required
                  data={
                    Array.isArray(approvalTemplate)
                      ? approvalTemplate.map((e: any, i: any) => {
                          return {
                            value: e.id,
                            label: e.certificate_approval_template_name
                              ? e.certificate_approval_template_name
                              : "_",
                          };
                        })
                      : []
                  }
                  value={inputList.approvalId}
                  onChange={(value) =>
                    setInputList({ ...inputList, approvalId: value })
                  }
                  searchable
                  maxDropdownHeight={400}
                  nothingFound="Nobody here"
                />

                <Group position="right">
                  <Button
                    mt="sm"
                    style={{ background: "#25204f" }}
                    onClick={() => CreateLevel(edit.length)}
                  >
                    Add Levels
                  </Button>
                </Group>
                <Divider my="sm" variant="dashed" />

                {edit.map((item: any, index: any) => (
                  <Group id="create-level">
                    <Box mt="lg">
                      <h4>{index + 1}</h4>
                    </Box>
                    <Select
                      label="select Levels"
                      placeholder="levels"
                      value={String(item?.level)}
                      required
                      data={[
                        { value: "1", label: "One" },
                        { value: "2", label: "Two" },
                        { value: "3", label: "Three" },
                        { value: "4", label: "Four" },
                        { value: "5", label: "five" },
                        { value: "6", label: "six" },
                        { value: "7", label: "seven" },
                        { value: "8", label: "eight" },
                        { value: "9", label: "nine" },
                      ]}
                      onChange={(value) => {
                        {
                          const list = [...edit];
                          list[index]["level"] = value;
                          setEdit(list);
                        }
                      }}
                      searchable
                      maxDropdownHeight={400}
                      nothingFound="Nobody here"
                    />
                    <Select
                      label="Select Users"
                      placeholder="Users"
                      required
                      value={item?.userId}
                      data={
                        Array.isArray(userlist)
                          ? userlist.map((e: any, i: any) => {
                              return {
                                value: e.user_details?.id,
                                label: e.first_name ? e.first_name : "",
                              };
                            })
                          : []
                      }
                      onChange={(value) => {
                        {
                          const list = [...edit];
                          list[index]["userId"] = value;
                          setEdit(list);
                        }
                      }}
                      searchable
                      maxDropdownHeight={400}
                      nothingFound="Nobody here"
                    />
                    <Box mt="lg">
                      <Button
                        variant="default"
                        mr="sm"
                        onClick={() => handleUpdate(item)}
                        radius="md"
                      >
                        Update
                      </Button>
                      <Button
                        variant="default"
                        onClick={() => {
                          handleRemoveClick(item, index);
                        }}
                        radius="md"
                      >
                        Remove
                      </Button>
                    </Box>
                  </Group>
                ))}
                {edit.length > editLen ? (
                  <Group position="right">
                    <Button
                      type="submit"
                      mt="sm"
                      loading={loading}
                      style={{ background: "#25204f" }}
                      // onClick={(e) => handleSubmit(e)}
                    >
                      Add
                    </Button>
                  </Group>
                ) : (
                  <Group position="right">
                    <Button
                      mt="sm"
                      loading={loading}
                      style={{ background: "#25204f" }}
                      onClick={() => setPopup(false)}
                    >
                      close
                    </Button>
                  </Group>
                )}
              </form>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default ApprovalLevelModal;
