import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Loader,
  Pagination,
  Select,
  Table,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import AddRolePop from "../../components/AllPopUPs/AddRolePop";
import DeleteModal from "../../components/AllPopUPs/DeleteModal";
import NoData from "../../components/LottieFiles/NoData";
import RoleServices from "../../services/RoleSevices";
import "./UserRollSetting.css";

const RollSettingsRolls = () => {
  //table---
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState(false);

  //table---
  const [RolePop, setRolePop]: any = useState(false);
  const [roleslist, setRoleslist]: any = useState("");
  const [editpop, setEditpop]: any = useState("");
  const [editdata, setEditdata]: any = useState();
  const [confirmDialog, setConfirmDialog]: any = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [roleTotal, setRoleTotal]: any = useState();
  const [roleName, setRoleName]: any = useState("");
  //role functions
  const getRolelists = async () => {
    setLoading(true);
    let data = {
      skip: pageSize * (page - 1),
      limit: pageSize,
    };
    let data1 = {
      limit: pageSize,
      where: {
        role_name: {
          contains: roleName,
          mode: "insensitive",
        },
      },
    };
    try {
      let res = await RoleServices.Rolelists(roleName ? data1 : data);
      if (res.status === true) {
        setRoleslist(res.data.roles);
        setRoleTotal(res.data.total);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleDeleterole = async (params: any) => {
    try {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      await RoleServices.Deleterole(params).then((res) => {
        if (res.status === true) {
          getRolelists();
          showNotification({
            color: "green",
            message: res.message,
          });
        }
      });
    } catch (error) {
      console.log(error);
      showNotification({
        color: "red",
        message: "Something went wrong",
      });
    }
  };

  const handleUpdaterole = async (params: any) => {
    setEditdata(params);
    setEditpop(true);
  };

  useEffect(() => {
    getRolelists();
  }, [page, pageSize, roleName]);

  const setcurrentPage = (value: any) => {
    setPage(value);
    setRoleslist([]);
  };

  const setrowsperpage = (value: any) => {
    setPageSize(parseInt(value));
    setRoleslist([]);
  };

  return (
    <>
      <DeleteModal
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {RolePop && (
        <AddRolePop
          setRolePop={setRolePop}
          getRolelists={getRolelists}
          setEditpop={setEditpop}
          editpop={editpop}
          editdata={editdata}
        />
      )}

      <Card mt="sm" radius="lg" shadow="lg">
        <Card.Section>
          <Group position="apart">
            <Box m="md">
              <Autocomplete
                label="Filter by Name"
                placeholder="Search Roles"
                data={
                  Array.isArray(roleslist)
                    ? roleslist.map((provider) => `${provider.role_name}`)
                    : []
                }
                onChange={(value) => setRoleName(value)}
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
            </Box>
            <Box p="md">
              <Button
                style={{ background: "#25204f" }}
                onClick={() => {
                  setRolePop(true);
                  setEditpop(false);
                }}
              >
                + Add 
              </Button>
            </Box>
          </Group>
          {/* {roleslist && roleslist ? ( */}
          {loading === true ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "50px",
              }}
            >
              <Loader color="#25204f" />
            </div>
          ) : roleslist && roleslist.length > 0 ? (
            <>
              <Table verticalSpacing="lg" highlightOnHover>
                <thead style={{ border: "none" }}>
                  <tr>
                    <th>
                      <Checkbox size="sm" />
                    </th>
                    <th>
                      <div>Name</div>
                    </th>
                    <th>
                      <div>Status</div>
                    </th>
                    <th>
                      <div>Action</div>
                    </th>
                  </tr>
                </thead>
                {roleslist.length >= 0 ? (
                  <tbody>
                    {roleslist.map((roles: any, index: number) => (
                      <tr
                        className={
                          roles?.status === false ? "in-active-list" : ""
                        }
                        key={roles?.uuid}
                      >
                        <td>
                          <Checkbox size="sm" />
                        </td>
                        <td>{roles?.role_name}</td>
                        <td>{roles?.status ? "Active" : "In-Active"}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setEditpop(true);
                              setRolePop(true);
                              handleUpdaterole(roles);
                            }}
                            variant="default"
                            radius="md"
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Want to Delete?",

                                onConfirm: () => {
                                  handleDeleterole(roles);
                                },
                              });
                            }}
                            variant="default"
                            radius="md"
                            ml="sm"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <>no data</>
                )}
                <tfoot></tfoot>
              </Table>
              <Group position="apart" mt="md" p="lg">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box mr="md">Rows per page</Box>
                  <Select
                    onChange={(e) => setrowsperpage(e)}
                    style={{ width: "4rem" }}
                    value={String(pageSize)}
                    data={[
                      { value: "5", label: "5" },
                      { value: "10", label: "10" },
                      { value: "15", label: "15" },
                      { value: "20", label: "20" },
                    ]}
                  />
                </Box>
                <Pagination
                  styles={(theme) => ({
                    item: {
                      "&[data-active]": {
                        backgroundColor: "#25204f",
                      },
                    },
                  })}
                  total={Math.ceil(roleTotal / pageSize)}
                  boundaries={5}
                  page={page}
                  initialPage={1}
                  onChange={(e) => setcurrentPage(e)}
                />
              </Group>
            </>
          ) : (
            <NoData />
          )}
        </Card.Section>
      </Card>
    </>
  );
};

export default RollSettingsRolls;
