import React from "react";
import PropTypes from "prop-types";
import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";

interface IconPropsType {
  icon: string;
  size: number;
  color?: string;
  className?: string;
}

const Icon: React.FC<IconPropsType> = (props: IconPropsType): JSX.Element => {
  const { color, size, icon, className } = props;
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
    />
  );
};

export default Icon;
