import React, { useEffect, useState } from "react";
import { Button, Group, Modal, Switch, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import ModuleServices from "../../services/ModuleServices";


interface CardPropsType {
  setModulePop: any;
  ModulePop: any;
  editModulepop: any;
  editmoduledata: any;
  getModulelists: any;
}
const AddModulePop: React.FC<CardPropsType> = (props): JSX.Element => {
  const { setModulePop, getModulelists, editModulepop, editmoduledata } = props;
  const [popup, setPopup]: any = useState(true);
  const [checked, setChecked]: any = useState(true);
  const [moduledname, setModuleName]: any = useState("");
  const [edited, setEdited]: any = useState();
  const [loading, setLoading]: any = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (moduledname) {
      try {
        let res = await ModuleServices.AddModule(moduledname, checked);
        if (res.status === true) {
          getModulelists();
          setLoading(false);
          setPopup(false);
          showNotification({
            color: "violet",
            message: res.message,
          });
        }
      } catch (error) {
        showNotification({
          color: "violet",
          message: "Something Went Wrong",
        });
      }
    } else {
      showNotification({
        color: "violet",
        message: "Please fill the Department Name",
      });
    }
  };

  useEffect(() => {
    if (editmoduledata) {
      setChecked(editmoduledata.status);
      setEdited(editmoduledata.module_name);
    }
    setModulePop(popup);
  }, [popup]);

  const handleUpdate = async () => {
    setLoading(true);
    if (edited) {
      try {
        let res = await ModuleServices.UpdateModules(
          edited,
          editmoduledata,
          checked
        );
        if (res.ststus === true) {
          getModulelists();
          setLoading(false);
          setModulePop(false);
          showNotification({
            color: "green",
            message: res.message,
          });
        }
      } catch (error) {
        setLoading(false);
        showNotification({
          color: "red",
          message: "Something Went Wrong",
        });
      }
    } else {
      showNotification({
        color: "red",
        message: "Please Update the Modules",
      });
      setLoading(false);
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    // add module
    <div>
      {editModulepop === false && (
        <Modal
          opened={popup}
          onClose={() => {
            setPopup(false);
            setChecked(false);
          }}
          title="Add Module"
          centered
        >
          <div>
            <TextInput
              size="md"
              onChange={(e) => setModuleName(e.target.value)}
              label="Module name"
            />

            <Switch
              label="status"
              radius="lg"
              size="sm"
              mt="sm"
              className="switch"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button style={{ background: "#25204f" }} loading={loading} onClick={() => handleSubmit()}>
              Add
            </Button>
          </Group>
        </Modal>
      )}
      {/* edit module */}
      {editModulepop === true && (
        <Modal
          opened={popup}
          onClose={() => {
            setPopup(false);
            setChecked(false);
          }}
          title="Edit Module"
          centered
        >
          <div>
            <TextInput
              size="md"
              value={edited}
              onChange={(e) => setEdited(e.target.value)}
              label="Module name"
            />

            <Switch
              label={checked ? "Active" : "In-active"}
              radius="lg"
              mt="md"
              size="sm"
              className="switch"
              checked={checked}
              onChange={(event) => setChecked(event.currentTarget.checked)}
            />
          </div>
          <Group position="right">
            <Button loading={loading} style={{ background: "#25204f" }} onClick={() => handleUpdate()}>
              Save
            </Button>
          </Group>
        </Modal>
      )}
    </div>
  );
};

export default AddModulePop;
