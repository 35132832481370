import { configureStore, combineReducers, Action } from "@reduxjs/toolkit"
// import rootReducer from "./reducers"
import createSagaMiddleware from "redux-saga"
import { watcherSaga } from "./sagas/rootSaga"
import userReducer from "./features/userSlice"
import tokenReducer from "./features/TokenSlice"
import storage from "redux-persist/lib/storage"
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"

const persistConfig = {
  key: "root",
  storage: storage,
}

const sagaMiddleware = createSagaMiddleware()


export const rooReducer = combineReducers({
  // rootReducer,
  user: userReducer,
  token: tokenReducer,
})

const persistedReducer = persistReducer(persistConfig, rooReducer)

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
})

export let persistor = persistStore(store)

sagaMiddleware.run(watcherSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
