import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import ApprovalTemplateService from "../../services/ApprovalTemplateService";
import DepartmentServices from "../../services/DepartmentService";
import DocumentService from "../../services/DocumentService";

interface ApprovalTemplatePropsType {
  setDocumentFieldsModal: any;
  get_documentField_list: any;
  setEditModal: any;
  edit_document: any;
  organsize_list: any;
  editModal: any;
}
const ApprovalTemplateModal: React.FC<ApprovalTemplatePropsType> = (
  props
): JSX.Element => {
  const {
    setDocumentFieldsModal,
    get_documentField_list,
    edit_document,
    editModal,
    organsize_list,
  } = props;
  console.log(edit_document);
  const form = useForm({
    initialValues: {
      departmentId: edit_document?.department_id,
      documentId: edit_document?.document_id,
      certificate_approval_template_name:
        edit_document?.certificate_approval_template_name,
    },
  });
  const [popup, setPopup] = useState(true);
  const [required, setRequired] = useState(false);
  const [Documentname, setDocumentName]: any = useState("");
  const [fieldType, setfieldType]: any = useState("");
  const [fieldLabel, setFieldLabel]: any = useState("");
  const [edited, setEdited]: any = useState("");
  const [loading, setLoading]: any = useState(false);
  const [document, setDocument]: any = useState([]);
  const [documentid, setDocumentId]: any = useState();
  const [status, setStatus]: any = useState(false);
  const [department, setDepartment]: any = useState();
  const [visible, setVisible] = useState(false);
  const [imageurl, setImageurl]: any = useState();
  const [departmentId, setDepartmentId]: any = useState();

  let data = localStorage.getItem("user");
  let organizationId: any;
  if (data) {
    organizationId = JSON.parse(data);
    organizationId = organizationId?.user_details.organization_id;
  }

  const getDepartment = async () => {
    setLoading(true);
    let data = {
      select: {
        id: true,
        department_name: true,
      },
    };

    try {
      let res = await DepartmentServices.Departmentlists(data);
      if (res.status === true) {
        setDepartment(res.data.department);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getDocument = async () => {
    let data = {
      select: {
        id: true,
        template_name: true,
      },
    };
    try {
      let res = await DocumentService.Documentlists(data);
      if (res.status === true) {
        setDocument(res.data.document);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    // if (editModal) {
    //   //setStatus(edit_document_fileds.status);

    //   setDepartmentId(edit_document.department_id);
    //   setDocumentId(edit_document.document_id);
    //   // setFieldLabel(edit_document_fileds.field_label);
    // }
    getDocument();
    getDepartment();
    setDocumentFieldsModal(popup);
  }, [popup]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setVisible(true);
    if (documentid) {
      try {
        let res = await ApprovalTemplateService.AddApprovalTemplate(
          documentid,
          organizationId,
          departmentId,
          Documentname
        );
        if (res.status === true) {
          get_documentField_list();
          setVisible(false);
          setPopup(false);
          showNotification({
            color: "green",
            message: res.message,
          });
        }
      } catch (error) {
        setVisible(false);
        console.log(error);
        showNotification({
          color: "violet",
          message: "error",
        });
      }
    } else {
      setLoading(false);
      showNotification({
        color: "violet",
        message: "Please Enter the Department name",
      });
    }
  };

  const handleUpdate = async (value: any) => {
    // value.preventDefault();
    console.log(value);

    setLoading(true);
    try {
      let data = {
        department_id: value.departmentId,
        document_id: value.documentId,
        certificate_approval_template_name:
          value.certificate_approval_template_name,
      };
      let res = await ApprovalTemplateService.UpdateApprovalTemplate(
        data,
        edit_document
      );
      get_documentField_list();
      setLoading(false);
      setPopup(false);
      showNotification({
        color: "green",
        message: res.message,
      });
    } catch (error) {
      // console.log(error);
      setLoading(false);
      showNotification({
        color: "violet",
        message: "error",
      });
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      {editModal === false && (
        <Modal
          size={500}
          opened={popup}
          onClose={() => {
            setPopup(false);
            setRequired(false);
          }}
          title="Add Document Template"
          centered
        >
          <LoadingOverlay
            loaderProps={{ color: "#25204f" }}
            visible={visible}
            overlayBlur={0.1}
          />
          <form onSubmit={handleSubmit}>
            <div>
              <TextInput
                value={Documentname}
                required
                withAsterisk
                size="sm"
                mt="sm"
                onChange={(event) => setDocumentName(event.currentTarget.value)}
                label="Document Template Name"
              />
              <Select
                label="select Department"
                placeholder="Department"
                required
                withAsterisk
                data={
                  Array.isArray(department)
                    ? department.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.department_name,
                        };
                      })
                    : []
                }
                onChange={(value) => {
                  setDepartmentId(value);
                }}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
              <Select
                label="select Document "
                placeholder="Document "
                required
                withAsterisk
                data={
                  Array.isArray(document)
                    ? document.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.template_name,
                        };
                      })
                    : []
                }
                onChange={(value) => {
                  setDocumentId(value);
                }}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
            </div>

            <Group position="right">
              <Button
                type="submit"
                mt="md"
                style={{ background: "#25204f" }}
                loading={loading}
                // onClick={() => handleSubmit()}
              >
                Add
              </Button>
            </Group>
          </form>
        </Modal>
      )}
      {editModal === true && (
        <>
          <Modal
            opened={popup}
            onClose={() => {
              setPopup(false);
              setRequired(false);
            }}
            title="Edit Document Template"
            centered
          >
            <form onSubmit={form.onSubmit((values) => handleUpdate(values))}>
              <TextInput
                value={Documentname}
                size="sm"
                mt="sm"
                onChange={(event) => setDocumentName(event.currentTarget.value)}
                label="Document Template Name"
                required
                {...form.getInputProps("certificate_approval_template_name")}
              />
              <Select
                label="select Department"
                placeholder={departmentId}
                data={
                  Array.isArray(department)
                    ? department.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.department_name,
                        };
                      })
                    : []
                }
                {...form.getInputProps("departmentId")}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />
              <Select
                label="select Document"
                placeholder={documentid}
                data={
                  Array.isArray(document)
                    ? document.map((e: any, i: any) => {
                        return {
                          value: e.id,
                          label: e.template_name,
                        };
                      })
                    : []
                }
                {...form.getInputProps("documentId")}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
              />

              <Group position="right" mt="lg">
                <Button
                  style={{ background: "#25204f" }}
                  loading={loading}
                  type="submit"
                >
                  Save
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      )}
    </>
  );
};

export default ApprovalTemplateModal;
